import axios from 'axios';
import Question from 'models/Question';
import SanitizedQuestion from 'types/SanitizedQuestion';

const basePath = 'question';

const questionService = {
    getAll: () => axios.get<Question[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Question>(`${basePath}/${id}`),
    add: (model: Question) => axios.post<Question>(`${basePath}/`, model),
    update: (model: Question) => axios.put<Question>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getBySourceType: (sourceType: number) => axios.get<SanitizedQuestion[]>(`${basePath}/by-source-type/${sourceType}`),
    getQuestionCounts: () => axios.get<number[]>(`${basePath}/question-counts`),
};

export default questionService;

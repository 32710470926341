import { FC, ReactNode } from 'react';
import './InfoText.scss';

type InfoTextProps = {
    label: string;
    value?: string | number;
    children?: ReactNode;
};

const InfoText: FC<InfoTextProps> = ({ label, value, children }) => {
    return (
        <div className="InfoBox">
            <span>
                {value}
                {children}
            </span>
            <fieldset aria-hidden="true">
                <legend>
                    <span>{label}</span>
                </legend>
            </fieldset>
        </div>
    );
};

export default InfoText;

import { FC } from 'react';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Partner from 'models/Partner';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

type PartnerListProps = {
    partners: Partner[];
    editPartner: (partner: Partner) => void;
    deletePartner: (id: number) => void;
};

const PartnerList: FC<PartnerListProps> = ({ partners, editPartner, deletePartner }) => {
    const { t } = useTranslation();

    return (
        <div>
            {partners === null ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>{t('common.type')}</TableCell>
                                <TableCell>{t('common.name')}</TableCell>
                                <TableCell>{t('common.title')}</TableCell>
                                <TableCell>{t('common.phone')}</TableCell>
                                <TableCell>{t('common.email')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partners.map((partner) => (
                                <TableRow key={partner.id}>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => editPartner(partner)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{t('partnerType.' + partner.type)}</TableCell>
                                    <TableCell>{partner.name}</TableCell>
                                    <TableCell>{partner.title}</TableCell>
                                    <TableCell>{partner.phone}</TableCell>
                                    <TableCell>{partner.email}</TableCell>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => deletePartner(partner.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {partners.length === 0 && (
                        <p style={{ textAlign: 'center' }}>
                            <i>{t('common.theListIsEmpty')}</i>
                        </p>
                    )}
                </TableContainer>
            )}
        </div>
    );
};

export default PartnerList;

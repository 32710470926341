import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import { useHistory } from 'react-router';
import { useAuth } from 'hooks';
import routes from 'consts/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Button } from '@mui/material';

const ProfileMenu = () => {
    const user = useSelector((state: RootState) => state.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const isMenuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        history.push(routes.PROFILE.path);
    };

    const { signOut } = useAuth();
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem
                onClick={() => {
                    signOut();
                    history.push(routes.LOGIN.path);
                }}
            >
                Sign Out
            </MenuItem>
        </Menu>
    );

    return (
        <>
            {renderMenu}
            <Button onClick={handleProfileMenuOpen} color="inherit" sx={{ textTransform: 'none' }}>
                <span>{user.name}</span> &nbsp;
                <AccountCircleIcon sx={{ fontSize: '1.5rem' }} />
            </Button>
        </>
    );
};

export default ProfileMenu;

import { FC, ReactNode } from 'react';

type PublicPageContainerProps = {
    children: ReactNode;
    maxWidth?: number;
};

const PublicPageContainer: FC<PublicPageContainerProps> = ({ children, maxWidth }) => {
    const _maxWidth = maxWidth || 600;

    return (
        <div
            className="public-page"
            style={{
                paddingTop: '100px',
                backgroundColor: '#f3f3f3',
                minHeight: '100vh',
            }}
        >
            <img
                className="public-page-logo"
                src="/assets/logo-192.png"
                style={{
                    display: 'block',
                    margin: '20px auto',
                    maxWidth: '192px',
                }}
            />
            <div
                className="public-page-container"
                style={{
                    maxWidth: _maxWidth + 'px',
                    margin: '0 auto',
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default PublicPageContainer;

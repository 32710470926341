import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { useEffect, useState } from 'react';
import FidelityReview from 'models/FidelityReview';
import fidelityReviewService from 'services/fidelityReview.service';
import { useParams } from 'react-router';
import IdParams from 'types/IdParams';
import familyService from 'services/family.service';
import Family from 'models/Family';
import answerService from 'services/answer.service';
import sourceType from 'consts/sourceType';
import clientService from 'services/client.service';
import Client from 'models/Client';
import FidelityReviewShortInfo from 'components/FidelityReviewShortInfo/FidelityReviewShortInfo';
import employeeService from 'services/employee.service';
import Employee from 'models/Employee';
import LinkIcon from '@mui/icons-material/Link';
import { IconButton } from '@mui/material';
import routes from 'consts/routes';
import questionService from 'services/question.service';

const view = {
    LOADING: 0,
    SHOW_PROGRESS: 1,
};
type FamilyProgress = {
    family: Family;
    progress: number[];
};

type ClientProgress = {
    client: Client;
    progress: number[];
};

type EmployeeProgress = {
    employee: Employee;
    progress: number[];
};

const FidelityReviewSurveyProgress = () => {
    const { t } = useTranslation();
    const { id } = useParams<IdParams>();
    const surveyId = +id;

    const [currentView, setCurrentView] = useState<number>(view.LOADING);
    const [fidelityReview, setFidelityReview] = useState<FidelityReview>(null);
    const [familyProgress, setFamilyProgress] = useState<FamilyProgress[]>([]);
    const [clientsProgress, setClientsProgress] = useState<ClientProgress[]>([]);
    const [employeesProgress, setEmployeesProgress] = useState<EmployeeProgress[]>([]);
    const [questionsCount, setQuestionsCount] = useState<number[]>([]);

    const init = async () => {
        // Get fidelity Review
        const fidelityReviewQuery = await fidelityReviewService.getSingle(surveyId);
        setFidelityReview(fidelityReviewQuery.data);

        const questionsCount = await questionService.getQuestionCounts();
        setQuestionsCount(questionsCount.data);

        // Get Family Memers
        const familyQuery = await familyService.getByFidelityReview(surveyId);
        const familyProgressQueries = familyQuery.data.map((f) => answerService.getProgress(sourceType.FAMILY, f.uid));
        const familyProgress = (await Promise.all(familyProgressQueries)).map((fp, index) => {
            return {
                family: familyQuery.data[index],
                progress: fp.data,
            };
        });
        setFamilyProgress(familyProgress);

        // Get Clients
        const clientsQuery = await clientService.getByFidelityReview(surveyId);
        const clientsProgressQueries = clientsQuery.data.map((c) => answerService.getProgress(sourceType.CLIENT, c.uid));
        const clientsProgress = (await Promise.all(clientsProgressQueries)).map((fp, index) => {
            return {
                client: clientsQuery.data[index],
                progress: fp.data,
            };
        });
        setClientsProgress(clientsProgress);

        // Get Clients
        const employeesQuery = await employeeService.getByFidelityReview(surveyId);
        const employeesProgressQueries = employeesQuery.data.map((e) => answerService.getProgress(sourceType.EMPLOYEE, e.uid));
        const employeesProgress = (await Promise.all(employeesProgressQueries)).map((fp, index) => {
            return {
                employee: employeesQuery.data[index],
                progress: fp.data,
            };
        });
        setEmployeesProgress(employeesProgress);
        // Get SurveyProgress

        setCurrentView(view.SHOW_PROGRESS);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <h1>{t('surveyProgress.surveyProgress')}</h1>
            {fidelityReview !== null && <FidelityReviewShortInfo review={fidelityReview} />}

            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                {currentView === view.LOADING && <CircularProgress sx={{ margin: '10px 0' }} />}
                {currentView === view.SHOW_PROGRESS && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('common.type')}</TableCell>
                                    <TableCell>{t('common.name')}</TableCell>
                                    <TableCell width="100">{t('common.progress')}</TableCell>
                                    <TableCell width="70">
                                        <LinkIcon />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {employeesProgress.map((ep) => (
                                    <TableRow key={ep.employee.id}>
                                        <TableCell>{t('common.employee')}</TableCell>
                                        <TableCell>{ep.employee.name}</TableCell>
                                        <TableCell>
                                            {ep.progress.length} / {questionsCount[sourceType.EMPLOYEE]}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    window.open(
                                                        routes.TAKE_SURVEY.path
                                                            .replace(':uid', ep.employee.uid)
                                                            .replace(':id', sourceType.EMPLOYEE.toString())
                                                    );
                                                }}
                                            >
                                                <LinkIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {familyProgress.map((fp) => (
                                    <TableRow key={fp.family.id}>
                                        <TableCell>{t('common.family')}</TableCell>
                                        <TableCell>{fp.family.name}</TableCell>
                                        <TableCell>
                                            {fp.progress.length} / {questionsCount[sourceType.FAMILY]}
                                        </TableCell>
                                        <TableCell><IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    window.open(
                                                        routes.TAKE_SURVEY.path
                                                            .replace(':uid', fp.family.uid)
                                                            .replace(':id', sourceType.FAMILY.toString())
                                                    );
                                                }}
                                            >
                                                <LinkIcon />
                                            </IconButton></TableCell>
                                    </TableRow>
                                ))}
                                {clientsProgress.map((cp) => (
                                    <TableRow key={cp.client.id}>
                                        <TableCell>{t('common.client')}</TableCell>
                                        <TableCell>{cp.client.name}</TableCell>
                                        <TableCell>
                                            {cp.progress.length} / {questionsCount[sourceType.CLIENT]}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    window.open(
                                                        routes.TAKE_SURVEY.path
                                                            .replace(':uid', cp.client.uid)
                                                            .replace(':id', sourceType.CLIENT.toString())
                                                    );
                                                }}
                                            >
                                                <LinkIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </div>
    );
};

export default FidelityReviewSurveyProgress;

import Evaluators from 'pages/Evaluators/Evaluators';
import Dashboard from 'components/Dashboard/Dashboard';
import FidelityReviewListOrder from 'pages/FidelityReviewListOrder/FidelityReviewListOrder';
import role from './role';
import FidelityReview from 'pages/FidelityReview/FidelityReview';
import FidelityReviewListActive from 'pages/FidelityReviewListActive/FidelityReviewListActive';
import SetNewPassword from 'pages/SetNewPassword/SetNewPassword';
import StartPage from 'pages/StartPage/StartPage';
import RouteItem from 'types/RouteItem';
import Login from 'pages/Login/Login';
import FidelityReviewOrder from 'pages/FidelityReviewOrder/FidelityReviewOrder';
import Profile from 'pages/Profile/Profile';
import Users from 'pages/Users/Users';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Sources from 'pages/FidelityReviewSourceList/FidelityReviewSourceList';
import EmployeeCalendarEntry from 'pages/EmployeeCalendarEntry/EmployeeCalendarEntry';
import TakeSurvey from 'pages/TakeSurvey/TakeSurvey';
import FidelityReviewSurveyProgress from 'pages/FidelityReviewSurveyProgress/FidelityReviewSurveyProgress';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import FidelityReviewOrderSelect from 'pages/FidelityReviewOrderSelect/FidelityReviewOrderSelect';
import Activities from 'pages/Activities/Activities';
import EditSurvey from 'pages/EditSurvey/EditSurvey';

const routes: { [k: string]: RouteItem } = {
    // Public
    //--------------------------------------
    LOGIN: {
        path: '/login',
        private: false,
        component: <Login />,
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        private: false,
        component: <ForgotPassword />,
    },
    ORDER_FIDELITY_REVIEW: {
        path: '/fidelity-review/order/:uid',
        private: false,
        component: <FidelityReviewOrder />,
    },
    SET_NEW_PASSWORD: {
        path: '/set-new-password/:token',
        private: false,
        component: <SetNewPassword />,
    },
    ERROR_PAGE: {
        path: '/error/:code',
        private: false,
        component: <ErrorPage />,
    },

    EMPLOYEE_CALENDAR_ENTRY: {
        path: '/employee/:uid/calendar-entry',
        private: false,
        component: <EmployeeCalendarEntry />,
    },
    TAKE_SURVEY: {
        path: '/survey/:id/:uid',
        private: false,
        component: <TakeSurvey />,
    },
    FIDELITY_REVIEW_ORDER_SELECT: {
        path: '/fidelity-review/select-order',
        private: false,
        menuTitle: 'orderFidelityReview.newFidelityReview',
        component: (
            <Dashboard title={'orderFidelityReview.newFidelityReview'}>
                <FidelityReviewOrderSelect />
            </Dashboard>
        ),
    },

    // Private
    //--------------------------------------
    START_PAGE: {
        path: '/start',
        menuTitle: 'common.startPage',
        private: true,
        component: (
            <Dashboard title={'common.startPage'}>
                <StartPage />
            </Dashboard>
        ),
    },
    PROFILE: {
        path: '/profile',
        private: true,
        roles: [role.HOST, role.EVALUATOR, role.SUPERVISOR],
        component: (
            <Dashboard title={'common.profile'}>
                <Profile />
            </Dashboard>
        ),
    },

    USERS: {
        path: '/user/list',
        menuTitle: 'common.users',
        private: true,
        roles: [role.HOST],
        component: (
            <Dashboard title={'common.users'}>
                <Users />
            </Dashboard>
        ),
    },

    EDIT_SURVEY: {
        path: '/edit-survey/',
        menuTitle: 'common.editSurvey',
        private: true,
        roles: [role.HOST],
        component: (
            <Dashboard title={'common.editSurvey'}>
                <EditSurvey />
            </Dashboard>
        ),
    },

    EVALUATORS: {
        path: '/evaluator/list',
        menuTitle: 'common.evaluators',
        private: true,
        roles: [role.HOST],
        component: (
            <Dashboard title={'common.evaluators'}>
                <Evaluators />
            </Dashboard>
        ),
    },

    ACTIVITIES: {
        path: '/fidelity-review/activities/:id',
        private: true,
        component: (
            <Dashboard title={'common.activities'} pageWidth="xl">
                <Activities />
            </Dashboard>
        ),
    },

    FIDELITY_REVIEW_ORDERS: {
        path: '/fidelity-review/list-order',
        menuTitle: 'common.orders',
        private: true,
        roles: [role.EVALUATOR, role.HOST],
        component: (
            <Dashboard title={'common.orders'}>
                <FidelityReviewListOrder />
            </Dashboard>
        ),
    },

    FIDELITY_REVIEW_SURVEY_PROGRESS: {
        path: '/fidelity-review/:id/survey-progress',
        private: true,
        roles: [role.EVALUATOR, role.HOST],
        component: (
            <Dashboard title={'surveyProgress.surveyProgress'}>
                <FidelityReviewSurveyProgress />
            </Dashboard>
        ),
    },

    ACTIVE_FIDELITY_REVIEWS: {
        path: '/fidelity-review/list-active/',
        menuTitle: 'common.activeReviews',
        private: true,
        roles: [role.EVALUATOR, role.HOST],
        component: (
            <Dashboard title={'activeFidelityReviews.activeFidelityReviews'}>
                <FidelityReviewListActive />
            </Dashboard>
        ),
    },
    SOURCES: {
        path: '/fidelity-review/:id/sources',
        private: true,
        roles: [role.EVALUATOR, role.HOST, role.SUPERVISOR],
        component: (
            <Dashboard title={'common.registerSources'}>
                <Sources />
            </Dashboard>
        ),
    },

    FIDELITY_REVIEW: {
        path: '/fidelity-review/:id',
        private: true,
        component: (
            <Dashboard title={'common.fidelityReview'}>
                <FidelityReview />
            </Dashboard>
        ),
    },
};

export default routes;

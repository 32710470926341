import { Button, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import Suggestion from 'models/Suggestion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

type EditSuggestionsProps = {
    value: Suggestion[];
    questionId: number;
    onChange: (suggestions: Suggestion[]) => void;
};

const EditSuggestions: FC<EditSuggestionsProps> = ({ value, onChange, questionId }) => {
    const { t } = useTranslation();

    const addSuggestion = () => {
        const newSuggestion = {
            id: 0,
            questionId: questionId,
            order: 1,
            text: '',
            value: 0,
        };
        onChange([...value, newSuggestion]);
    };

    const deleteSuggestion = (index: number) => {
        const newSuggestions = value.filter((s, i) => i != index);
        onChange(newSuggestions);
    };

    const updateOrder = (newValue: string, index: number) => {
        let newValueInt = newValue.trim() === '' ? 0 : +newValue;
        if (isNaN(newValueInt)) {
            newValueInt = 0;
        }
        const newSuggestions = value.map((s, i) => {
            if (i === index) {
                return { ...s, order: newValueInt };
            } else {
                return s;
            }
        });
        onChange(newSuggestions);
    };

    const updateValue = (newValue: string, index: number) => {
        let newValueInt = newValue.trim() === '' ? 0 : +newValue;
        if (isNaN(newValueInt)) {
            newValueInt = 0;
        }
        const newSuggestions = value.map((s, i) => {
            if (i === index) {
                return { ...s, value: newValueInt };
            } else {
                return s;
            }
        });
        onChange(newSuggestions);
    };

    const updateText = (newValue: string, index: number) => {
        const newSuggestions = value.map((s, i) => {
            if (i === index) {
                return { ...s, text: newValue };
            } else {
                return s;
            }
        });
        onChange(newSuggestions);
    };

    return (
        <div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.order')}</TableCell>
                        <TableCell sx={{ width: '60%' }}>{t('common.text')}</TableCell>
                        <TableCell>{t('common.value')}</TableCell>
                        <TableCell>
                            <DeleteIcon />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {value &&
                        value.map((s, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        value={s.order}
                                        onChange={(e) => updateOrder(e.target.value, index)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        value={s.text}
                                        fullWidth
                                        onChange={(e) => updateText(e.target.value, index)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        value={s.value}
                                        onChange={(e) => updateValue(e.target.value, index)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => deleteSuggestion(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Button onClick={addSuggestion}>
                                <AddCircleIcon /> &nbsp;
                                {t('common.addSuggestion')}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

export default EditSuggestions;

import React, { ChangeEvent, FC, useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';

type EditSingleStringModalProps = {
    onCancel: () => void;
    onConfirm: (value: string) => void;
    label?: string;
    value?: string;
    min?: number;
    max?: number;
};

const EditSingleStringModal: FC<EditSingleStringModalProps> = ({
    onCancel,
    onConfirm,
    label = '',
    value = '',
    min,
    max,
}) => {
    const { t } = useTranslation();

    const [field, setField] = useState(value);

    const fieldChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setField(e.target.value);
    };

    const validate = () => {
        if (typeof min === 'number') {
            if (field.length < min) return false;
        }

        if (typeof max === 'number') {
            if (field.length > max) return false;
        }

        return true;
    };

    const confirmChangesHandler = () => {
        if (validate()) {
            onConfirm(field);
        } else {
            alert(
                `${label} må være stærre enn ${min - 1} / mindre enn ${max + 1}`
            );
        }
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                {capitalizeFirstLetter(t('profile.changeName'))}
            </DialogTitle>
            <DialogContent >
                <TextField
                    sx={{ marginTop: '20px'}}
                    label={label}
                    value={field}
                    onChange={fieldChangeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onCancel}>{t('cancel')}</Button>
                <Button variant='contained' onClick={confirmChangesHandler}>
                    {t('confirmChanges')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditSingleStringModal;

import { FC } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Question from 'models/Question';
import questionType from 'consts/questionType';
import Suggestion from 'models/Suggestion';
import EditSuggestions from './EditSuggestions';

type EditQuestionModalProps = {
    onCancel: () => void;
    onConfirm: (result: Question) => void;
    question?: Question;
};

type FormValues = {
    order: number;
    criteriaNo: string;
    type: number;
    text: string;
    employmentSpecialist: boolean;
    suggestions: Suggestion[];
    client: boolean;
    family: boolean;
    supervisor: boolean;
    management: boolean;
    partnerVocationalRehabilitation: boolean;
    partnerHealthOrganization: boolean;
    evaluator: boolean;
};

const EditQuestionModal: FC<EditQuestionModalProps> = ({ onCancel, onConfirm, question }) => {
    const { t } = useTranslation();

    let defaultValues: FormValues = {
        text: '',
        order: 1,
        criteriaNo: '',
        type: questionType.INPUT_INT,
        suggestions: [],
        employmentSpecialist: false,
        client: false,
        family: false,
        supervisor: false,
        management: false,
        partnerVocationalRehabilitation: false,
        partnerHealthOrganization: false,
        evaluator: false,
    };
    let newQuestion = true;
    if (question) {
        newQuestion = false;
        defaultValues = {
            text: question.text,
            order: question.order,
            criteriaNo: question.criteriaNo,
            suggestions: question.suggestions,
            type: question.type,
            employmentSpecialist: question.employmentSpecialist,
            client: question.client,
            family: question.family,
            supervisor: question.supervisor,
            management: question.management,
            partnerVocationalRehabilitation: question.partnerVocationalRehabilitation,
            partnerHealthOrganization: question.partnerHealthOrganization,
            evaluator: question.evaluator,
        };
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm<FormValues>({
        defaultValues: defaultValues,
    });
    const watchType = watch('type');

    const onSubmit: SubmitHandler<FormValues> = (formValues) => {
        const result: Question = {
            id: 0,
            suggestions: formValues.suggestions,
            order: formValues.order,
            criteriaNo: formValues.criteriaNo,
            type: formValues.type,
            text: formValues.text,
            employmentSpecialist: formValues.employmentSpecialist,
            client: formValues.client,
            family: formValues.family,
            supervisor: formValues.supervisor,
            management: formValues.management,
            partnerVocationalRehabilitation: formValues.partnerVocationalRehabilitation,
            partnerHealthOrganization: formValues.partnerHealthOrganization,
            evaluator: formValues.evaluator,
        };

        if (!newQuestion) {
            result.id = question.id;
        }
        onConfirm(result);
    };

    const suggestions = getValues('suggestions') ? getValues('suggestions').sort((a, b) => a.order - b.order) : [];
    return (
        <Dialog open={true}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '700px' }}>
                <DialogTitle>{t('common.editQuestion')}</DialogTitle>
                <DialogContent>
                    <Stack sx={{ padding: '20px 0' }} direction="column" spacing={2}>
                        <TextField
                            label={t('common.text')}
                            fullWidth
                            multiline
                            rows={2}
                            {...register('text', {
                                required: 'text is required',
                            })}
                            error={errors.text ? true : false}
                            helperText={errors.text ? errors.text.message : ''}
                        />
                        <TextField
                            label={t('common.order')}
                            fullWidth
                            {...register('order', {
                                required: 'order is required',
                                pattern: {
                                    value: /[0-9]+/,
                                    message: 'Value must be numeric', // JS only: <p>error message</p> TS only support string
                                },
                            })}
                            error={errors.order ? true : false}
                            helperText={errors.order ? errors.order.message : ''}
                        />
                        <TextField
                            label={t('common.criteriaNo')}
                            fullWidth
                            {...register('criteriaNo', {
                                required: 'criteriaNo is required',
                            })}
                            error={errors.criteriaNo ? true : false}
                            helperText={errors.criteriaNo ? errors.criteriaNo.message : ''}
                        />

                        <Select label={t('common.type')} {...register('type')} value={watchType ? watchType : 0}>
                            {Object.values(questionType).map((qType) => (
                                <MenuItem key={qType} value={qType}>
                                    {t('questionType.' + qType)}
                                </MenuItem>
                            ))}
                        </Select>

                        {watchType === questionType.MULTIPLE_CHOICE && (
                            <>
                                <h4>{t('common.suggestions')}</h4>
                                <EditSuggestions
                                    value={suggestions}
                                    questionId={newQuestion ? 0 : question.id}
                                    onChange={(s) => {
                                        console.log('Setting suggestions', s);
                                        setValue('suggestions', s, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                    }}
                                />
                            </>
                        )}
                        <hr />
                        <Stack direction="row" spacing={2}>
                            <FormControlLabel
                                {...register('employmentSpecialist')}
                                control={<Checkbox defaultChecked={getValues('employmentSpecialist')} />}
                                label={
                                    <Tooltip title={t('common.employmentSpecialist')}>
                                        <span> {t('acronyms.employmentSpecialist')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('client')}
                                control={<Checkbox defaultChecked={getValues('client')} />}
                                label={
                                    <Tooltip title={t('common.client')}>
                                        <span> {t('acronyms.client')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('family')}
                                control={<Checkbox defaultChecked={getValues('family')} />}
                                label={
                                    <Tooltip title={t('common.family')}>
                                        <span> {t('acronyms.family')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('supervisor')}
                                control={<Checkbox defaultChecked={getValues('supervisor')} />}
                                label={
                                    <Tooltip title={t('common.supervisor')}>
                                        <span> {t('acronyms.supervisor')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('partnerVocationalRehabilitation')}
                                control={<Checkbox defaultChecked={getValues('partnerVocationalRehabilitation')} />}
                                label={
                                    <Tooltip title={t('common.partnerVocationalRehabilitation')}>
                                        <span> {t('acronyms.partnerVocationalRehabilitation')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('partnerHealthOrganization')}
                                control={<Checkbox defaultChecked={getValues('partnerHealthOrganization')} />}
                                label={
                                    <Tooltip title={t('common.partnerHealthOrganization')}>
                                        <span> {t('acronyms.partnerHealthOrganization')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('management')}
                                control={<Checkbox defaultChecked={getValues('management')} />}
                                label={
                                    <Tooltip title={t('common.management')}>
                                        <span> {t('acronyms.management')}</span>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                {...register('evaluator')}
                                control={<Checkbox defaultChecked={getValues('evaluator')} />}
                                label={
                                    <Tooltip title={t('common.evaluator')}>
                                        <span> {t('acronyms.evaluator')}</span>
                                    </Tooltip>
                                }
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditQuestionModal;

import './Alerts.scss';
import { FC } from 'react';
import AlertType from 'types/Alert';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useAlert } from 'hooks';

const Alerts: FC = () => {
    const alerts = useSelector((state: RootState) => state.alerts);
    const { removeAlert } = useAlert();

    const alertComponents = alerts.map((alert: AlertType) => {
        return (
            <div key={alert.id} className={'alert-container'}>
                <Alert
                    className={'alert'}
                    elevation={4}
                    severity={alert.type}
                    action={
                        <div onClick={() => removeAlert(alert.id)}>
                            <Close />
                        </div>
                    }
                >
                    {alert.text}
                </Alert>
            </div>
        );
    });

    return <div className='alerts-container'>{alertComponents}</div>;
};

export default Alerts;

import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Client from 'models/Client';
import Employee from 'models/Employee';
import { validate } from 'utils';
import { ReactHookDatePicker, ReactHookFormSelect } from 'components';

type EditClientModalProps = {
    client: Client;
    employees: Employee[];
    onCancel: () => void;
    onConfirm: (client: Client) => void;
    term: string;
    employeeTerm: string;
};

const EditClientModal: FC<EditClientModalProps> = ({ onCancel, onConfirm, client, term, employeeTerm, employees }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<Client>({
        defaultValues: {
            ...client,
            employeeId: client?.employeeId ?? -1,
        },
    });
    const onSubmit: SubmitHandler<Client> = async (fields) => {
        const returnClient: Client = {
            id: client?.id,
            name: fields.name,
            email: fields.email,
            phone: fields.phone,
            employeeId: fields.employeeId !== -1 ? fields.employeeId : null,
            firstMeetingEmployeeDate: fields.firstMeetingEmployeeDate,
            firstMeetingEmployerDate: fields.firstMeetingEmployerDate,
        };

        // TODO: validate date
        onConfirm(returnClient);
    };

    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {client ? t('common.edit') : t('common.add')} {term}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                        <TextField
                            label={t('common.name')}
                            fullWidth
                            {...register('name', {
                                required: 'name is required',
                                minLength: 2,
                                maxLength: 50,
                            })}
                            error={errors.name ? true : false}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                        <TextField
                            label={t('common.phone')}
                            type={'tel'}
                            fullWidth
                            {...register('phone', {
                                required: 'phone is required',
                                validate: (val) => validate.phone(val),
                            })}
                            error={errors.phone ? true : false}
                            helperText={errors.phone ? errors.phone.message : ''}
                        />
                        <TextField
                            label={t('common.email')}
                            fullWidth
                            {...register('email', {
                                required: 'email is required',
                                validate: (val) => validate.email(val),
                            })}
                            error={errors.email ? true : false}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                        <ReactHookFormSelect name={'employeeId'} label={employeeTerm} control={control}>
                            <MenuItem value={-1}>{t('common.none')}</MenuItem>
                            {employees.map((e) => (
                                <MenuItem key={e.id} value={e.id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <ReactHookDatePicker
                            name={'firstMeetingEmployeeDate'}
                            label={t('common.firstMeetingEmployeeDate')}
                            control={control}
                        />
                        <ReactHookDatePicker
                            name={'firstMeetingEmployerDate'}
                            label={t('common.firstMeetingEmployerDate')}
                            control={control}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditClientModal;

import axios from 'axios';
import Client from 'models/Client';

const basePath = 'client';

const clientService = {
    countForFidelityReview: (id: number) => axios.get<{ count: number }>(`${basePath}/count-for-fidelity-review/${id}`),
    getAll: () => axios.get<Client[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Client>(`${basePath}/${id}`),
    getByFidelityReview: (id: number) => axios.get<Client[]>(`${basePath}/by-fidelity-review/${id}`),
    add: (model: Client) => axios.post<Client>(`${basePath}/`, model),
    update: (model: Client) => axios.put<Client>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getByUid: (uid: string) => axios.get<Client>(`${basePath}/get-by-uid/${uid}`)
};

export default clientService;

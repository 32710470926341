import XlsxTemplate from 'types/XlsxTemplate';
import partnerType from 'consts/partnerType';
import i18n from 'translation';

const PartnerXlsxTemplate = {
    name: `${i18n.t('common.partner')}-${i18n.t('common.template')}`,
    sheets: [
        {
            name: `${i18n.t('common.partners')}`,
            headerRow: [
                { header: `${i18n.t('common.type')}*`, width: 30 },
                { header: `${i18n.t('common.name')}*`, width: 30 },
                { header: `${i18n.t('common.title')}*`, width: 30 },
                { header: `${i18n.t('common.phone')}*`, width: 30 },
                { header: `${i18n.t('common.email')}*`, width: 35 },
            ],
        },
        {
            name: `${i18n.t('common.availible')} ${i18n.t('common.partnerTypes')}`,
            headerRow: [{ header: `${i18n.t('common.partnerTypes')}`, width: 40 }],
            rows: Object.values(partnerType).map((value) => [i18n.t(`partnerType.${value}`)]),
        },
    ],
} as XlsxTemplate;

export default PartnerXlsxTemplate;

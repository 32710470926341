import { IconButton, Paper, Stack } from '@mui/material';
import axios, { AxiosError } from 'axios';
import InfoText from 'components/InfoText/InfoText';
import routes from 'consts/routes';
import { useAlert } from 'hooks';
import CalendarEntry from 'models/CalendarEntry';
import Employee from 'models/Employee';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import calendarEntryService from 'services/calendarEntry.service';
import employeeService from 'services/employee.service';
import UidParams from 'types/UidParams';
import WeekTable from './WeekTable/WeekTable';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import PublicPageContainer from 'components/PublicPageContainer/PublicPageContainer';

const EmployeeCalendarEntry = () => {
    const { t } = useTranslation();
    const { uid } = useParams<UidParams>();
    const history = useHistory();
    const { addAlert } = useAlert();
    const [employee, setEmployee] = useState<Employee>(null);
    const [calendarEntries, setCalendarEntries] = useState<CalendarEntry[]>(null);
    const [startOfWeek, setStartOfWeek] = useState<Moment>(moment().startOf('isoWeek'));
    const endOfWeek = startOfWeek.clone();
    endOfWeek.add(7, 'days');

    if (!uid) {
        return <ErrorPage code={'702'} />;
    }

    const init = async () => {
        try {
            const employeeData = (await employeeService.getByUid(uid)).data;
            setEmployee(employeeData);
        } catch (error) {
            let message = 'Client error';
            if (axios.isAxiosError(error)) {
                const axiosError: AxiosError = error;
                if (axiosError.response && axiosError.response.status === 404) {
                    // Show employee information?
                    history.push(routes.ERROR_PAGE.path.replace(':code', '701'));
                    return;
                } else {
                    message = axiosError.message;
                }
            }
            addAlert('error', message);
        }
        const calendarEntriesData = (await calendarEntryService.getByEmployeeUid(uid)).data;
        setCalendarEntries(calendarEntriesData);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <PublicPageContainer maxWidth={980}>
            <h1 style={{ textAlign: 'center' }}>{t('common.calendarEntry')}</h1>
            <Paper sx={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
                {employee !== null && <InfoText label={t('common.name')} value={employee.name} />}
            </Paper>

            <Paper sx={{ maxWidth: '200px', margin: '20px auto', padding: '20px', textAlign: 'center' }}>
                <h3 style={{ margin: 0 }}>{t('common.week')}</h3>
                <Stack direction="row" spacing={2} justifyContent={'center'}>
                    <IconButton
                        onClick={() => {
                            const newDate = startOfWeek.clone();
                            newDate.add(-7, 'days');
                            setTimeout(() => {
                                setStartOfWeek(newDate);
                            }, 10); // Set timout to allow update before rerender.
                        }}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <div style={{ fontSize: '24px' }}>{startOfWeek.week()}</div>
                    <IconButton
                        onClick={() => {
                            const newDate = startOfWeek.clone();
                            newDate.add(7, 'days');
                            setTimeout(() => {
                                setStartOfWeek(newDate);
                            }, 10); // Set timout to allow update before rerender.
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Stack>
                <small style={{ color: '#888' }}>
                    {startOfWeek.format('DD.MM.YYYY')} - {endOfWeek.format('DD.MM.YYYY')}
                </small>
            </Paper>

            {calendarEntries !== null && (
                <WeekTable uid={uid} startOfWeek={startOfWeek} calendarEntries={calendarEntries} setCalendarEntries={setCalendarEntries} />
            )}
        </PublicPageContainer>
    );
};

export default EmployeeCalendarEntry;

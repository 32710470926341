import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'models/User';

const initialState = null as User;

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<User>) => {
            return action.payload;
        },
        remove: () => {
            return initialState;
        },
    },
});

export const { actions: userActions } = userSlice;

export default userSlice.reducer;

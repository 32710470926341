import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import FidelityReview from 'models/FidelityReview';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmOrderProps = {
    onSend: () => void;
    onPrevious: () => void;
    order: FidelityReview;
};

const ConfirmOrder: FC<ConfirmOrderProps> = ({ onSend, onPrevious }) => {
    const { t } = useTranslation();
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
    const [confirmAuthority, setConfirmAuthority] = useState(false);
    const [error, setError] = useState(false);

    return (
        <div>
            <h2>{t('orderFidelityReview.confirmAndSendOrder')}</h2>
            <Stack>
                <p>
                    <a href="https://ips-norge.no/personvern" target="_blank" rel="noreferrer">
                        {t('orderFidelityReview.readPrivacyPolicy')}
                    </a>
                </p>
                <FormControl error={error && (!acceptedPrivacyPolicy || !confirmAuthority)}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptedPrivacyPolicy}
                                onChange={(event) => {
                                    setAcceptedPrivacyPolicy(event.target.checked);
                                }}
                                name="acceptedPrivacyPolicy"
                            />
                        }
                        label={t('orderFidelityReview.iAcceptPrivacyPolicy').toString()}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirmAuthority}
                                onChange={(event) => {
                                    setConfirmAuthority(event.target.checked);
                                }}
                                name="confirmAuthority"
                            />
                        }
                        label={t('orderFidelityReview.iConfirmAuthority').toString()}
                    />

                    {error && (!acceptedPrivacyPolicy || !confirmAuthority) && (
                        <FormHelperText>{t('orderFidelityReview.pleaceCheckBothBoxesToProceed')}</FormHelperText>
                    )}
                </FormControl>
                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                <div>
                    <Button
                        onClick={() => {
                            onPrevious();
                        }}
                        variant="outlined"
                    >
                        {t('common.previous')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (!confirmAuthority || !acceptedPrivacyPolicy) {
                                setError(true);
                            } else {
                                onSend();
                            }
                        }}
                        variant="contained"
                        sx={{ float: 'right' }}
                    >
                        {t('common.send')}
                    </Button>
                </div>
            </Stack>
        </div>
    );
};

export default ConfirmOrder;

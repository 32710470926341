/** react.js version
 * a component for a react component to show percentage circle
 **/
import { FC } from 'react';

import './PercentageCircle.scss';

type PercentageCircleProp = {
    color?: string;
    bgColor?: string;
    innerColor?: string;
    radius?: number;
    percent?: number;
    borderWidth?: number;
    textStyle?: string;
};

const adjustColor = (value: number) => {
    // value from 0 to 1
    const hue = ((value / 100) * 120).toString(10);

    return ['hsl(', hue, ',100%,40%)'].join('');
};

const PercentageCircle: FC<PercentageCircleProp> = (props) => {
    const defaultProps = {
        color: '#000',
        radius: 20,
        percent: 0,
        borderWidth: 4,
        bgColor: '#e3e3e3',
        innerColor: '#fff',
        disabled: false,
        textStyle: 'bold',
    };
    props = { ...defaultProps, ...props };

    let adjust = false;
    if (props.color === 'adjust') {
        props.color = adjustColor(props.percent);
        adjust = true;
    }

    const percent = props.percent;
    let leftTransformerDegree = '0deg';
    let rightTransformerDegree = '0deg';
    if (percent >= 50) {
        rightTransformerDegree = '180deg';
        leftTransformerDegree = (percent - 50) * 3.6 + 'deg';
    } else {
        rightTransformerDegree = percent * 3.6 + 'deg';
        leftTransformerDegree = '0deg';
    }
    const state = {
        percent: props.percent,
        borderWidth: props.borderWidth < 2 || !props.borderWidth ? 2 : props.borderWidth,
        leftTransformerDegree: leftTransformerDegree,
        rightTransformerDegree: rightTransformerDegree,
    };
    return (
        <div
            className="percentage-circle"
            style={{
                width: props.radius * 2,
                height: props.radius * 2,
                borderRadius: props.radius,
                backgroundColor: props.bgColor,
            }}
        >
            <div
                className="left-wrap"
                style={{
                    width: props.radius,
                    height: props.radius * 2,
                    left: 0,
                }}
            >
                <div
                    className="loader"
                    id="id1"
                    style={{
                        left: props.radius,
                        width: props.radius,
                        height: props.radius * 2,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        backgroundColor: props.color,
                        transform: 'rotate(' + state.leftTransformerDegree + ')',
                    }}
                />
            </div>
            <div
                className="right-wrap"
                style={{
                    width: props.radius,
                    height: props.radius * 2,
                    left: props.radius,
                }}
            >
                <div
                    className="loader2"
                    id="id2"
                    style={{
                        left: -props.radius,
                        width: props.radius,
                        height: props.radius * 2,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: props.color,
                        transform: 'rotate(' + state.rightTransformerDegree + ')',
                    }}
                />
            </div>
            <div
                className="inner-circle"
                style={{
                    left: props.borderWidth,
                    top: props.borderWidth,
                    width: (props.radius - props.borderWidth) * 2,
                    height: (props.radius - props.borderWidth) * 2,
                    borderRadius: props.radius - props.borderWidth,
                    backgroundColor: props.innerColor,
                }}
            >
                {props.children ? (
                    props.children
                ) : (
                    <span className={'text ' + props.textStyle} style={{ color: adjust ? props.color : 'inherit' }}>
                        {props.percent}%
                    </span>
                )}
            </div>
        </div>
    );
};

export default PercentageCircle;

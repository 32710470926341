import './Users.scss';
import { FC, useEffect, useState } from 'react';
import { AddUserModal, ConsentModal } from 'components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Paper } from '@mui/material';
import userService from 'services/user.service';
import User from 'types/User';
import { useAlert } from 'hooks';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Users: FC = () => {
    const [users, setUsers] = useState<User[]>(null);
    const getUsers = async () => {
        try {
            if (users) {
                setUsers(null);
            }
            const { data } = await userService.getUsers();
            setUsers(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);
    const [addUserModal, setAddUserModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null as User);
    const { addAlert } = useAlert();
    const { t } = useTranslation();

    const deleteUser = async () => {
        try {
            await userService.deleteUser(userToDelete.id);
            addAlert(
                'success',
                `User ${userToDelete.name} has been deleted`
            );
            const updatedUsers = users.filter(
                (user) => user.id != userToDelete.id
            );
            setUsers(updatedUsers);
        } catch (error) {
            console.error(error);
            addAlert('error', 'Could not delete user');
        } finally {
            setUserToDelete(null);
        }
    };

    const postUserAdded = (user: User) => {
        setAddUserModal(false);
        getUsers();
        addAlert('success', t('users.userAddedMessage', user.name), 5000);
    };

    return (
        <div className={'users-page'}>
            <h1>{t('common.users')}</h1>
            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                {users === null ? (
                    <CircularProgress sx={{ margin: '10px 0' }} />
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('common.role')}</TableCell>
                                    <TableCell>{t('common.name')}</TableCell>
                                    <TableCell>{t('common.email')}</TableCell>
                                    <TableCell>{t('common.phone')}</TableCell>
                                    <TableCell align='center' width='50'>
                                        <DeleteIcon />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{t('role.' + user.role)}</TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone}</TableCell>
                                        <TableCell align='center' width='50'>
                                            <IconButton
                                                onClick={() =>
                                                    setUserToDelete(user)
                                                }
                                            >
                                                <DeleteIcon color="secondary"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>

            <Button
                variant='contained'
                sx={{ marginTop: '20px' }}
                onClick={() => {
                    setAddUserModal(true);
                }}
            >
                Add user
            </Button>
            {addUserModal && (
                <AddUserModal
                    onConfirm={postUserAdded}
                    onCancel={() => setAddUserModal(false)}
                />
            )}
            {userToDelete && (
                <ConsentModal
                    title={'Delete user'}
                    content={`Are you sure you want to delete user ${userToDelete.name}`}
                    onCancel={() => setUserToDelete(null)}
                    onConfirm={deleteUser}
                />
            )}
        </div>
    );
};

export default Users;

import { Button, Divider } from '@mui/material';
import EmployeeList from './EmployeeList/EmployeeList';
import FidelityReview from 'models/FidelityReview';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditEmployeeModal from './EditEmployeeModal/EditEmployeeModal';
import employeeService from 'services/employee.service';
import Employee from 'models/Employee';
import { ImportModal } from 'components';
import { AxiosResponse } from 'axios';
import { Download } from '@mui/icons-material';
import SupervisorXlsxTemplate from 'models/XlsxTemplate/SupervisorXlsxTemplate';
import SupervisorModel from 'models/XlsxImport/SupervisorModel';

type RegisterEmployeesProps = {
    fidelityReview: FidelityReview;
};

const RegisterEmployees: FC<RegisterEmployeesProps> = ({ fidelityReview }) => {
    const { t } = useTranslation();

    const term: string = t('common.employee');

    const [modal, setModal] = useState(0);

    // Employees
    // -------------------------------------------
    const [employees, setEmployees] = useState<Employee[]>(null);
    const [employeeToEdit, setEmployeeToEdit] = useState(null);
    const getEmployees = async (id: number) => {
        if (id === null) {
            return;
        }
        // Get Supervisor Reviews
        const result = (await employeeService.getByFidelityReview(id)).data;
        setEmployees(result);
    };

    const addEmployee = async (employee: Employee) => {
        setEmployees(null);
        employee.fidelityReviewId = fidelityReview.id;
        await employeeService.add(employee);
        setModal(0);
        getEmployees(fidelityReview.id);
    };

    const updateEmployee = async (employee: Employee) => {
        setEmployees(null);
        await employeeService.update(employee);
        setEmployeeToEdit(null);
        setModal(0);
        getEmployees(fidelityReview.id);
    };

    const deleteEmployee = async (employeeId: number) => {
        setEmployees(null);
        await employeeService.delete(employeeId);
        setModal(0);
        getEmployees(fidelityReview.id);
    };

    useEffect(() => {
        getEmployees(fidelityReview.id);
    }, []);

    const handleImport = async (newEmployees: Employee[]) => {
        setModal(null);
        const pmArr = [] as Promise<AxiosResponse<Employee>>[];

        newEmployees.forEach((newEmployee) => {
            newEmployee.fidelityReviewId = fidelityReview.id;
            pmArr.push(employeeService.add(newEmployee));
        });

        await Promise.all(pmArr)
            .then()
            .catch()
            .finally(() => {
                getEmployees(fidelityReview.id);
            });
    };

    return (
        <div>
            <EmployeeList
                employees={employees}
                editEmployee={(employee) => {
                    setEmployeeToEdit(employee);
                    setModal(1);
                }}
                deleteEmployee={deleteEmployee}
            />
            <Divider />
            <Button variant="contained" sx={{ margin: '20px 10px' }} onClick={() => setModal(1)}>
                {t('common.add')} {term}
            </Button>
            <Button endIcon={<Download />} variant="outlined" sx={{ margin: '20px 0' }} onClick={() => setModal(2)}>
                {t('common.import')}
            </Button>
            {modal === 1 && (
                <EditEmployeeModal
                    employee={employeeToEdit}
                    onConfirm={(employee) => (employeeToEdit ? updateEmployee(employee) : addEmployee(employee))}
                    onCancel={() => {
                        setEmployeeToEdit(null);
                        setModal(0);
                    }}
                    term={fidelityReview.employmentSpecialistTerm}
                />
            )}
            {modal === 2 && (
                <ImportModal
                    onSave={handleImport}
                    onCancel={() => setModal(1)}
                    title={'Import employees'}
                    xlsxTemplate={SupervisorXlsxTemplate}
                    importModel={SupervisorModel}
                />
            )}
        </div>
    );
};

export default RegisterEmployees;

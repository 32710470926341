import { Button, Divider } from '@mui/material';
import FidelityReview from 'models/FidelityReview';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import partnerService from 'services/partner.service';
import Partner from 'models/Partner';
import EditPartnerModal from './EditPartnerModal/EditPartnerModal';
import PartnerList from './PartnerList/PartnerList';
import { ImportModal } from 'components';
import { AxiosResponse } from 'axios';
import partnerType from 'consts/partnerType';
import { Download } from '@mui/icons-material';
import PartnerXlsxTemplate from 'models/XlsxTemplate/PartnerXlsxTemplate';
import PartnerModel from 'models/XlsxImport/PartnerModel';

type RegisterPartnersProps = {
    fidelityReview: FidelityReview;
};

const RegisterPartners: FC<RegisterPartnersProps> = ({ fidelityReview }) => {
    const { t } = useTranslation();

    const term: string = t('common.partner');

    const [modal, setModal] = useState(0);

    // Partners
    // -------------------------------------------
    const [partners, setPartners] = useState<Partner[]>(null);
    const [partnerToEdit, setPartnerToEdit] = useState(null);
    const getPartners = async (id: number) => {
        if (id === null) {
            return;
        }
        // Get Supervisor Reviews
        const result = (await partnerService.getByFidelityReview(id)).data;
        setPartners(result);
    };

    const addPartner = async (partner: Partner) => {
        setPartners(null);
        partner.fidelityReviewId = fidelityReview.id;
        await partnerService.add(partner);
        setModal(0);
        getPartners(fidelityReview.id);
    };

    const updatePartner = async (partner: Partner) => {
        setPartners(null);
        await partnerService.update(partner);
        setPartnerToEdit(null);
        setModal(0);
        getPartners(fidelityReview.id);
    };

    const deletePartner = async (partnerId: number) => {
        setPartners(null);
        await partnerService.delete(partnerId);
        setModal(0);
        getPartners(fidelityReview.id);
    };

    useEffect(() => {
        getPartners(fidelityReview.id);
    }, []);

    const handleImport = async (newPartners: Partner[]) => {
        setModal(null);
        const pmArr = [] as Promise<AxiosResponse<Partner>>[];

        newPartners.forEach((newPartner) => {
            newPartner.fidelityReviewId = fidelityReview.id;
            newPartner.type =
                Object.values(partnerType).find((partnerTypeValue) => {
                    return newPartner.typeName == t(`partnerType.${partnerTypeValue}`);
                }) ?? null;

            partnerType.HEALTH_ORGANIZATION;

            pmArr.push(partnerService.add(newPartner));
        });

        await Promise.all(pmArr)
            .then((res) => {
                res.forEach((element) => {
                    console.log('element', element);
                });
            })
            .catch()
            .finally(() => {
                getPartners(fidelityReview.id);
            });
    };

    return (
        <div>
            <PartnerList
                partners={partners}
                editPartner={(partner) => {
                    setPartnerToEdit(partner);
                    setModal(1);
                }}
                deletePartner={deletePartner}
            />
            <Divider />
            <Button variant="contained" sx={{ margin: '20px 10px' }} onClick={() => setModal(1)}>
                {t('common.add')} {term}
            </Button>
            <Button variant="outlined" sx={{ margin: '20px 0' }} onClick={() => setModal(2)} endIcon={<Download />}>
                {t('common.import')}
            </Button>
            {modal === 1 && (
                <EditPartnerModal
                    partner={partnerToEdit}
                    onConfirm={(partner) => (partnerToEdit ? updatePartner(partner) : addPartner(partner))}
                    onCancel={() => {
                        setPartnerToEdit(null);
                        setModal(0);
                    }}
                />
            )}
            {modal === 2 && (
                <ImportModal
                    onSave={handleImport}
                    onCancel={() => setModal(0)}
                    title={'Import partners'}
                    xlsxTemplate={PartnerXlsxTemplate}
                    importModel={PartnerModel}
                />
            )}
        </div>
    );
};

export default RegisterPartners;

import { FC } from 'react';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Client from 'models/Client';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

type ClientListProps = {
    clients: Client[];
    employeeTerm: string;
    editClient: (client: Client) => void;
    deleteClient: (id: number) => void;
};

const ClientList: FC<ClientListProps> = ({ clients, employeeTerm, editClient, deleteClient }) => {
    const { t } = useTranslation();

    return (
        <div>
            {clients === null ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>{t('common.name')}</TableCell>
                                <TableCell>{t('common.phone')}</TableCell>
                                <TableCell>{t('common.email')}</TableCell>
                                <TableCell>{employeeTerm}</TableCell>
                                <TableCell>{t('common.firstMeetingEmployeeDate', { employee: employeeTerm.toLowerCase() })}</TableCell>
                                <TableCell>{t('common.firstMeetingEmployerDate')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map((client) => (
                                <TableRow key={client.id}>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => editClient(client)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{client.name}</TableCell>
                                    <TableCell>{client.phone}</TableCell>
                                    <TableCell>{client.email}</TableCell>
                                    <TableCell>{client.employee?.name || t('common.none')}</TableCell>
                                    <TableCell>{moment(client.firstMeetingEmployeeDate).format('MM.DD.YYYY')}</TableCell>
                                    <TableCell>{moment(client.firstMeetingEmployerDate).format('MM.DD.YYYY')}</TableCell>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => deleteClient(client.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {clients.length === 0 && (
                        <p style={{ textAlign: 'center' }}>
                            <i>{t('common.theListIsEmpty')}</i>
                        </p>
                    )}
                </TableContainer>
            )}
        </div>
    );
};

export default ClientList;

import { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/lab';
import Employee from 'models/Employee';

type EditEmployeeModalProps = {
    employee: Employee;
    onCancel: () => void;
    onConfirm: (employee: Employee) => void;
    term: string;
};

type FormValues = {
    name: string;
    email: string;
    phone: string;
    vacancyRate: number;
    startDate: Date;
};

const EditEmployeeModal: FC<EditEmployeeModalProps> = ({ onCancel, onConfirm, employee, term }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            name: employee?.name,
            email: employee?.email,
            phone: employee?.phone,
            vacancyRate: employee?.vacancyRate,
            startDate: employee?.startDate,
        },
    });

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        //TODO CreateEvaluator
        const returnEmployee: Employee = {
            id: employee?.id,
            name: fields.name,
            email: fields.email,
            phone: fields.phone,
            vacancyRate: fields.vacancyRate,
            startDate: fields.startDate,
        };
        onConfirm(returnEmployee);
    };

    // DatePicker
    const [tempDate, setTempDate] = useState<Date>(null);

    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{employee ? t('common.edit') : t('common.add')} {term}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                        <TextField
                            label={t('common.name')}
                            fullWidth
                            {...register('name', {
                                required: 'name is required',
                            })}
                            error={errors.name ? true : false}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                        <TextField
                            label={t('common.phone')}
                            fullWidth
                            {...register('phone', {
                                required: 'phone is required',
                            })}
                            error={errors.phone ? true : false}
                            helperText={errors.phone ? errors.phone.message : ''}
                        />
                        <TextField
                            label={t('common.email')}
                            fullWidth
                            {...register('email', {
                                required: 'email is required',
                            })}
                            error={errors.email ? true : false}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                        <TextField
                            label={t('common.vacancyRate')}
                            fullWidth
                            {...register('vacancyRate', {
                                required: 'vacancyRate is required',
                                min: 0,
                                max: 100,
                            })}
                            error={errors.vacancyRate ? true : false}
                            helperText={errors.vacancyRate ? errors.vacancyRate.message : ''}
                        />
                        <DatePicker
                            value={tempDate}
                            onChange={(date: Date) => {
                                setTempDate(date);
                                setValue('startDate', date, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            label={t('common.startDate')}
                            inputFormat="DD.MM.yyyy"
                            renderInput={(params: TextFieldProps) => <TextField {...params} />}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditEmployeeModal;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Download } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Import from 'types/Import';

import './ImportModal.scss';
import moment from 'moment';
//import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import ExcelJs from 'exceljs';
import XlsxTemplate from 'types/XlsxTemplate';
import ImportView from 'types/ImportView';
import ImportViewComponent from './ImportView/ImportView';

type ImportModalProps = {
    title: string;
    importModel: Import[];
    onCancel: () => void;
    onSave: (importData: any[]) => void;
    xlsxTemplate?: XlsxTemplate;
};

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

const ImportModal: FC<ImportModalProps> = ({ title, xlsxTemplate, onCancel, onSave, importModel }) => {
    const { t } = useTranslation();

    const [file, setFile] = useState<File>(null);
    const [importData, setImportData] = useState(null);
    const [importView, setImportView] = useState<Array<ImportView[]>>([]);
    const [importValid, setImportValid] = useState<boolean>(null);

    const handleSave = () => {
        onSave(importData);
    };

    const handleFileOnLoad = (e: any) => {
        const tempFile = e.target.files[0];
        setFile(tempFile);
    };

    const handleImport = () => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            const data = event.target.result as ArrayBuffer;
            const workbook = new ExcelJs.Workbook();
            await workbook.xlsx.load(data);

            const worksheets = workbook.worksheets;

            const importDataTemp = [] as any[];
            const importViewTemp = [] as Array<ImportView[]>;
            let valid = true;

            worksheets.forEach((worksheet, index) => {
                // Only import first worksheet
                if (index == 0) {
                    worksheet.eachRow((row, rowNumber) => {
                        // Skip row 1 for import
                        // TODO: if only on row show error that sheet has no data
                        if (rowNumber !== 1) {
                            const rowValues = row.values as [];
                            const parsedImportData = {} as any;
                            const parsedImportView = [] as ImportView[];

                            importModel.forEach(({ key, validate: modelValidate, required }, index) => {
                                let notDefined = true;
                                let importValue = null;

                                // Column
                                if (index < rowValues.length - 1) {
                                    // row from import
                                    importValue = rowValues[index + 1];
                                    notDefined = importValue === undefined || importValue === null;
                                }

                                parsedImportData[key] = importValue;

                                // Setup validation
                                let isValid = false;
                                const isDefined = !notDefined;

                                // Validate value
                                if (notDefined && !required) {
                                    // If !required and is null then null is valid
                                    isValid = true;

                                    // Is defined
                                } else if (isDefined) {
                                    isValid = modelValidate(parsedImportData[key]);
                                }

                                // If importValue is valid date, change to date object
                                const d = moment(importValue, 'DD.MM.YYYY', true);

                                parsedImportData[key] = d.isValid() ? d : importValue;
                                const importValueIsDate = d.isValid();

                                // Creates date string in correct viewing format
                                const viewValue = importValueIsDate
                                    ? moment(parsedImportData[key]).format('MM.DD.YYYY')
                                    : parsedImportData[key];

                                const importViewItem = {
                                    value: viewValue,
                                    valid: isValid,
                                } as ImportView;

                                if (!importViewItem.valid) {
                                    valid = false;
                                }

                                parsedImportView.push(importViewItem);
                            });

                            importViewTemp.push(parsedImportView);
                            importDataTemp.push(parsedImportData);
                        }
                    });
                }
            });
            setImportValid(valid);
            setImportData(importDataTemp);
            setImportView(importViewTemp);
        };
        reader.readAsBinaryString(file);
    };

    const createXlsx = async () => {
        const workbook = new ExcelJs.Workbook();

        xlsxTemplate.sheets.forEach((worksheet) => {
            const sheet = workbook.addWorksheet(worksheet.name);

            const headerColumns = [] as Partial<ExcelJs.Column>[];
            worksheet.headerRow.forEach((headerColumn) => {
                headerColumns.push(headerColumn);
            });
            sheet.columns = headerColumns;

            if (worksheet.rows) {
                worksheet.rows.forEach((row) => {
                    sheet.addRow(row);
                });
            }

            worksheet.headerRow.forEach((headerColumn, index) => {
                // Cell row and column starts at 1
                sheet.getCell(1, index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '00CCCCCC' },
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();

        return new Blob([buffer], {
            type: 'application/octet-stream',
        });
    };

    const saveFileOnUserDevice = async function (filename: string) {
        // content: blob, name: string
        const blob = await createXlsx();

        if (navigator.msSaveBlob) {
            // For ie and Edge
            return navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = filename + '.xlsx';
            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );
            link.remove();
            window.URL.revokeObjectURL(link.href);
        }
    };

    return (
        <Dialog className={'import-modal'} open={true}>
            <div style={{ minWidth: 650, maxWidth: '90vw' }}>
                <DialogTitle>
                    <div className="title-container">{title}</div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px', padding: '15px 0' }}>
                        <div>
                            <Typography fontSize={'1.25rem'} style={{ padding: 0, margin: 0 }}>
                                Preview
                            </Typography>
                            {importValid === false && (
                                <Typography color={'error'}>Fiks røde felter før lagring. Felter med * kan ikke være tomme</Typography>
                            )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            {file && <Typography>{file.name}</Typography>}
                            <Button component="label">
                                Upload XLSX
                                <input type="file" hidden onChange={handleFileOnLoad} accept=".xlsx,.xls" />
                            </Button>
                            <Button
                                onClick={handleImport}
                                disabled={!file ? true : false}
                                variant="contained"
                                component="label"
                                endIcon={<GetAppIcon />}
                            >
                                {t('common.import')}
                            </Button>
                        </div>
                    </div>
                    <ImportViewComponent importView={importView} importModel={importModel} />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <div>
                        {xlsxTemplate && (
                            <Button onClick={() => saveFileOnUserDevice(xlsxTemplate.name)} endIcon={<Download />}>
                                {t('import.downloadTemplate')}
                            </Button>
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Button onClick={onCancel} variant="outlined">
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={handleSave} variant="contained" disabled={importData && importValid ? false : true}>
                            {t('common.save')}
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ImportModal;

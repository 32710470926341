import { Button, CircularProgress, Divider } from '@mui/material';
import ClientList from './ClientList/ClientList';
import FidelityReview from 'models/FidelityReview';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditClientModal from './EditClientModal/EditClientModal';
import clientService from 'services/client.service';
import Client from 'models/Client';
import employeeService from 'services/employee.service';
import Employee from 'models/Employee';
import { GetApp } from '@mui/icons-material';
import { ImportModal } from 'components';
import { AxiosResponse } from 'axios';

import ClientFields from 'types/ClientFields';
import ClientModel from 'models/XlsxImport/ClientModel';
import clientXlsxTemplate from 'models/XlsxTemplate/ClientXlsxTemplate';
import XlsxTemplate from 'types/XlsxTemplate';

type RegisterClientsProps = {
    fidelityReview: FidelityReview;
};

const RegisterClients: FC<RegisterClientsProps> = ({ fidelityReview }) => {
    const { t } = useTranslation();

    const term: string = t('common.client');
    const [modal, setModal] = useState(0);

    // Clients
    // -------------------------------------------
    const [clients, setClients] = useState<Client[]>(null);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [clientToEdit, setClientToEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [clientXlsxTemplateState, setClientXlsxTemplateState] = useState<XlsxTemplate>();

    const getClients = async (id: number) => {
        if (id === null) {
            return;
        }
        // Get Supervisor Reviews
        const [clientsQuery, employeesQuery] = await Promise.all([
            clientService.getByFidelityReview(id),
            employeeService.getByFidelityReview(id),
        ]);
        const employees = employeesQuery.data;
        const clients = clientsQuery.data.map((c) => {
            c.employee = employees.find((e) => e.id === c.employeeId);
            return c;
        });

        clientXlsxTemplate.sheets[1].rows = [];
        employees.forEach((employee) => {
            clientXlsxTemplate.sheets[1].rows.push([employee.name]);
        });

        setClientXlsxTemplateState(clientXlsxTemplate);
        setEmployees(employees);
        setClients(clients);
    };

    const addClient = async (client: Client) => {
        setClients(null);
        client.fidelityReviewId = fidelityReview.id;
        await clientService.add(client);
        setModal(0);
        getClients(fidelityReview.id);
    };

    const updateClient = async (client: Client) => {
        setModal(0);
        setClients(null);
        await clientService.update(client);
        setClientToEdit(null);
        getClients(fidelityReview.id);
    };

    const deleteClient = async (clientId: number) => {
        setModal(0);
        setClients(null);
        await clientService.delete(clientId);
        getClients(fidelityReview.id);
    };

    useEffect(() => {
        getClients(fidelityReview.id);
    }, []);

    const handleImport = async (clientsFields: ClientFields[]) => {
        setModal(null);
        const pmArr = [] as Promise<AxiosResponse<Client>>[];

        // Validate that employee exists
        const newClients = [] as Client[];

        clientsFields.forEach((clientFields) => {
            const employee = employees.find((employee) => clientFields.employeeName === employee.name);
            newClients.push({ ...clientFields, employeeId: employee?.id ?? null, fidelityReviewId: fidelityReview.id });
        });

        setLoading(true);
        newClients.forEach((newClients) => {
            pmArr.push(clientService.add(newClients));
        });

        await Promise.all(pmArr)
            .then((res) => {
                res.forEach((element) => {
                    console.log('element', element);
                });
            })
            .catch()
            .finally(() => {
                setLoading(false);
                getClients(fidelityReview.id);
            });
    };

    return (
        <div>
            {loading && <CircularProgress sx={{ margin: '10px 0' }} />}
            {!loading && (
                <ClientList
                    clients={clients}
                    employeeTerm={fidelityReview.employmentSpecialistTerm}
                    editClient={(client) => {
                        setClientToEdit(client);
                        setModal(1);
                    }}
                    deleteClient={deleteClient}
                />
            )}
            <Divider />
            <Button variant="contained" sx={{ margin: '20px 10px 20px 20px' }} onClick={() => setModal(1)}>
                {t('common.add')} {term}
            </Button>
            <Button variant="outlined" sx={{ margin: '20px 0' }} onClick={() => setModal(2)} endIcon={<GetApp />}>
                {t('common.import')}
            </Button>
            {modal === 1 && (
                <EditClientModal
                    client={clientToEdit}
                    employees={employees}
                    onConfirm={(client) => (clientToEdit ? updateClient(client) : addClient(client))}
                    onCancel={() => {
                        setClientToEdit(null);
                        setModal(0);
                    }}
                    term={fidelityReview.clientTerm}
                    employeeTerm={fidelityReview.employmentSpecialistTerm}
                />
            )}
            {modal === 2 && (
                <ImportModal
                    onSave={handleImport}
                    onCancel={() => setModal(0)}
                    title={'Import clients'}
                    xlsxTemplate={clientXlsxTemplateState}
                    importModel={ClientModel}
                />
            )}
        </div>
    );
};

export default RegisterClients;

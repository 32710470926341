// formatString = Function that will replace notations in string with object properties.
//
// Ref: https://stackoverflow.com/questions/7975005/format-a-javascript-string-using-placeholders-and-an-object-of-substitutions
//
// Usage:
// -----------------------------------------------------
// const str = 'My name is {name} and my age is {age}.';
//
// const obj = {
//   name: 'Mike',
//   age: 32,
// };
//
// const result = formatString(str, obj)
// -----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatString = (str:string , obj: any) => {
    return str.replace(/{(\w+)}/g, (placeholderWithDelimiters: string, placeholderWithoutDelimiters: string) =>
        Object.prototype.hasOwnProperty.call(obj, placeholderWithoutDelimiters)
            ? obj[placeholderWithoutDelimiters]
            : placeholderWithDelimiters
    );
};

export default formatString;

import { FC } from 'react';
import './InviteEvaluatorModal.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Evaluator from 'models/Evaluator';
import { SubmitHandler, useForm } from 'react-hook-form';
import evaluatorService from 'services/evaluator.service';
import { validate } from 'utils';

type InviteEvaluatorModalProps = {
    onCancel: () => void;
    onConfirm: () => void;
};

type FormValues = {
    agencyName: string;
    evaluatorName: string;
    evaluatorEmail: string;
    evaluatorPhone: string;
};

const InviteEvaluatorModal: FC<InviteEvaluatorModalProps> = ({ onCancel, onConfirm }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        //TODO CreateEvaluator

        const evaluator: Evaluator = {
            agencyName: fields.agencyName,
            userId: 2,
            user: {
                name: fields.evaluatorName,
                email: fields.evaluatorEmail,
                phone: fields.evaluatorPhone,
            },
            status: 0,
        };
        await evaluatorService.add(evaluator);
        onConfirm();
    };

    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('evaluators.inviteNew')}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                        <TextField
                            label={t('common.agencyName')}
                            fullWidth
                            {...register('agencyName', {
                                required: 'agencyName is required',
                            })}
                            error={errors.agencyName ? true : false}
                            helperText={errors.agencyName ? errors.agencyName.message : ''}
                        />
                        <TextField
                            label={t('common.evaluatorName')}
                            fullWidth
                            {...register('evaluatorName', {
                                required: 'evaluatorName is required',
                            })}
                            error={errors.evaluatorName ? true : false}
                            helperText={errors.evaluatorName ? errors.evaluatorName.message : ''}
                        />
                        <TextField
                            label={t('common.email')}
                            fullWidth
                            
                            {...register('evaluatorEmail', {
                                required: 'evaluatorEmail is required',
                                validate: validate.email
                            })}
                            error={errors.evaluatorEmail ? true : false}
                            helperText={errors.evaluatorEmail ? errors.evaluatorEmail.message : ''}
                        />
                        <TextField
                            label={t('common.phone')}
                            fullWidth
                            type={'tel'}
                            {...register('evaluatorPhone', {
                                required: 'evaluatorPhone is required',
                                validate: validate.phone,
                            })}
                            error={errors.evaluatorPhone ? true : false}
                            helperText={errors.evaluatorPhone ? errors.evaluatorPhone.message : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.send')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default InviteEvaluatorModal;

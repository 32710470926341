import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationInformationForm from './OrganizationInformationForm/OrganizationInformationForm';

import './FidelityReviewOrder.scss';
import FidelityReview from '../../models/FidelityReview';
import TermsForm from './TermsForm/TermsForm';
import ConfirmOrder from './ConfirmOrder/ConfirmOrder';
import { Alert, CircularProgress, Container, Paper } from '@mui/material';
import fidelityReviewService from 'services/fidelityReview.service';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useParams } from 'react-router';
import evaluatorService from 'services/evaluator.service';
import SimpleEvaluator from 'types/SimpleEvaluator';

const FidelityReviewOrder = () => {
    const { t } = useTranslation();
    const { uid }: { uid: string } = useParams();

    const [section, setSection] = useState(1);
    const [simpleEvaluator, setSimpleEvaluator] = useState<SimpleEvaluator>();
    const [order, setOrder] = useState(new FidelityReview(uid));

    const getEvaluator = async () => {
        try {
            const { data } = await evaluatorService.getByUid(uid);
            setSimpleEvaluator(data);
        } catch (error) {
            setSimpleEvaluator(null);
        }
    };

    useEffect(() => {
        if (uid) {
            getEvaluator();
        }
    }, []);

    const orgInfoNext = (order: FidelityReview) => {
        setOrder(order);
        setSection(2);
    };

    const onTermsNext = (order: FidelityReview) => {
        setOrder(order);
        setSection(3);
    };
    const onTermsPrevious = (order: FidelityReview) => {
        setOrder(order);
        setSection(1);
    };

    const onConfirmSend = async () => {
        setSection(0);
        await fidelityReviewService.add(order);
        setSection(4);
    };

    const onConfirmPrevious = () => {
        setSection(2);
    };

    return (
        <div className="order-evaluation-page">
            <img className="login-logo" src="/assets/logo-192.png" />
            {simpleEvaluator === undefined && <CircularProgress />}
            {simpleEvaluator && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LanguageSwitcher color="#333" />
                    </div>
                    <h1 className="page-title">{`${t('orderFidelityReview.newFidelityReview')}: ${simpleEvaluator?.agencyName}, ${
                        simpleEvaluator?.name
                    }`}</h1>
                    <Paper sx={{ maxWidth: '700px;', margin: '0 auto', padding: '20px' }}>
                        {section === 0 && (
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress sx={{ margin: '10px 0' }} />
                            </div>
                        )}
                        {section === 1 && <OrganizationInformationForm order={order} onNext={orgInfoNext} />}
                        {section === 2 && <TermsForm order={order} onNext={onTermsNext} onPrevious={onTermsPrevious} />}
                        {section === 3 && <ConfirmOrder order={order} onSend={onConfirmSend} onPrevious={onConfirmPrevious}></ConfirmOrder>}
                        {section === 4 && <Alert severity="success">{t('orderFidelityReview.orderSent')}</Alert>}
                    </Paper>
                </>
            )}
            {simpleEvaluator === null && (
                <Container>
                    <Paper sx={{ maxWidth: '700px;', margin: '0 auto', padding: '20px' }}>
                        <Alert severity="error">{t('orderFidelityReview.notValidUrl')}</Alert>
                    </Paper>
                </Container>
            )}
        </div>
    );
};

export default FidelityReviewOrder;

import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import IdParams from 'types/IdParams';
import FidelityReviewEvaluator from './FidelityReviewEvaluator/FidelityReviewEvaluator';

const FidelityReview = () => {
    const { t } = useTranslation();
    const { id } = useParams<IdParams>();
    const idNumeric = +id;

    if (isNaN(idNumeric)) {
        return <ErrorPage code={'702'} />;        
    }

    //{[role.HOST, role.EVALUATOR].includes(user.role) && <FidelityReviewEvaluator id={idNumeric} />}
    return (
        <div>
            <h1>{t('common.fidelityReview')}</h1>
            <FidelityReviewEvaluator id={idNumeric} />
        </div>
    );
};

export default FidelityReview;

import { EvaluatorList } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import evaluatorService from 'services/evaluator.service';

const FidelityReviewOrderSelect: FC = () => {
    const { t } = useTranslation();
    const [evaluators, setEvaluators] = useState([]);

    const getEvaluators = async () => {
        try {
            const { data: evaluatorsRes } = await evaluatorService.getAll();
            const filteredEvaluators = evaluatorsRes.filter((evaluator) => evaluator?.user?.enabled);
            setEvaluators(filteredEvaluators);
        } catch (error) {}
    };

    useEffect(() => {
        getEvaluators();
    }, []);

    return (
        <div className="fidelity-review-order-select-page">
            <h1>{t('orderFidelityReview.newFidelityReviewSelect')}</h1>
            <EvaluatorList evaluators={evaluators} displayOrderButton />
        </div>
    );
};

export default FidelityReviewOrderSelect;

import axios from 'axios';
import Family from 'models/Family';

const basePath = 'family';

const familyService = {
    countForFidelityReview: (id: number) => axios.get<{ count: number }>(`${basePath}/count-for-fidelity-review/${id}`),
    getAll: () => axios.get<Family[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Family>(`${basePath}/${id}`),
    getByFidelityReview: (id: number) => axios.get<Family[]>(`${basePath}/by-fidelity-review/${id}`),
    add: (model: Family) => axios.post<Family>(`${basePath}/`, model),
    update: (model: Family) => axios.put<Family>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getByUid: (uid: string) => axios.get<Family>(`${basePath}/get-by-uid/${uid}`)
};

export default familyService;

import { Container, Paper, Typography } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { FC, ReactNode } from 'react';

import './InfoContainer.scss';

type InfoContainerProps = {
    title?: string;
    fullWidth?: boolean;
    actions?: ReactNode;
    maxWidth?: Breakpoint;
};

const InfoContainer: FC<InfoContainerProps> = ({
    fullWidth = false,
    title,
    children,
    actions,
    maxWidth = 'md',
}) => {
    const fullWidthClass = fullWidth ? 'full-width' : '';

    return (
        <Container maxWidth={maxWidth}>
            <Paper className={`info-container ${fullWidthClass}`}>
                {title && (
                    <div className={'info-container-header'}>
                        <Typography variant={'h6'}>{title}</Typography>
                        <div className='actions'>{actions}</div>
                    </div>
                )}
                {children}
            </Paper>
        </Container>
    );
};

export default InfoContainer;

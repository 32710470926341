import axios from 'axios';
import Activity from 'models/Activity';

const basePath = 'activity';

const activityService = {
    getAll: () => axios.get<Activity[]>(`${basePath}/`),
    add: (model: Activity) => axios.post<Activity>(`${basePath}/`, model),
    update: (model: Activity) => axios.post<Activity>(`${basePath}/`, model),
    deleteByUid: (uid: string) => axios.delete(`${basePath}/deleteByUid/${uid}`),
    updateByUid: (model: Activity) => axios.put<Activity>(`${basePath}/updateByUid/${model.uid}`, model),
    /* getSingle: (id: number) => axios.get<Activity>(`${basePath}/${id}`),
    getByUid: (uid: string) => axios.get<Activity>(`${basePath}/get-by-uid/${uid}`),
    getProgress: (type: number, uid: string) => axios.get<number[]>(`${basePath}/get-progress/${type}/${uid}`), */
};

export default activityService;

import axios from 'axios';
import Answer from 'models/Answer';

const basePath = 'answer';

const answerService = {
    getAll: () => axios.get<Answer[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Answer>(`${basePath}/${id}`),
    add: (model: Answer) => axios.post<Answer>(`${basePath}/`, model),
    update: (model: Answer) => axios.put<Answer>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getByUid: (uid: string) => axios.get<Answer>(`${basePath}/get-by-uid/${uid}`),
    getProgress: (type: number, uid: string) => axios.get<number[]>(`${basePath}/get-progress/${type}/${uid}`),
};

export default answerService;

import { OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import CalendarEntry from 'models/CalendarEntry';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import calendarEntryService from 'services/calendarEntry.service';
import KeysOfType from 'utils/_keysOfType';
//import { useAlert } from 'hooks';

const restrictDecimals = (num: number, decimalPoinst: number): number => {
    const p = Math.pow(10, decimalPoinst);
    const n = Math.round(num * p) / p;
    return n;
};

const restrictMinMaxValue = (num: number, min: number, max: number): number => {
    return num < min ? min : num > max ? max : num;
};

type WeekTableProps = {
    uid: string;
    startOfWeek: Moment;
    calendarEntries: CalendarEntry[];
    setCalendarEntries: (calendarEntry: CalendarEntry[]) => void;
};

const calendarEntryPropKeys: KeysOfType<CalendarEntry, number>[] = [
    'hoursVocationalWorkOut',
    'hoursVocationalWorkIn',
    'hoursNonVocationalWork',
    'hoursGroupWork',
    'hoursOther',
];

const WeekTable: FC<WeekTableProps> = ({ uid, startOfWeek, calendarEntries, setCalendarEntries }) => {
    const { t } = useTranslation();
    //const { addAlert } = useAlert();
    const [currentWeekEntries, setCurrentWeekEntries] = useState<CalendarEntry[]>(null);

    const datesOfWeek: Moment[] = [startOfWeek];
    for (let i = 1; i < 7; i++) {
        const day = startOfWeek.clone();
        day.add(i, 'days');
        datesOfWeek.push(day);
    }
    const updateCurrentWeekEntries = () => {
        const result = datesOfWeek.map((d) => {
            const existingEntry = calendarEntries.find((e) => moment(e.date).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'));
            return existingEntry === undefined
                ? {
                      date: d.format('YYYY-MM-DD'),
                      hoursVocationalWorkOut: null,
                      hoursVocationalWorkIn: null,
                      hoursNonVocationalWork: null,
                      hoursGroupWork: null,
                      hoursOther: null,
                  }
                : Object.assign({}, existingEntry);
        });
        setCurrentWeekEntries(result);
    };

    useEffect(() => {
        updateCurrentWeekEntries();
    }, [startOfWeek]);

    const handleEntryChange = (prop: KeysOfType<CalendarEntry, number>, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEntries = [...currentWeekEntries];
        event.target.value = event.target.value.replace(/^0+/, '');
        const value = +event.target.value;
        updatedEntries[index][prop] = value;
        setCurrentWeekEntries(updatedEntries);
    };

    const handleEntryBlur = (prop: KeysOfType<CalendarEntry, number>, index: number) => (event: React.FocusEvent<HTMLInputElement>) => {
        const currentWeekEntriesCopy = [...currentWeekEntries];

        let value = +event.target.value;
        value = restrictMinMaxValue(value, 0, 24);
        value = restrictDecimals(value, 1);
        value = value === 0 ? null : value;
        currentWeekEntriesCopy[index][prop] = value;
        setCurrentWeekEntries(currentWeekEntriesCopy);

        const existingEntry = calendarEntries.find((ce) => ce.date === currentWeekEntriesCopy[index].date);
        const oldValue = existingEntry === undefined ? null : existingEntry[prop];
        if (oldValue !== value) {
            // Update db entry
            calendarEntryService.updateWithUid({ [prop]: value, date: currentWeekEntriesCopy[index].date }, uid).then(({ data }) => {
                const entryIndex = calendarEntries.findIndex((ce) => ce.id === data.id);
                const calendarEntriesCopy = [...calendarEntries];

                if (entryIndex === -1) {
                    calendarEntriesCopy.push(data);
                } else {
                    calendarEntriesCopy[entryIndex] = data;
                }
                setCalendarEntries(calendarEntriesCopy);
            });
        }
    };

    const sumHours = (entries: CalendarEntry): string => {
        let sum = 0;
        calendarEntryPropKeys.map((k) => {
            if (entries[k] && !isNaN(entries[k])) {
                sum += entries[k];
            }
        });
        return `${sum} ${t('common.hours')}`;
    };

    return (
        <div>
            <TableContainer sx={{ maxWidth: '1200px', margin: '20px auto' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('common.description')}</TableCell>
                            {datesOfWeek.map((d) => (
                                <TableCell key={d.toISOString()} sx={{ width: '100px', borderLeft: 'solid 1px rgba(255,255,255,0.3)' }}>
                                    {d.format('ddd D')}.
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentWeekEntries !== null &&
                            calendarEntryPropKeys.map((k) => (
                                <TableRow key={k}>
                                    <TableCell>{t('calendarEntry.' + k)}</TableCell>
                                    {currentWeekEntries.map((weekEntries, index) => (
                                        <TableCell key={weekEntries.date.toString()}>
                                            <OutlinedInput
                                                type="number"
                                                value={weekEntries[k] === null || weekEntries[k] === undefined ? '' : weekEntries[k]}
                                                onChange={handleEntryChange(k, index)}
                                                onBlur={handleEntryBlur(k, index)}
                                                sx={{ fontSize: '14px' }}
                                                size="small"
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                    {
                        <TableFooter>
                            <TableRow>
                                <TableCell>{t('common.sum')}</TableCell>
                                {currentWeekEntries !== null &&
                                    currentWeekEntries.map((weekEntries) => (
                                        <TableCell key={weekEntries.date.toString()} sx={{ textAlign: 'center', textTransform: 'lowercase' }}>
                                            {sumHours(weekEntries)}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </TableContainer>
        </div>
    );
};

export default WeekTable;

import Paper from '@mui/material/Paper';
import Evaluator from 'models/Evaluator';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import { InviteEvaluatorModal, EvaluatorList } from 'components';
import { useEffect, useState } from 'react';
import evaluatorService from 'services/evaluator.service';
import './Evaluators.scss';

const Evaluators = () => {
    const [inviteModal, setinviteModal] = useState(false);
    const [evaluators, setEvaluators] = useState<Evaluator[]>(null);

    const { t } = useTranslation();

    const getEvaluators = () => {
        evaluatorService.getAll().then(({ data: evaluators = [] }) => {
            setEvaluators(evaluators);
        });
    };

    useEffect(() => {
        getEvaluators();
    }, []);

    return (
        <div className="evaluators-page">
            <h1>{t('common.evaluators')}</h1>
            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                {evaluators === null ? (
                    <CircularProgress sx={{ margin: '10px 0' }} />
                ) : (
                    <EvaluatorList evaluators={evaluators} displayStatus />
                )}
            </Paper>
            <Button
                variant="contained"
                size="large"
                onClick={() => {
                    setinviteModal(true);
                }}
                sx={{ marginTop: '20px' }}
            >
                {t('evaluators.inviteNew')}
            </Button>
            {inviteModal && (
                <InviteEvaluatorModal
                    onCancel={() => {
                        setinviteModal(false);
                    }}
                    onConfirm={() => {
                        getEvaluators();
                        setinviteModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default Evaluators;

import { Button, TextField } from '@mui/material';
import axios from 'axios';
import routes from 'consts/routes';
import { useAuth, useAlert } from 'hooks';
import { t } from 'i18next';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import queryString from 'utils/_queryString';

import './Login.scss';

type FormValues = {
    email: string;
    password: string;
};

const Login: FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const { addAlert } = useAlert();
    const { signInUser } = useAuth();

    const onSubmit: SubmitHandler<FormValues> = async (formData) => {
        try {
            const validLogin = await signInUser(formData.email, formData.password);
            if (!validLogin) {
                addAlert('error', 'Incorrect email or password');
            }
        } catch (err) {
            const message = axios.isAxiosError(err) ? err.message : 'Client error';
            addAlert('error', 'Something went wrong – ' + message);
        }
    };

    useEffect(() => {
        if (user) {
            const returnPath = queryString.get('returnPath');
            const redirect = returnPath || routes.START_PAGE.path;
            history.push({
                pathname: redirect,
            });
        }
    }, [user]);

    if (!user) {
        return (
            <div className="login-page">
                <img className="login-logo" src="/assets/logo-512.png" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form-container">
                        <TextField label={'Email'} {...register('email')} />
                        <TextField label={'Password'} type={'password'} {...register('password')} />
                        <div className="login-form-actions">
                            <Button type="submit" variant="contained" size="large">
                                Logg inn
                            </Button>
                            <Button variant="outlined" size="large" onClick={() => history.push('/forgot-password')}>
                                {t('common.forgotPassword')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    } else {
        return null;
    }
};

export default Login;

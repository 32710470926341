import { Button, Paper, TextField } from '@mui/material';
import questionType from 'consts/questionType';
import { t } from 'i18next';
import Answer from 'models/Answer';
import Suggestion from 'models/Suggestion';
import React, { FC, useState } from 'react';
import SanitizedQuestion from 'types/SanitizedQuestion';

type QuestionProps = {
    question: SanitizedQuestion;
    submitAnswer: (answer: Answer) => void;
};

const Question: FC<QuestionProps> = ({ question, submitAnswer }) => {
    const [intInput, setIntInput] = useState<number>(null);
    console.log('question', question);
    const submitIntInput = (questionId: number) => {
        // Post answer
        const answer: Answer = {
            questionId: questionId,
            value: intInput,
            sourceUid: null,
            type: null,
        };
        submitAnswer(answer);
        setIntInput(null);
    };

    const selectSuggestion = (suggestion: Suggestion) => {
        // Post answer
        const answer: Answer = {
            suggestionId: suggestion.id,
            questionId: suggestion.questionId,
            sourceUid: null,
            type: null,
            value: null,
        };

        submitAnswer(answer);
    };

    return (
        <div>
            <Paper sx={{ padding: '10px 20px' }}>
                <h4>{t('common.question')}</h4>
                <p>{question.text}</p>
            </Paper>

            {question.type === questionType.INPUT_INT && (
                <>
                    <TextField
                        label={t('common.answer')}
                        value={intInput === null ? '' : intInput}
                        onChange={(e) => {
                            setIntInput(+e.target.value);
                        }}
                        type="number"
                        onKeyDown={(event) => {
                            console.log('event.key: ', event.key);
                            if (event.key === 'Enter') {
                                submitIntInput(question.id);
                            }
                        }}
                        sx={{ margin: '20px 0', backgroundColor: '#fff', '& input': { textAlign: 'center' } }}
                    ></TextField>
                    <br />
                    <Button
                        variant="contained"
                        onClick={() => {
                            submitIntInput(question.id);
                        }}
                    >
                        {t('common.next')}
                    </Button>
                </>
            )}
            {question.type === questionType.MULTIPLE_CHOICE &&
                question.suggestions.map((s) => (
                    <Paper
                        key={s.id}
                        onClick={() => {
                            selectSuggestion(s);
                        }}
                        sx={{
                            width: '90%',
                            margin: '20px auto',
                            padding: '20px',
                            borderColor: '#000',
                            '&:hover': {
                                borderColor: 'primary.light',
                            },
                            borderBottomWidth: '3px',
                            borderBottomStyle: 'solid',
                            cursor: 'pointer',
                        }}
                    >
                        {s.text}
                    </Paper>
                ))}
        </div>
    );
};

export default Question;

import { FC } from 'react';
import { useParams } from 'react-router';
import { AlertTitle, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
type ErrorParameters = {
    code?: string;
};

const ErrorPage: FC<ErrorParameters> = (props) => {
    const { code } = props.code ? props : useParams<ErrorParameters>();
    const { t } = useTranslation();

    let title = 'common.error';
    let message = 'error.somethingWentWrong';
    const severity = 'error';
    switch (code) {
        case '401':
            title = 'error.authorizationError';
            message = 'error.userNotAuthorized';
            break;
        case '404':
            title = 'error.notFound';
            message = 'error.notFoundMsg';
            break;
        case '701':
            title = 'error.invalidOrExpiredLink';
            message = 'error.invalidOrExpiredLinkMsg';
            break;
        case '702':
            title = 'error.invalidUid';
            message = 'error.invalidUidMsg';
            break;
        default:
            break;
    }
    return (
        <div className="order-evaluation-page">
            <div style={{ textAlign: 'center' }}>
                <ErrorOutlineIcon color="error" sx={{ fontSize: '80px' }} />
                <h1> {t('error.woops')} </h1>
            </div>
            <Paper sx={{ maxWidth: '400px;', margin: '0 auto' }}>
                <Alert severity={severity}>
                    <AlertTitle>
                        <strong>{t(title)}</strong>
                    </AlertTitle>
                    {t(message)}
                </Alert>
            </Paper>
        </div>
    );
};

export default ErrorPage;

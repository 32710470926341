import { FC, useEffect, useState } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { Alerts } from 'components';
import axios from 'axios';
import { useAuth } from 'hooks';
import { config } from 'utils';

import DateAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import routes from 'consts/routes';
import RouteHandler from 'components/RouteHandler/RouteHandler';
import ErrorPage from 'pages/ErrorPage/ErrorPage';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const App: FC = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const { getUser } = useAuth();
    useEffect(() => {
        const authToken = JSON.parse(localStorage.getItem('dfrt-token'));

        const handleUserAuthentication = async () => {
            if (authToken) {
                axios.defaults.headers.common['Authorization'] = authToken;
                await getUser();
            }
            setInitialLoading(false);
        };

        handleUserAuthentication();
    }, []);
    if (initialLoading) {
        return <SplashScreen />;
    } else {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Alerts />
                    <Router>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
                            <Switch>
                                {Object.values(routes).map((routeItem) => {
                                    return <RouteHandler key={routeItem.path} path={routeItem.path} routeItem={routeItem} />;
                                })}
                                <Route exact path="/">
                                    <Redirect to={routes.START_PAGE.path} />
                                </Route>
                                <Route path="*">
                                    <ErrorPage code={'404'} />
                                </Route>
                            </Switch>
                        </Box>
                    </Router>
                </Box>
            </LocalizationProvider>
        );
    }
};

export default App;

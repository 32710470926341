import { FC } from 'react';
import { Divider } from '@mui/material';
import { uniqueId } from 'lodash';
import Import from 'types/Import';
import ImportViewType from 'types/ImportView';

type ImportViewProps = {
    importView: Array<ImportViewType[]>;
    importModel: Import[];
};

const ImportView: FC<ImportViewProps> = ({ importView, importModel }) => {
    return (
        <div className={'import-view'}>
            <div className="import-data">
                <div className="import-table-container">
                    {importView.length > 0 && (
                        <>
                            <table className="import-data-table">
                                <thead>
                                    <tr>
                                        {importModel.map((item: Import, index: number) => {
                                            return <th key={index}>{item.label}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {importView.map((row: ImportViewType[], index: number) => {
                                        const rowDataArr = Object.values(row);

                                        const rowDataItemsElements = rowDataArr.map((rowImportView: ImportViewType) => {
                                            const id = uniqueId();
                                            return (
                                                <td className={`${!rowImportView.valid ? 'not-valid' : ''}`} key={id}>
                                                    {rowImportView.value}
                                                </td>
                                            );
                                        });

                                        return <tr key={index}>{rowDataItemsElements}</tr>;
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                    {importView.length == 0 && (
                        <>
                            <Divider />
                            <div className="no-data-imported-container">
                                <i>No data imported</i>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImportView;

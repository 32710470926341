import Import from 'types/Import';
import { validate } from 'utils';
import i18n from 'translation';

export const SupervisorModel = [
    {
        key: 'name',
        label: `${i18n.t('common.name')}*`,
        required: true,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'phone',
        label: `${i18n.t('common.phone')}*`,
        required: true,
        validate: validate.phone,
    },
    {
        key: 'email',
        label: `${i18n.t('common.email')}*`,
        required: true,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'vacancyRate',
        label: `${i18n.t('common.vacancyRate')}*`,
        required: true,
        validate: (val: number) => validate.number(val, 0, 100),
    },
    {
        key: 'startDate',
        label: `${i18n.t('common.startDate')}*`,
        required: true,
        validate: validate.dateString,
    },
] as Import[];

export default SupervisorModel;

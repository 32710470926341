import { CssBaseline, LinearProgress } from '@mui/material';
import './SplashScreen.scss';

const SplashScreen = () => (
    <div className="SplashScreen">
        <CssBaseline />
        <div className="SplashContainer">
            <img src="/assets/logo-512.png" />
            <LinearProgress color="primary" sx={{ width: '70%', margin: '0 auto', borderRadius: '2px' }} />
        </div>
    </div>
);

export default SplashScreen;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Alert from 'types/Alert';
import { uniqeId } from 'utils';

const defaultAlert: Alert = {
    id: uniqeId(),
    type: 'success',
    text: '',
    timeout: 5000,
};

const initialState = [] as Alert[];

export const alerts = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        addAlert: (state, action: PayloadAction<Alert>) => {
            const alert = { ...defaultAlert, ...action.payload };

            if (!alert.id) {
                alert.id = uniqeId();
            }
            
            state.push(alert);
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            return state.filter((alert) => alert.id !== action.payload);
        },
    },
});

export const { actions: alertActions } = alerts;

export default alerts.reducer;

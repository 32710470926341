import ReactDOM from 'react-dom';

import App from './App';

import './translation';
import { Provider } from 'react-redux';

import store from './store/index';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

import './Profile.scss';
import { FC, useState } from 'react';

import { IconButton, Typography } from '@mui/material';
import { Edit, Person } from '@mui/icons-material';
import { InfoContainer, PrimaryListItem } from 'components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
    ChangePasswordModal,
    ConsentModal,
    EditSingleStringModal,
} from 'components';
import useEditUser from './useEditUser';
import { useTranslation } from 'react-i18next';

const modals = {
    changeNameModal: 0,
    changePasswordModal: 1,
    changeEmailModal: 2,
};

const Profile: FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const { t } = useTranslation();
    
    const [openModal, setOpenModal] = useState(-1);
    const { changeName, changeEmailRequest, changePassword } =
        useEditUser();

    const closeModal = () => {
        setOpenModal(-1);
    };

    return (
        <>
            <div className='profile'>
                <InfoContainer maxWidth={'sm'} fullWidth={true}>
                    <div className={'user-info-container'}>
                        <div className={'header'}>
                            <div className='header-content'>
                                <Person />
                                <Typography variant={'h6'}>
                                    {user.name}
                                </Typography>
                            </div>
                        </div>
                        <div className={'user-info'}>
                            <PrimaryListItem
                                label={'Email'}
                                value={user.email}
                                actions={
                                    <IconButton
                                        onClick={() =>
                                            setOpenModal(
                                                modals.changeEmailModal
                                            )
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                }
                            />
                            <PrimaryListItem
                                label={'Name'}
                                value={user.name}
                                actions={
                                    <IconButton
                                        onClick={() =>
                                            setOpenModal(modals.changeNameModal)
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                }
                            />
                            <PrimaryListItem
                                label={'Password'}
                                value={'********'}
                                actions={
                                    <IconButton
                                        onClick={() =>
                                            setOpenModal(
                                                modals.changePasswordModal
                                            )
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                }
                            />
                            <PrimaryListItem
                                label={'Role'}
                                value={t('role.' + user.role)}
                            />
                        </div>
                    </div>
                </InfoContainer>
            </div>
            {openModal === modals.changeNameModal && (
                <EditSingleStringModal
                    value={user.name}
                    label={'Name'}
                    min={2}
                    max={50}
                    onCancel={closeModal}
                    onConfirm={(newName) => {
                        closeModal();
                        changeName(newName);
                    }}
                />
            )}
            {openModal === modals.changeEmailModal && (
                <ConsentModal
                    title={'Endre epost'}
                    content={`En epost med link for å endre epost vil bli sendt til ${user.email}. Trykk bekreft for å fortsette`}
                    onCancel={closeModal}
                    onConfirm={() => {
                        closeModal();
                        changeEmailRequest();
                    }}
                />
            )}
            {openModal === modals.changePasswordModal && (
                <ChangePasswordModal
                    onCancel={closeModal}
                    onConfirm={(newPassword, oldPassword) => {
                        closeModal();
                        changePassword(newPassword, oldPassword);
                    }}
                />
            )}
        </>
    );
};

export default Profile;

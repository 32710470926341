import axios from 'axios';
import Evaluator from 'models/Evaluator';
import SimpleEvaluator from 'types/SimpleEvaluator';

const basePath = 'evaluator';

const evaluatorService = {
    getAll: () => {
        return axios.get<Evaluator[]>(`${basePath}/`);
    },
    getSingle: (id: number) => {
        return axios.get<Evaluator>(`${basePath}/${id}`);
    },
    add: (model: Evaluator) => {
        return axios.post<Evaluator>(`${basePath}/`, model);
    },
    update: (model: Evaluator) => {
        return axios.put<Evaluator>(`${basePath}/${model.id}`, model);
    },
    delete: (id: number) => {
        return axios.delete(`${basePath}/${id}`);
    },
    getByUid: (uid: string) => axios.get<SimpleEvaluator>(`${basePath}/get-by-uid/${uid}`),
};

export default evaluatorService;

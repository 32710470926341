import { Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FidelityReview from 'models/FidelityReview';

type TermsFormProps = {
    onNext: (order: FidelityReview) => void;
    onPrevious: (order: FidelityReview) => void;
    order: FidelityReview;
};

type FormValues = {
    clientTerm: string;
    employmentSpecialistTerm: string;
    supervisorTerm: string;
};

const TermsForm: FC<TermsFormProps> = ({ onNext, onPrevious, order }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        getValues,
    } = useForm({
        defaultValues: {
            clientTerm: order.clientTerm || 'term.client',
            employmentSpecialistTerm: order.employmentSpecialistTerm || 'term.employmentSpecialist',
            supervisorTerm: order.supervisorTerm || 'term.supervisor',
        },
    });

    const onSubmit: SubmitHandler<FormValues> = (formData) => {
        order.clientTerm = t(formData.clientTerm);
        order.clientTermPlural = t(formData.clientTerm + 'Plural');
        order.employmentSpecialistTerm = t(formData.employmentSpecialistTerm);
        order.employmentSpecialistTermPlural = t(formData.employmentSpecialistTerm + 'Plural');
        order.supervisorTerm = t(formData.supervisorTerm);
        order.supervisorTermPlural = t(formData.supervisorTerm + 'Plural');
        onNext(order);
    };

    const onPreviousClick = () => {
        order.clientTerm = getValues('clientTerm');
        order.employmentSpecialistTerm = getValues('employmentSpecialistTerm');
        order.supervisorTerm = getValues('supervisorTerm');
        onPrevious(order);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>{t('common.terminology')}</h2>
            <Stack rowGap="30px">
                <FormControl fullWidth>
                    <InputLabel>{t('orderFidelityReview.clientTerm')}</InputLabel>
                    <Select label={t('orderFidelityReview.clientTerm')} defaultValue={getValues('clientTerm')} {...register('clientTerm')}>
                        <MenuItem value={'term.client'}>{t('term.client')}</MenuItem>
                        <MenuItem value={'term.pasient'}>{t('term.pasient')}</MenuItem>
                        <MenuItem value={'term.applicant'}>{t('term.applicant')}</MenuItem>
                        <MenuItem value={'term.participant'}>{t('term.participant')}</MenuItem>
                    </Select>
                    <FormHelperText>{t('orderFidelityReview.clientTermDescription')}</FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>{t('orderFidelityReview.employmentSpecialistTerm')}</InputLabel>
                    <Select
                        label={t('orderFidelityReview.employmentSpecialistTerm')}
                        defaultValue={getValues('employmentSpecialistTerm')}
                        {...register('employmentSpecialistTerm')}
                    >
                        <MenuItem value={'term.employee'}>{t('term.employee')}</MenuItem>
                        <MenuItem value={'term.employmentSpecialist'}>{t('term.employmentSpecialist')}</MenuItem>
                        <MenuItem value={'term.consultant'}>{t('term.consultant')}</MenuItem>
                    </Select>
                    <FormHelperText>{t('orderFidelityReview.employmentSpecialistTermDescription')}</FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>{t('orderFidelityReview.supervisorTerm')}</InputLabel>
                    <Select
                        label={t('orderFidelityReview.supervisorTerm')}
                        defaultValue={getValues('supervisorTerm')}
                        {...register('supervisorTerm')}
                    >
                        <MenuItem value={'term.supervisor'}>{t('term.supervisor')}</MenuItem>
                        <MenuItem value={'term.manager'}>{t('term.manager')}</MenuItem>
                        <MenuItem value={'term.teamLeader'}>{t('term.teamLeader')}</MenuItem>
                    </Select>
                    <FormHelperText>{t('orderFidelityReview.supervisorTermDescription')}</FormHelperText>
                </FormControl>
            </Stack>
            <Divider sx={{ marginTop: '20px', marginBottom: '10px' }} />
            <div>
                <Button onClick={onPreviousClick} variant="outlined">
                    {t('common.previous')}
                </Button>
                <Button type="submit" variant="contained" sx={{ float: 'right' }}>
                    {t('common.next')}
                </Button>
            </div>
        </form>
    );
};

export default TermsForm;

import axios from 'axios';
import Partner from 'models/Partner';

const basePath = 'partner';

const partnerService = {
    countForFidelityReview: (id: number) => axios.get<{ count: number }>(`${basePath}/count-for-fidelity-review/${id}`),
    getAll: () => axios.get<Partner[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Partner>(`${basePath}/${id}`),
    supervisorGetByFidelityReview: (id: number) => axios.get<Partner[]>(`${basePath}/supervisor/by-fidelity-review/${id}`),
    getByFidelityReview: (id: number) => axios.get<Partner[]>(`${basePath}/by-fidelity-review/${id}`),
    add: (model: Partner) => axios.post<Partner>(`${basePath}/`, model),
    supervisorAdd: (model: Partner) => axios.post<Partner>(`${basePath}/supervisor/`, model),
    supervisorUpdate: (model: Partner) => axios.put<Partner>(`${basePath}/supervisor/${model.id}`, model),
    supervisorDelete: (id: number) => axios.delete(`${basePath}/supervisor/${id}`),
    update: (model: Partner) => axios.put<Partner>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
};

export default partnerService;

import { FC } from 'react';
import './ConsentModal.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConsentModalProps = {
    title?: string;
    content?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
};

const ConsentModal: FC<ConsentModalProps> = ({ title, content, onConfirm, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={true}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {content && (
                <DialogContent>
                    <p>{content}</p>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={onConfirm}>
                    {t('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConsentModal;

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, CircularProgress, Paper, Tab } from '@mui/material';
import FidelityReviewShortInfo from 'components/FidelityReviewShortInfo/FidelityReviewShortInfo';
import fidelityReviewStatus from 'consts/fidelityReviewStatus';
import routes from 'consts/routes';
import FidelityReview from 'models/FidelityReview';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import fidelityReviewService from 'services/fidelityReview.service';
import IdParams from 'types/IdParams';
import RegisterClients from './RegisterClient/RegisterClient';
import RegisterEmployees from './RegisterEmployees/RegisterEmployees';
import RegisterFamily from './RegisterFamily/RegisterFamily';
import RegisterPartners from './RegisterPartners/RegisterPartners';

const FidelityReviewSourceList: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<IdParams>();
    const history = useHistory();

    const idNumeric = +id;
    if (isNaN(idNumeric)) {
        return <h1>Invalid Id.</h1>;
    }
    const [review, setReview] = useState<FidelityReview>(null);
    const [tabValue, setTabValue] = useState('partners');

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const getReview = async () => {
        const result = (await fidelityReviewService.getSingle(idNumeric)).data;
        setReview(result);
        return result.id;
    };

    const registrationComplete = async () => {
        await fidelityReviewService.registrationComplete(review.id);
        history.push(routes.START_PAGE.path);
    };

    // Init
    // -------------------------------------------
    useEffect(() => {
        getReview();
    }, []);

    return (
        <div>
            <h2>{t('common.fidelityReview')}</h2>
            {review === null ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
            ) : (
                <FidelityReviewShortInfo review={review}>
                    {review.status === fidelityReviewStatus.AWAITING_REGISTRATION && (
                        <Button variant="contained" onClick={registrationComplete}>
                            {t('sources.registrationComplete')}
                        </Button>
                    )}
                </FidelityReviewShortInfo>
            )}

            <h2>{t('common.sources')}</h2>
            <Paper>
                {review === null ? (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress sx={{ margin: '10px 0' }} />
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label={t('common.partner') + ' ' + t('common.list')} value="partners" />
                                    <Tab label={review.employmentSpecialistTerm + ' ' + t('common.list')} value="employees" />
                                    <Tab label={review.clientTerm + ' ' + t('common.list')} value="clients" />
                                    <Tab label={t('common.family') + ' ' + t('common.list')} value="family" />
                                </TabList>
                            </Box>
                            <TabPanel value="partners" sx={{ padding: 0 }}>
                                <RegisterPartners fidelityReview={review} />
                            </TabPanel>
                            <TabPanel value="employees" sx={{ padding: 0 }}>
                                <RegisterEmployees fidelityReview={review} />
                            </TabPanel>
                            <TabPanel value="clients" sx={{ padding: 0 }}>
                                <RegisterClients fidelityReview={review} />
                            </TabPanel>
                            <TabPanel value="family" sx={{ padding: 0 }}>
                                <RegisterFamily fidelityReview={review} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                )}
            </Paper>
        </div>
    );
};

export default FidelityReviewSourceList;

import { ChangeEvent, FC, useState } from 'react';
import './ChangePasswordModal.scss';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';

type ChangePasswordModalProps = {
    onCancel: () => void;
    onConfirm: (newPassword: string, oldPassword: string) => void;
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();

    const [fields, setFields] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    });

    const confirmChangesHandler = () => {
        const isValid = validate();

        if (isValid) {
            onConfirm(fields.newPassword, fields.oldPassword);
        }
    };

    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    };

    const validate = () => {
        if (
            fields.newPassword.length < 5 ||
            fields.newPasswordRepeat.length < 5
        ) {
            alert(t('error.passwordLength'));
            return false;
        }

        if (fields.newPassword != fields.newPasswordRepeat) {
            alert(t('error.passwordsNotEqual'));
            return false;
        }

        return true;
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                {capitalizeFirstLetter(t('profile.changePassword'))}
            </DialogTitle>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            label={capitalizeFirstLetter(
                                t('profile.oldPassword')
                            )}
                            name={'oldPassword'}
                            value={fields.oldPassword}
                            onChange={inputChangeHandler}
                            fullWidth
                        />
                    </div>
                    <div>
                        <TextField
                            label={capitalizeFirstLetter(
                                t('profile.newPassword')
                            )}
                            name={'newPassword'}
                            value={fields.newPassword}
                            onChange={inputChangeHandler}
                            fullWidth
                        />
                    </div>
                    <div>
                        <TextField
                            label={`${capitalizeFirstLetter(t('confirm'))} ${t(
                                'profile.newPassword'
                            )}`}
                            name={'newPasswordRepeat'}
                            value={fields.newPasswordRepeat}
                            onChange={inputChangeHandler}
                            fullWidth
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{t('cancel')}</Button>
                <Button onClick={confirmChangesHandler}>
                    {t('confirmChanges')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswordModal;

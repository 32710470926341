import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type LanguageSwitcherProps = {
    color?: string;
};

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ color }) => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.language);
    const textColor = color || '#fff';

    const languages = [
        {
            key: 'no',
            name: t('language.norwegian'),
        },
        {
            key: 'en',
            name: t('language.english'),
        },
    ];
    return (
        <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel sx={{ color: textColor }} id="demo-simple-select-standard-label">
                    {t('common.language')}
                </InputLabel>
                <Select
                    label={t('common.language')}
                    value={language}
                    onChange={(e) => {
                        setLanguage(e.target.value);
                        i18n.changeLanguage(e.target.value);
                    }}
                    sx={{
                        color: textColor,
                        borderColor: textColor,
                        '&::before, &:hover::before': {
                            display: 'none',
                        },
                        '& svg': {
                            color: textColor,
                        },
                    }}
                >
                    {languages.map((l) => (
                        <MenuItem key={l.key} value={l.key}>
                            {l.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSwitcher;

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import fidelityReviewStatus from 'consts/fidelityReviewStatus';
import routes from 'consts/routes';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import fidelityReviewService from 'services/fidelityReview.service';

const MyEvaluatorReviews: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [myActiveReviews, setMyActiveReviews] = useState([]);

    const getReviews = async () => {
        // Get Supervisor Reviews
        const reviews = (await fidelityReviewService.evaluatorGetAll()).data;
        setMyActiveReviews(reviews);
    };
    useEffect(() => {
        getReviews();
    }, []);
    return (
        <div>
            <h2>My reviews as supervisor</h2>
            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>{t('common.status')}</TableCell>
                                <TableCell>{t('common.organizationName')}</TableCell>
                                <TableCell>{t('common.organizationNo')}</TableCell>
                                <TableCell>{t('common.reviewDate')}</TableCell>
                                <TableCell>{t('common.orderDate')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myActiveReviews.map((review) => (
                                <TableRow key={review.id}>
                                    <TableCell>
                                        {review.status === fidelityReviewStatus.AWAITING_REGISTRATION && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    history.push(routes.SOURCES.path.replace(':id', review.id.toString()));
                                                }}
                                            >
                                                {t('common.registerSources')}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>{t('fidelityReviewStatus.' + review.status)}</TableCell>
                                    <TableCell>{review.organizationName}</TableCell>
                                    <TableCell>{review.organizationNo}</TableCell>
                                    <TableCell>{moment(review.reviewDate).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell>{moment(review.orderDate).format('DD.MM.YYYY')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {myActiveReviews.length === 0 && (
                        <p style={{ textAlign: 'center' }}>
                            <i>{t('common.theListIsEmpty')}</i>
                        </p>
                    )}
                </TableContainer>
            </Paper>
        </div>
    );
};

export default MyEvaluatorReviews;

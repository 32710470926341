import { Button, Divider } from '@mui/material';
import FamilyList from './FamilyList/FamilyList';
import FidelityReview from 'models/FidelityReview';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditFamilyModal from './EditFamilyModal/EditFamilyModal';
import familyService from 'services/family.service';
import Family from 'models/Family';
import clientService from 'services/client.service';
import Client from 'models/Client';
import { Download } from '@mui/icons-material';
import { ImportModal } from 'components';
import FamilyXlsxTemplate from 'models/XlsxTemplate/FamilyXlsxTemplate';
import FamilyModel from 'models/XlsxImport/FamilyModel';
import { AxiosResponse } from 'axios';
import XlsxTemplate from 'types/XlsxTemplate';

type RegisterFamilyProps = {
    fidelityReview: FidelityReview;
};

type FamilyField = {
    client: string;
    name: string;
    phone: string;
    email: string;
};

const RegisterFamily: FC<RegisterFamilyProps> = ({ fidelityReview }) => {
    const { t } = useTranslation();

    const term: string = t('common.family');
    const [modal, setModal] = useState(0);
    const [xlsxTemplate, setXlsxTemplate] = useState<XlsxTemplate>();

    // Family
    // -------------------------------------------
    const [family, setFamily] = useState<Family[]>(null);
    const [clients, setClients] = useState<Client[]>([]);
    const [familyToEdit, setFamilyToEdit] = useState(null);

    const getFamily = async (id: number) => {
        if (id === null) {
            return;
        }
        // Get family members
        const [familyQuery, clientsQuery] = await Promise.all([
            familyService.getByFidelityReview(id),
            clientService.getByFidelityReview(id),
        ]);
        const clients = clientsQuery.data;
        const family = familyQuery.data.map((c) => {
            c.client = clients.find((e) => e.id === c.clientId);
            return c;
        });

        clients.forEach((client) => {
            FamilyXlsxTemplate.sheets[1].rows.push([client.name]);
        });
        setXlsxTemplate(FamilyXlsxTemplate);
        setClients(clients);
        setFamily(family);
    };

    const addFamily = async (family: Family) => {
        setFamily(null);
        family.fidelityReviewId = fidelityReview.id;
        await familyService.add(family);
        setModal(0);
        getFamily(fidelityReview.id);
    };

    const updateFamily = async (family: Family) => {
        setFamily(null);
        await familyService.update(family);
        setFamilyToEdit(null);
        setModal(0);
        getFamily(fidelityReview.id);
    };

    const deleteFamily = async (familyId: number) => {
        setFamily(null);
        await familyService.delete(familyId);
        setModal(0);
        getFamily(fidelityReview.id);
    };

    const handleImport = async (familyFields: FamilyField[]) => {
        setModal(null);
        const pmArr = [] as Promise<AxiosResponse<Family>>[];

        // Validate that employee exists
        const newFamilyMembers = [] as Family[];

        familyFields.forEach((familyField: FamilyField) => {
            const client = clients.find((client) => familyField.client === client.name);
            newFamilyMembers.push({
                name: familyField.name,
                phone: familyField.phone,
                email: familyField.email,
                clientId: client?.id ?? null,
                fidelityReviewId: fidelityReview.id,
            } as Family);
        });

        newFamilyMembers.forEach((newFamilyMember) => {
            pmArr.push(familyService.add(newFamilyMember));
        });

        await Promise.all(pmArr)
            .then((res) => {
                res.forEach((element) => {
                    console.log('element', element);
                });
            })
            .catch()
            .finally(() => {
                getFamily(fidelityReview.id);
            });
    };

    useEffect(() => {
        getFamily(fidelityReview.id);
    }, []);

    return (
        <div>
            <FamilyList
                family={family}
                clientTerm={fidelityReview.clientTerm}
                editFamily={(family) => {
                    setFamilyToEdit(family);
                    setModal(1);
                }}
                deleteFamily={deleteFamily}
            />
            <Divider />
            <Button variant="contained" sx={{ margin: '20px 10px' }} onClick={() => setModal(1)}>
                {t('common.add')} {term}
            </Button>
            <Button variant="outlined" sx={{ margin: '20px 0' }} onClick={() => setModal(2)} endIcon={<Download />}>
                {t('common.import')}
            </Button>
            {modal === 1 && (
                <EditFamilyModal
                    family={familyToEdit}
                    clients={clients}
                    onConfirm={(family) => (familyToEdit ? updateFamily(family) : addFamily(family))}
                    onCancel={() => {
                        setFamilyToEdit(null);
                        setModal(0);
                    }}
                    clientTerm={fidelityReview.clientTerm}
                />
            )}
            {modal === 2 && (
                <ImportModal
                    onSave={handleImport}
                    onCancel={() => setModal(1)}
                    title={'Import family'}
                    xlsxTemplate={xlsxTemplate}
                    importModel={FamilyModel}
                />
            )}
        </div>
    );
};

export default RegisterFamily;

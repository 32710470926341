import axios from 'axios';
import User from 'models/User';

const authService = {
    signIn: (email: string, password: string) => {
        return axios.post('auth/login', {
            email: email,
            password: password,
        });
    },
    getUserInfo: () => {
        return axios.get('auth/user-info');
    },
    getUserRoles: () => {
        return axios.get('auth/user-role');
    },
    updateUser: (user: User) => {
        return axios.put('auth/user-info', { user: user });
    },
    changeEmailRequest: () => {
        return axios.post('auth/change-email-request');
    },
    changeEmail: (newEmail: string, changeEmailToken: string) => {
        return axios.post('auth/change-email', {
            email: newEmail,
            changeEmailToken: changeEmailToken,
        });
    },
    changePassword: (newPassword: string, oldPassword: string) => {
        return axios.post('auth/change-password', {
            newPassword: newPassword,
            oldPassword: oldPassword,
        });
    },
    resetPassword: (password: string, token: string) => {
        return axios.post('auth/reset-password', {
            password: password,
            token: token,
        });
    },
    forgotPassword: (email: string) => {
        return axios.post('auth/forgot-password', {
            email: email,
        });
    },
};

export default authService;

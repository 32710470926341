import axios from 'axios';
import CalendarEntry from 'models/CalendarEntry';

const basePath = 'calendarentry';

const calendarentryService = {
    getAll: () => axios.get<CalendarEntry[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<CalendarEntry>(`${basePath}/${id}`),
    add: (model: CalendarEntry) => axios.post<CalendarEntry>(`${basePath}/`, model),
    update: (model: CalendarEntry) => axios.put<CalendarEntry>(`${basePath}/${model.id}`, model),
    updateWithUid: (model: CalendarEntry, uid: string) => axios.put<CalendarEntry>(`${basePath}/update-entry-with-uid/${uid}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getByEmployeeUid: (uid: string) => axios.get<CalendarEntry[]>(`${basePath}/get-by-employee-uid/${uid}`),
};

export default calendarentryService;

import { Alert, AlertTitle, Button, CircularProgress, Paper } from '@mui/material';
import axios, { AxiosError } from 'axios';
import PublicPageContainer from 'components/PublicPageContainer/PublicPageContainer';
import routes from 'consts/routes';
import { useAlert } from 'hooks';
import Answer from 'models/Answer';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import answerService from 'services/answer.service';
import fidelityReviewService from 'services/fidelityReview.service';
import questionService from 'services/question.service';
import SanitizedQuestion from 'types/SanitizedQuestion';
import Terms from 'types/Terms';
import formatString from 'utils/_formatString';
import Question from './Question';

type TakeSurveyParams = {
    id: string;
    uid: string;
};

const view = {
    LOADING: 0,
    START: 1,
    ANSWER_QUESTIONS: 2,
    END: 3,
    ALREADY_COMPLETED: 4,
    ERROR: 5,
};

const TakeSurvey = () => {
    const { id, uid } = useParams<TakeSurveyParams>();
    const surveySourceType = +id;
    const { t } = useTranslation();

    const history = useHistory();
    const { addAlert } = useAlert();
    const [question, setQuestion] = useState<SanitizedQuestion>(null);

    const [currentView, setCurrentView] = useState(view.LOADING);
    const [questions, setQuestions] = useState<SanitizedQuestion[]>([]);
    const [progress, setProgress] = useState<number[]>(null);
    const [questionNo, setQuestionNo] = useState<number>(-1);

    if (!uid) {
        return <ErrorPage code={'702'} />;
    }
    if (isNaN(surveySourceType)) {
        return <ErrorPage code={'702'} />;
    }

    const startSurveyHandler = () => {
        const goToNextQuestions = nextQuestion();
        console.log('goToNextQuestions', goToNextQuestions);
        if (goToNextQuestions) {
            setCurrentView(view.ANSWER_QUESTIONS);
        } else {
            setCurrentView(view.END);
        }
    };

    const nextQuestion = () => {
        let n = questionNo;
        n++;
        if (questions.length <= n) {
            return false;
        }
        let nextQuestion = questions[n];

        while (progress.indexOf(nextQuestion.id) !== -1) {
            n++;
            if (questions.length <= n) {
                return false;
            }
            nextQuestion = questions[n];
        }
        setQuestionNo(n);
        setQuestion(nextQuestion);
        return true;
    };

    const submitAnswer = (answer: Answer) => {
        // Post answer
        answer.sourceUid = uid;
        answer.type = surveySourceType;
        answerService.add(answer);
        if (!nextQuestion()) {
            setCurrentView(view.END);
        }
    };

    const getQuestions = async (): Promise<SanitizedQuestion[]> => {
        const questionsQuery = await questionService.getBySourceType(surveySourceType);
        return questionsQuery.data.sort((a, b) => a.order - b.order);
    };

    const getProgress = async (): Promise<number[]> => {
        const progressQuery = await answerService.getProgress(surveySourceType, uid);
        return progressQuery.data;
    };

    const getTerms = async () => {
        const termsQuery = await fidelityReviewService.getTermsBySourceUid(surveySourceType, uid);
        return termsQuery.data;
    };

    const insertTerms = (_questions: SanitizedQuestion[], terms: Terms): SanitizedQuestion[] => {
        for (const question of _questions) {
            question.text = formatString(question.text, terms);
            if (question.suggestions !== null) {
                for (const suggestion of question.suggestions) {
                    suggestion.text = formatString(suggestion.text, terms);
                }
            }
        }
        return _questions;
    };

    const init = async () => {
        try {
            let _questions = await getQuestions();
            if (!_questions) {
                setCurrentView(view.ERROR);
                return;
            }

            const progressData = await getProgress();
            if (!progressData) {
                setCurrentView(view.ERROR);
                return;
            }
            setProgress(progressData);

            const termsData = await getTerms();
            if (!termsData) {
                setCurrentView(view.ERROR);
                return;
            }
            _questions = insertTerms(_questions, termsData);
            setQuestions(_questions);

            if (progressData.length === _questions.length) {
                setCurrentView(view.ALREADY_COMPLETED);
                return;
            }
            setCurrentView(view.START);
        } catch (error) {
            let message = 'Client error';
            if (axios.isAxiosError(error)) {
                const axiosError: AxiosError = error;
                if (axiosError.response && axiosError.response.status === 400) {
                    message = 'Could not find Survey';
                    history.push(routes.ERROR_PAGE.path.replace(':code', '701'));
                    return;
                }
                message = axiosError.message;
            }
            addAlert('error', message);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <PublicPageContainer>
            <div style={{ textAlign: 'center' }}>
                {<h1>{t('common.survey')}</h1>}

                {currentView === view.LOADING && <CircularProgress sx={{ margin: '10px 0' }} />}
                {currentView === view.START && (
                    <>
                        <Button variant="contained" onClick={startSurveyHandler}>
                            {t('takeSurvey.startSurvey')}
                        </Button>
                    </>
                )}

                {currentView === view.ANSWER_QUESTIONS && <Question question={question} submitAnswer={submitAnswer}></Question>}

                {currentView === view.END && (
                    <Paper sx={{ width: '90%', margin: '20px auto' }}>
                        <Alert severity="success">
                            <AlertTitle>
                                <strong>{t('common.completed')}</strong>
                            </AlertTitle>
                        </Alert>
                    </Paper>
                )}
                {currentView === view.ALREADY_COMPLETED && (
                    <Paper sx={{ width: '90%', margin: '20px auto' }}>
                        <Alert severity="info">
                            <AlertTitle>
                                <strong>{t('takeSurvey.allreadyCompleted')}</strong>
                            </AlertTitle>
                        </Alert>
                    </Paper>
                )}
            </div>
            {currentView === view.ERROR && (
                <Paper sx={{ width: '90%', margin: '20px auto' }}>
                    <Alert severity="error">
                        <AlertTitle>
                            <strong>{t('takeSurvey.somethingWentWrong')}</strong>
                        </AlertTitle>
                        {t('takeSurvey.somethingWentWrong')}
                    </Alert>
                </Paper>
            )}
        </PublicPageContainer>
    );
};

export default TakeSurvey;

import { Paper, Stack } from '@mui/material';
import InfoText from 'components/InfoText/InfoText';
import { t } from 'i18next';
import FidelityReview from 'models/FidelityReview';
import moment from 'moment';
import { FC, ReactNode } from 'react';

type FidelityReviewShortInfoProps = {
    review: FidelityReview;
    children?: ReactNode;
};

const FidelityReviewShortInfo: FC<FidelityReviewShortInfoProps> = ({ review, children }) => {
    return (
        <Paper sx={{ padding: '20px', marginBottom: '20px' }}>
            <Stack spacing={2} direction="row">
                <InfoText label={t('common.orderNo')} value={review.id} />
                <InfoText label={t('common.organizationName')} value={review.organizationName} />
                <InfoText label={t('common.organizationNo')} value={review.organizationNo} />
                <InfoText label={t('common.reviewDate')} value={moment(review.reviewDate).format('DD.MM.YYYY')} />
                <div style={{ paddingTop: '6px', display: 'flex' }}>{children}</div>
            </Stack>
        </Paper>
    );
};

export default FidelityReviewShortInfo;

import { Button, Chip, Divider, Paper, Stack } from '@mui/material';
import InfoText from 'components/InfoText/InfoText';
import PercentageCircle from 'components/PercentageCircle/PercentageCircle';
import ReviewTimeline from 'components/ReviewTimeline/ReviewTimeline';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fidelityReviewService from 'services/fidelityReview.service';
import clientService from 'services/client.service';
import employeeService from 'services/employee.service';
import familyService from 'services/family.service';
import partnerService from 'services/partner.service';
import FidelityReview from 'models/FidelityReview';
import routes from 'consts/routes';
import { useHistory } from 'react-router';

type FidelityReviewEvaluatorProps = {
    id: number;
};

type Count = {
    clients: number;
    employees: number;
    family: number;
    partners: number;
};

const FidelityReviewEvaluator: FC<FidelityReviewEvaluatorProps> = ({ id }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [fidelityReview, setFidelityReview] = useState<FidelityReview>(null);
    const [count, setCount] = useState<Count>(null);

    const init = async () => {
        const review = (await fidelityReviewService.getSingle(id)).data;
        setFidelityReview(review);

        const count: Count = {
            clients: (await clientService.countForFidelityReview(review.id)).data.count,
            employees: (await employeeService.countForFidelityReview(review.id)).data.count,
            family: (await familyService.countForFidelityReview(review.id)).data.count,
            partners: (await partnerService.countForFidelityReview(review.id)).data.count,
        };
        setCount(count);
    };

    // Todo: Get review
    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            {fidelityReview !== null && (
                <div>
                    <Stack direction="row" spacing={2}>
                        <Paper sx={{ padding: '20px', minWidth: '200px' }}>
                            <Stack spacing={2}>
                                <h2>{t('common.organization')}</h2>
                                <InfoText label={t('common.organizationName')} value={fidelityReview.organizationName} />
                                <InfoText label={t('common.organizationNo')} value={fidelityReview.organizationNo} />
                                <InfoText label={t('common.terminology')}>
                                    {fidelityReview.clientTerm} &ndash;{' '}
                                    <i>
                                        <small>{t('common.client')}</small>
                                    </i>
                                    <br />
                                    {fidelityReview.employmentSpecialistTerm} &ndash;{' '}
                                    <i>
                                        <small>{t('common.employmentSpecialist')}</small>
                                    </i>
                                    <br />
                                    {fidelityReview.supervisorTerm} &ndash;{' '}
                                    <i>
                                        <small>{t('common.supervisor')}</small>
                                    </i>
                                </InfoText>
                                <InfoText label={t('common.billingContact')}>
                                    <span>{fidelityReview.billingContactName}</span>
                                    <br />
                                    <span>{fidelityReview.billingContactPhone}</span>
                                    <br />
                                    <span>{fidelityReview.billingContactEmail}</span>
                                </InfoText>
                                <InfoText label={t('common.supervisor')}>
                                    <span>{fidelityReview.supervisorName}</span>
                                    <br />
                                    <span>{fidelityReview.supervisorPhone}</span>
                                    <br />
                                    <span>{fidelityReview.supervisorEmail}</span>
                                </InfoText>
                            </Stack>
                        </Paper>
                        <Paper sx={{ padding: '20px', minWidth: '300px' }}>
                            <h2>Sources</h2>
                            <Stack spacing={2}>
                                {count !== null && (
                                    <div>
                                        <Chip label={count.clients} color="secondary" />
                                        &nbsp; {fidelityReview.clientTerm}
                                    </div>
                                )}
                                {count !== null && (
                                    <div>
                                        <Chip label={count.employees} color="secondary" />
                                        &nbsp; {fidelityReview.employmentSpecialistTerm}
                                    </div>
                                )}
                                {count !== null && (
                                    <div>
                                        <Chip label={count.family} color="secondary" />
                                        &nbsp; {t('common.family')}
                                    </div>
                                )}
                                {count !== null && (
                                    <div>
                                        <Chip label={count.partners} color="secondary" />
                                        &nbsp; {t('common.partners')}
                                    </div>
                                )}
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => history.push(routes.SOURCES.path.replace(':id', fidelityReview.id.toString()))}
                                    >
                                        {t('common.viewMore')}
                                    </Button>
                                </div>
                            </Stack>
                        </Paper>
                        <div style={{ textAlign: 'left', flex: 1, padding: '20px' }}>
                            <h2>{t('common.status')}</h2>
                            <ReviewTimeline
                                status={fidelityReview.status}
                                reviewDate={fidelityReview.reviewDate}
                                orderDate={fidelityReview.orderDate}
                            />
                        </div>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        sx={{ margin: '20px 0' }}
                    >
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <h4>Calendar Entry</h4>
                            <div>
                                <PercentageCircle radius={60} percent={10} color={'adjust'} innerColor={'#f3f3f3'} />
                            </div>
                            <Button variant="contained">{t('common.viewMore')}</Button>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <h4>Management Information</h4>
                            <div>
                                <PercentageCircle radius={60} percent={40} color={'adjust'} innerColor={'#f3f3f3'} />
                            </div>
                            <Button onClick={() => history.push(routes.ACTIVITIES.path.replace(':id', fidelityReview.id.toString()))} variant="contained">
                                {t('common.viewMore')}
                            </Button>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <h4>Surveys</h4>
                            <div>
                                <PercentageCircle radius={60} percent={90} color={'adjust'} innerColor={'#f3f3f3'} />
                            </div>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    history.push(routes.FIDELITY_REVIEW_SURVEY_PROGRESS.path.replace(':id', fidelityReview.id.toString()))
                                }
                            >
                                {t('common.viewMore')}
                            </Button>
                        </div>
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default FidelityReviewEvaluator;

import XlsxTemplate from 'types/XlsxTemplate';
import i18n from 'translation';

const SupervisorXlsxTemplate = {
    name: `${i18n.t('common.supervisor')}-${i18n.t('common.template')}`,
    sheets: [
        {
            name: `${i18n.t('common.supervisors')}`,
            headerRow: [
                { header: `${i18n.t('common.name')}*`, width: 30 },
                { header: `${i18n.t('common.phone')}*`, width: 30 },
                { header: `${i18n.t('common.email')}*`, width: 30 },
                { header: `${i18n.t('common.vacancyRate')}*`, width: 30 },
                { header: `${i18n.t('common.startDate')} (DD.MM.YYYY)*`, width: 35 },
            ],
        },
   
    ],
} as XlsxTemplate;

export default SupervisorXlsxTemplate;

import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { t } from 'i18next';
import Question from 'models/Question';
import { ReactNode, useEffect, useState } from 'react';
import questionService from 'services/question.service';
import EditQuestionModal from './EditQuestionModal/EditQuestionModal';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConsentModal } from 'components';
import { useAlert } from 'hooks';
import Suggestion from 'models/Suggestion';
import suggestionService from 'services/suggestion.service';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import questionType from 'consts/questionType';
import NumbersIcon from '@mui/icons-material/Numbers';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const modals = {
    addQuestion: 0,
    editQuestion: 1,
    copyQuestion: 2,
    deleteQuestion: 3,
};

const EditSurvey = () => {
    const [questions, setQuestions] = useState<Question[]>(null);
    const [modal, setModal] = useState<number>(-1);
    const [selectedQuestion, setSelectedQuestion] = useState<Question>(null);
    const { addAlert } = useAlert();

    const getQuestions = async () => {
        const questionsQuery = await questionService.getAll();
        setQuestions(questionsQuery.data);
    };

    const updateSuggestions = async (newSuggestions: Suggestion[], oldSuggestions: Suggestion[]) => {
        // Delete suggestions
        const newSuggestionIds = newSuggestions.map((ns) => ns.id);
        const toBeDeleted = oldSuggestions.filter((os) => newSuggestionIds.indexOf(os.id) === -1);
        let ps = toBeDeleted.map((s) => suggestionService.delete(s.id));

        // Add suggestions
        const toBeAdded = newSuggestions.filter((ns) => ns.id === 0);
        ps = [...ps, ...toBeAdded.map((s) => suggestionService.add(s))];

        // Update suggestions
        const toBeUpdated = newSuggestions.filter((ns) => {
            if (ns.id === 0) {
                return false;
            }
            const os = oldSuggestions.find((s) => s.id === ns.id);
            if (!os) {
                throw new Error('Could not find the corresponding suggestion to update.');
            }
            // Only update if suggestion has changed
            return os.order !== ns.order || os.text !== ns.text || os.value !== ns.value;
        });
        ps = [...ps, ...toBeUpdated.map((s) => suggestionService.update(s))];

        // Await all responses
        await Promise.all(ps);
    };

    const updateQuestion = async (question: Question) => {
        try {
            // Handle suggestions
            if (question.type === questionType.INPUT_INT) {
                const ps = selectedQuestion.suggestions.map((s) => suggestionService.delete(s.id));
                await Promise.all(ps);
            } else {
                await updateSuggestions(question.suggestions, selectedQuestion.suggestions);
            }

            const updatedQuestionQuery = await questionService.update(question);
            const updatedQuestion = updatedQuestionQuery.data;
            const updatedQuestions = questions
                .map((q) => {
                    if (updatedQuestion.id == q.id) {
                        return updatedQuestion;
                    }
                    return q;
                })
                .sort((a, b) => a.order - b.order);
            setQuestions(updatedQuestions);
            setSelectedQuestion(null);
        } catch (error) {
            addAlert('error', (error as Error)?.message);
        }

        setModal(-1);
    };
    const deleteQuestion = async () => {
        try {
            await questionService.delete(selectedQuestion.id);
            const updatedQuestions = questions.filter((q) => q.id !== selectedQuestion.id);
            setQuestions(updatedQuestions);
            setSelectedQuestion(null);
        } catch (error) {
            addAlert('error', (error as Error)?.message);
        }

        setModal(-1);
    };
    const addQuestion = async (question: Question) => {
        try {
            const addQuestionQuery = await questionService.add(question);
            const updatedQuestions = [...questions, addQuestionQuery.data].sort((a, b) => a.order - b.order);
            setQuestions(updatedQuestions);
        } catch (error) {
            addAlert('error', (error as Error)?.message);
        }

        setModal(-1);
    };
    const radioIcon = (bool: boolean): ReactNode => {
        if (bool) {
            return <RadioButtonCheckedIcon />;
        } else {
            return <RadioButtonUncheckedIcon />;
        }
    };
    const questionTypeIcon = (num: number): ReactNode => {
        if (num === questionType.INPUT_INT) {
            return <NumbersIcon />;
        }
        if (num === questionType.MULTIPLE_CHOICE) {
            return <FormatListBulletedIcon />;
        }
        return null;
    };
    useEffect(() => {
        getQuestions();
    }, []);

    return (
        <>
            <h1>EDIT SURVEY</h1>
            <Paper sx={{ margin: '0 auto', padding: '20px' }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setModal(modals.addQuestion);
                    }}
                >
                    Add Question
                </Button>
            </Paper>
            <TableContainer component={Paper} sx={{ margin: '20px auto', textAlign: 'center' }}>
                {!questions && <CircularProgress sx={{ margin: '10px 0' }} />}
                {questions && (
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <EditIcon />
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <FileCopyIcon />
                                </TableCell>
                                <TableCell>{t('common.order')}</TableCell>
                                <TableCell>{t('common.criteriaNo')}</TableCell>
                                <TableCell>{t('common.text')}</TableCell>
                                <TableCell>{t('common.type')}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.employmentSpecialist')}>
                                        <span>{t('acronyms.employmentSpecialist')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.client')}>
                                        <span>{t('acronyms.client')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.family')}>
                                        <span>{t('acronyms.family')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.supervisor')}>
                                        <span>{t('acronyms.supervisor')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.management')}>
                                        <span>{t('acronyms.management')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.partnerVocationalRehabilitation')}>
                                        <span>{t('acronyms.partnerVocationalRehabilitation')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.partnerHealthOrganization')}>
                                        <span>{t('acronyms.partnerHealthOrganization')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Tooltip title={t('common.evaluator')}>
                                        <span>{t('acronyms.evaluator')}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((question) => (
                                <TableRow key={question.id}>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedQuestion(question);
                                                setModal(modals.editQuestion);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedQuestion(question);
                                                setModal(modals.copyQuestion);
                                            }}
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{question.order}</TableCell>
                                    <TableCell>{question.criteriaNo}</TableCell>
                                    <TableCell>{question.text}</TableCell>
                                    <TableCell>{questionTypeIcon(question.type)}</TableCell>
                                    <TableCell>{radioIcon(question.employmentSpecialist)}</TableCell>
                                    <TableCell>{radioIcon(question.client)}</TableCell>
                                    <TableCell>{radioIcon(question.family)}</TableCell>
                                    <TableCell>{radioIcon(question.supervisor)}</TableCell>
                                    <TableCell>{radioIcon(question.management)}</TableCell>
                                    <TableCell>{radioIcon(question.partnerVocationalRehabilitation)}</TableCell>
                                    <TableCell>{radioIcon(question.partnerHealthOrganization)}</TableCell>
                                    <TableCell>{radioIcon(question.evaluator)}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedQuestion(question);
                                                setModal(modals.deleteQuestion);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {modal === modals.addQuestion && (
                    <EditQuestionModal
                        onCancel={() => {
                            setModal(-1);
                        }}
                        onConfirm={(result) => {
                            addQuestion(result);
                        }}
                    />
                )}

                {modal === modals.editQuestion && (
                    <EditQuestionModal
                        question={selectedQuestion}
                        onCancel={() => {
                            setModal(-1);
                        }}
                        onConfirm={(result) => {
                            updateQuestion(result);
                        }}
                    />
                )}
                {modal === modals.copyQuestion && (
                    <EditQuestionModal
                        question={selectedQuestion}
                        onCancel={() => {
                            setModal(-1);
                        }}
                        onConfirm={(result) => {
                            addQuestion(result);
                        }}
                    />
                )}
                {modal === modals.deleteQuestion && (
                    <ConsentModal
                        title={'Delete question'}
                        content={`Are you sure you want to delete the question?`}
                        onCancel={() => {
                            setModal(-1);
                        }}
                        onConfirm={() => {
                            deleteQuestion();
                        }}
                    />
                )}
            </TableContainer>
        </>
    );
};

export default EditSurvey;

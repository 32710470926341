import activityTypes from 'consts/activityTypes';
import Activity from 'models/Activity';

const filterActivitiesByType = (activities: Activity[] = []) => {
    const workActivities = activities.filter((activity) => activity.activityType === activityTypes.WORK);
    const workTrialActivities = activities.filter((activity) => activity.activityType === activityTypes.WORK_TRIAL);
    const trainingActivities = activities.filter((activity) => activity.activityType === activityTypes.TRAINING);

    return { workActivities, workTrialActivities, trainingActivities };
};

export default filterActivitiesByType;
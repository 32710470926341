import axios from 'axios';
import { useEffect, useState } from 'react';
import User from 'models/User';

const userService = {
    getUsers: () => {
        return axios.get('user/');
    },
    useUsers: () => {
        const usersState = useState<User[]>([]);

        useEffect(() => {
            const getUsers = async () => {
                try {
                    const { data } = await userService.getUsers();
                    usersState[1](data);
                } catch (error) {
                    console.error(error);
                }
            };

            getUsers();
        }, []);

        return usersState;
    },
    addUser: (user: User) => {
        return axios.post('user/', user);
    },
    deleteUser: (userId: string) => {
        return axios.delete(`user/${userId}`);
    },
};

export default userService;

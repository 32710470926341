import { Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import activityTypes from 'consts/activityTypes';
import Activity from 'models/Activity';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

type ActivityListProps = {
    activities: Activity[];
    activityType: number;
    onEdit: (activity: Activity) => void;
};

const ActivityList: FC<ActivityListProps> = ({ activities = [], activityType, onEdit }) => {
    const { t } = useTranslation();

    return (
        <div className="activityList">
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            {activityType !== activityTypes.TRAINING && <TableCell>{t('activity.employer')}</TableCell>}
                            {activityType !== activityTypes.TRAINING && <TableCell>{t('activity.jobDescription')}</TableCell>}
                            {activityType === activityTypes.TRAINING && <TableCell>{t('activity.placeOfTraining')}</TableCell>}
                            {activityType === activityTypes.TRAINING && <TableCell>{t('activity.typeOfTraining')}</TableCell>}
                            {activityType === activityTypes.TRAINING && <TableCell>{t('activity.qualification')}</TableCell>}
                            {activityType === activityTypes.WORK_TRIAL && <TableCell>{t('activity.incentive')}</TableCell>}
                            {activityType === activityTypes.WORK && <TableCell>{t('activity.hourlyWage')}</TableCell>}
                            <TableCell>{t('activity.hoursPerWeek')}</TableCell>
                            <TableCell>{t('activity.meetingWithJobSpecialist')}</TableCell>
                            <TableCell>{t('activity.start')}</TableCell>
                            <TableCell>{t('activity.end')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.map((activity, index) => (
                            <TableRow key={index}>
                                {activityType !== activityTypes.TRAINING && <TableCell>{activity.employer}</TableCell>}
                                {activityType !== activityTypes.TRAINING && <TableCell>{activity.jobDescription}</TableCell>}
                                {activityType === activityTypes.TRAINING && <TableCell>{activity.placeOfTraining}</TableCell>}
                                {activityType === activityTypes.TRAINING && <TableCell>{activity.typeOfTraining}</TableCell>}
                                {activityType === activityTypes.TRAINING && <TableCell>{activity.qualification}</TableCell>}
                                {activityType === activityTypes.WORK_TRIAL && <TableCell>{activity.incentive}</TableCell>}
                                {activityType === activityTypes.WORK && <TableCell>{activity.hourlyWage}</TableCell>}
                                <TableCell>{activity.hoursPerWeek}</TableCell>
                                <TableCell>{moment(activity.meetingWithJobSpecialist).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{moment(activity.start).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{moment(activity.end).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(activity)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {activities.length === 0 && (
                    <Container sx={{ padding: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}>No activities</Typography>
                    </Container>
                )}
            </TableContainer>
        </div>
    );
};

export default ActivityList;

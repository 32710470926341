import { AlertColor } from '@mui/material';
import { useDispatch } from 'react-redux';
import { uniqeId } from 'utils';
import Alert from 'types/Alert';
import { alertActions } from 'store';

const useAlert = () => {
    const dispatch = useDispatch();

    const addAlert = (type: AlertColor = 'success', text = '', timeout = 5000) => {
        const alertId = uniqeId();

        const alert: Alert = {
            id: alertId,
            type,
            text,
            timeout,
        };

        dispatch(alertActions.addAlert(alert));

        if (alert.timeout && alert.timeout > 0) {
            setTimeout(() => {
                removeAlert(alert.id);
            }, alert.timeout);
        }
    };

    const removeAlert = (id: string) => {
        dispatch(alertActions.removeAlert(id));
    };

    return { addAlert, removeAlert };
};

export default useAlert;

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FidelityReview from 'models/FidelityReview';
import fidelityReviewStatus from 'consts/fidelityReviewStatus';
import fidelityReviewService from 'services/fidelityReview.service';
import moment from 'moment';
import { useHistory } from 'react-router';
import routes from 'consts/routes';

const FidelityReviewListActive = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [reviews, setReviews] = useState<FidelityReview[]>(null);

    useEffect(() => {
        fidelityReviewService.getByStatus(fidelityReviewStatus.GATHERING_DATA).then((result) => {
            setReviews(result.data);
        });
    }, []);

    return (
        <div>
            <h1>{t('activeFidelityReviews.activeFidelityReviews')}</h1>
            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                {reviews === null ? (
                    <CircularProgress sx={{ margin: '10px 0' }} />
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>{t('common.orderNo')}</TableCell>
                                    <TableCell>{t('common.supervisorName')}</TableCell>
                                    <TableCell>{t('common.supervisorEmail')}</TableCell>
                                    <TableCell>{t('common.organizationName')}</TableCell>
                                    <TableCell>{t('common.organizationNo')}</TableCell>
                                    <TableCell>{t('common.reviewDate')}</TableCell>
                                    <TableCell>{t('common.orderDate')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reviews.map((review) => (
                                    <TableRow key={review.id}>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    history.push(routes.FIDELITY_REVIEW.path.replace(':id', review.id.toString()));
                                                }}
                                            >
                                                {t('common.viewMore')}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{review.id}</TableCell>
                                        <TableCell>{review.supervisorName}</TableCell>
                                        <TableCell>{review.supervisorEmail}</TableCell>
                                        <TableCell>{review.organizationName}</TableCell>
                                        <TableCell>{review.organizationNo}</TableCell>
                                        <TableCell>{moment(review.reviewDate).format('DD.MM.YYYY')}</TableCell>
                                        <TableCell>{moment(review.orderDate).format('DD.MM.YYYY')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {reviews.length === 0 && (
                            <p style={{ textAlign: 'center' }}>
                                <i>{t('common.theListIsEmpty')}</i>
                            </p>
                        )}
                    </TableContainer>
                )}
            </Paper>
        </div>
    );
};

export default FidelityReviewListActive;

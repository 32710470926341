import { DatePicker } from '@mui/lab';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type ReactHookDatePickerProps = {
    name: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    validate?: (value: Date) => boolean | string;
    error?: boolean;
    helperText?: string;
    required?: boolean | string;
};

// TODO: add custom validation prop

const ReactHookDatePicker: FC<ReactHookDatePickerProps> = ({ required = false, name, label, validate, error, control, helperText, ...props }) => {
    return (
        <FormControl {...props}>
            <Controller
                rules={{
                    required: required,
                    validate: validate
                }}
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <DatePicker
                        label={label}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        value={value === undefined ? null : value}
                        inputFormat="DD.MM.yyyy"
                        renderInput={(params: TextFieldProps) => <TextField {...params} 
                        error={error}
                        helperText={helperText} />}
                    />
                )}
            />
        </FormControl>
    );
};

export default ReactHookDatePicker;

import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import './FidelityReviewListOrder.scss';
import { useEffect, useState } from 'react';
import FidelityReview from 'models/FidelityReview';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StartReviewModal from 'components/StartReviewModal/StartReviewModal';
import { ConsentModal } from 'components';
import fidelityReviewStatus from 'consts/fidelityReviewStatus';
import fidelityReviewService from 'services/fidelityReview.service';
import { useHistory } from 'react-router';
import routes from 'consts/routes';
import moment from 'moment';

const FidelityReviewListOrder = () => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState<FidelityReview[]>(null);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [startReviewModal, setStartReviewModal] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [deleteOrderModal, setDeleteOrderModal] = useState(false);
    const history = useHistory();

    const deleteOrder = async () => {
        await fidelityReviewService.delete(orderToDelete.id);
        getOrders();
    };

    // Start
    const getOrders = async () => {
        const ordersRequests = await Promise.all([
            fidelityReviewService.getByStatus(fidelityReviewStatus.ORDER_RECIEVED),
            fidelityReviewService.getByStatus(fidelityReviewStatus.AWAITING_REGISTRATION),
        ]);
        const ordersData = ordersRequests.map((or) => or.data).flat();
        setOrders(ordersData);
    };
    useEffect(() => {
        getOrders();
    }, []);

    return (
        <div>
            <h1>{t('common.fidelityReviewOrders')}</h1>            
            <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                {orders === null ? (
                    <CircularProgress sx={{ margin: '10px 0' }} />
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>{t('common.orderNo')}</TableCell>
                                    <TableCell>{t('common.status')}</TableCell>
                                    <TableCell>{t('common.supervisorName')}</TableCell>
                                    <TableCell>{t('common.organizationName')}</TableCell>
                                    <TableCell>{t('common.reviewDate')}</TableCell>
                                    <TableCell>{t('common.orderDate')}</TableCell>
                                    <TableCell align="center" width="50"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell>
                                            {order.status === fidelityReviewStatus.ORDER_RECIEVED && (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        setSelectedOrder(order);
                                                        setStartReviewModal(true);
                                                    }}
                                                >
                                                    {t('orderFidelityReview.startReview')}
                                                </Button>
                                            )}
                                            {order.status === fidelityReviewStatus.AWAITING_REGISTRATION && (
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {
                                                        history.push(routes.FIDELITY_REVIEW.path.replace(':id', order.id.toString()));
                                                    }}
                                                >
                                                    {t('common.viewMore')}
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell>{order.id}</TableCell>
                                        <TableCell>{t('fidelityReviewStatus.' + order.status)}</TableCell>
                                        <TableCell>{order.supervisorName}</TableCell>
                                        <TableCell>{order.organizationName}</TableCell>
                                        <TableCell>{moment(order.reviewDate).format('MM.DD.YYYY')}</TableCell>
                                        <TableCell>{moment(order.orderDate).format('MM.DD.YYYY')}</TableCell>
                                        <TableCell align="center" width="50">
                                            <IconButton
                                                onClick={() => {
                                                    setDeleteOrderModal(true);
                                                    setOrderToDelete(order);
                                                }}
                                            >
                                                <DeleteIcon color="secondary" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {orders.length === 0 && (
                            <p style={{ textAlign: 'center' }}>
                                <i>{t('common.theListIsEmpty')}</i>
                            </p>
                        )}
                    </TableContainer>
                )}
                {startReviewModal && (
                    <StartReviewModal
                        onCancel={() => {
                            setStartReviewModal(false);
                        }}
                        onConfirm={() => {
                            setStartReviewModal(false);
                            setOrders(null);
                            getOrders();
                        }}
                        fidelityReview={selectedOrder}
                    />
                )}
                {deleteOrderModal && (
                    <ConsentModal
                        title={'Delete order'}
                        content={`Are you sure you want to delete order from ${orderToDelete.organizationName}`}
                        onCancel={() => {
                            setOrderToDelete(null);
                            setDeleteOrderModal(false);
                        }}
                        onConfirm={() => {
                            deleteOrder();
                            setOrderToDelete(null);
                            setDeleteOrderModal(false);
                        }}
                    />
                )}
            </Paper>
        </div>
    );
};

export default FidelityReviewListOrder;

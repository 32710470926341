import axios from 'axios';
import FidelityReview from 'models/FidelityReview';
import Terms from 'types/Terms';

const basePath = 'fidelity-review';

const fidelityReviewService = {
    getByStatus: (status: number) => {
        return axios.get<FidelityReview[]>(`${basePath}/by-status/${status}`);
    },
    supervisorGetAll: () => {
        return axios.get<FidelityReview[]>(`${basePath}/supervisor/`);
    },
    evaluatorGetAll: () => {
        return axios.get<FidelityReview[]>(`${basePath}/evaluator/`);
    },
    getAll: () => {
        return axios.get<FidelityReview[]>(`${basePath}/`);
    },
    getSingle: (id: number) => {
        return axios.get<FidelityReview>(`${basePath}/${id}`);
    },
    add: (model: FidelityReview) => {
        return axios.post<FidelityReview>(`${basePath}/`, model);
    },
    update: (model: FidelityReview) => {
        return axios.put<FidelityReview>(`${basePath}/${model.id}`, model);
    },
    delete: (id: number) => {
        return axios.delete(`${basePath}/${id}`);
    },
    evaluatorStartReview: (model: FidelityReview) => {
        return axios.put<FidelityReview>(`${basePath}/evaluator/start-review/`, model);
    },
    registrationComplete: (id: number) => {
        return axios.put<FidelityReview>(`${basePath}/${id}/registration-complete/`);
    },
    getTermsBySourceUid: (type: number, uid: string) => {
        return axios.get<Terms>(`${basePath}/terms-by-source-uid/${type}/${uid}`);
    }
};

export default fidelityReviewService;

import Drawer from '@mui/material/Drawer';
import { Divider, List, ListItem, ListItemText, Toolbar, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router';

import routes from 'consts/routes';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { FC } from 'react';
import role from 'consts/role';
import RouteItem from 'types/RouteItem';
import { useTranslation } from 'react-i18next';

type NavDrawerProps = {
    onDrawerOpen: () => void;
    onDrawerClose: () => void;
    displayDrawer: boolean;
};

const NavDrawer: FC<NavDrawerProps> = ({ displayDrawer }) => {
    const user = useSelector((state: RootState) => state.user);
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const createListItems = (list: RouteItem[]) => {
        return list
            .filter((routeItem: RouteItem) => {
                if (!routeItem?.roles) {
                    return true;
                } else {
                    return routeItem.roles.includes(user.role);
                }
            })
            .map((routeItem: RouteItem, index: number) => (
                <ListItem button key={index} selected={location.pathname === routeItem.path} onClick={() => history.push(routeItem.path)}>
                    <ListItemText primary={t(routeItem.menuTitle)} />
                </ListItem>
            ));
    };
    const routeItems = Object.values(routes).filter(r => r.menuTitle).reverse();
    const publicRoutes = createListItems(routeItems.filter((r) => !r.private));
    const privateRoutes = createListItems(routeItems.filter((r) => r.private));

    return (
        <Drawer
            className="main-drawer"
            sx={{
                width: displayDrawer ? 250 : 0,
                transition: 'width 0.3s',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 250,
                    boxSizing: 'border-box',
                    border: 0,
                    boxShadow: 4,
                },
            }}
            variant="persistent"
            anchor="left"
            open={displayDrawer}
        >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    IPS Norge
                </Typography>
            </Toolbar>
            <Divider />
            <List sx={{ padding: 0 }}>
                <Divider>
                    <small>Public</small>
                </Divider>
                {publicRoutes}
                {[role.HOST, role.EVALUATOR, role.SUPERVISOR].includes(user.role) && (
                    <>
                        <Divider>
                            <small>Private</small>
                        </Divider>
                        {privateRoutes}
                    </>
                )}
            </List>
        </Drawer>
    );
};

export default NavDrawer;

import { Button, Checkbox, Divider, FormControlLabel, Stack, TextField, TextFieldProps } from '@mui/material';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from '@mui/lab/DatePicker';
import { validate } from 'utils';
import FidelityReview from 'models/FidelityReview';

type OrganizationInformationFormProps = {
    onNext: (order: FidelityReview) => void;
    order: FidelityReview;
};

type FormValues = {
    organizationName: string;
    organizationNo: string;
    reviewDate: Date;
    supervisorName: string;
    supervisorPhone: string;
    supervisorEmail: string;
    billingContactName: string;
    billingContactPhone: string;
    billingContactEmail: string;
};

const OrganizationInformationForm: FC<OrganizationInformationFormProps> = ({ onNext, order }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            organizationName: order.organizationName,
            organizationNo: order.organizationNo?.toString(),
            reviewDate: order.reviewDate,
            supervisorName: order.supervisorName,
            supervisorPhone: order.supervisorPhone,
            supervisorEmail: order.supervisorEmail,
            billingContactName: order.billingContactName,
            billingContactPhone: order.billingContactPhone,
            billingContactEmail: order.billingContactEmail,
        },
    });

    const { t } = useTranslation();
    const [billingSameAsSupervisor, setBillingSameAsSupervisor] = useState<boolean>(true);

    const onSubmit: SubmitHandler<FormValues> = async (formData) => {
        // Create supervisor
        order.supervisorName = formData.supervisorName;
        order.supervisorEmail = formData.supervisorEmail;
        order.supervisorPhone = formData.supervisorPhone;
        order.billingContactName = billingSameAsSupervisor ? formData.supervisorName : formData.billingContactName;
        order.billingContactEmail = billingSameAsSupervisor ? formData.supervisorEmail : formData.billingContactEmail;
        order.billingContactPhone = billingSameAsSupervisor ? formData.supervisorPhone : formData.billingContactPhone;
        order.organizationName = formData.organizationName;
        order.organizationNo = +formData.organizationNo.replaceAll(' ', '');
        order.reviewDate = formData.reviewDate;

        onNext(order);
    };

    // DatePicker
    const [tempDate, setTempDate] = useState<Date>(order.reviewDate);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>{t('orderFidelityReview.organizationInformation')}</h2>
            <Stack rowGap="10px">
                <TextField
                    label={t('common.organizationName')}
                    {...register('organizationName', {
                        required: t('common.required').toString(),
                    })}
                    error={errors.organizationName ? true : false}
                    helperText={errors.organizationName ? errors.organizationName.message : ''}
                />
                <TextField
                    label={t('common.organizationNo')}
                    {...register('organizationNo', {
                        pattern: {
                            value: /^\d{3}\s?\d{3}\s?\d{3}$/,
                            message: 'Please provide correct organization no. pattern.',
                        },
                    })}
                    error={errors.organizationNo ? true : false}
                    helperText={errors.organizationNo ? errors.organizationNo.message : ''}
                />
                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                <DatePicker
                    value={tempDate}
                    {...register('reviewDate', {
                        required: {
                            value: true,
                            message: t('common.required'),
                        },
                    })}
                    onChange={(date: Date) => {
                        setTempDate(date);
                        setValue('reviewDate', date, {
                            shouldValidate: true,
                            shouldDirty: true,
                        });
                    }}
                    label={t('orderFidelityReview.desiredReviewDate')}
                    inputFormat="DD.MM.yyyy"
                    renderInput={(params: TextFieldProps) => <TextField {...params} />}
                />

                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                <TextField
                    label={t('common.supervisorName')}
                    {...register('supervisorName', {
                        required: t('common.required').toString(),
                    })}
                    error={errors.supervisorName ? true : false}
                    helperText={errors.supervisorName ? errors.supervisorName.message : ''}
                />
                <TextField
                    label={t('common.supervisorEmail')}
                    {...register('supervisorEmail', {
                        validate: (e) => (validate.email(e) ? true : t('common.invalidEmail').toString()),
                    })}
                    error={errors.supervisorEmail ? true : false}
                    helperText={errors.supervisorEmail ? errors.supervisorEmail.message : ''}
                />
                <TextField
                    label={t('common.supervisorPhone')}
                    {...register('supervisorPhone', {
                        validate: (e) => (validate.phone(e) ? true : t('common.invalidPhone').toString()),
                    })}
                    error={errors.supervisorPhone ? true : false}
                    helperText={errors.supervisorPhone ? errors.supervisorPhone.message : ''}
                />
                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                <FormControlLabel
                    label={t('orderFidelityReview.billingContactSameAsSupervisor').toString()}
                    control={
                        <Checkbox
                            defaultChecked={billingSameAsSupervisor}
                            onChange={(event, checked) => {
                                setBillingSameAsSupervisor(checked);
                            }}
                        />
                    }
                />

                {!billingSameAsSupervisor && (
                    <>
                        <TextField
                            label={t('common.billingContactName')}
                            {...register('billingContactName', {
                                required: t('common.required').toString(),
                            })}
                            error={errors.billingContactName ? true : false}
                            helperText={errors.billingContactName ? errors.billingContactName.message : ''}
                        />
                        <TextField
                            label={t('common.billingContactEmail')}
                            {...register('billingContactEmail', {
                                validate: (e) => (validate.email(e) ? true : t('common.invalidEmail').toString()),
                            })}
                            error={errors.billingContactEmail ? true : false}
                            helperText={errors.billingContactEmail ? errors.billingContactEmail.message : ''}
                        />
                        <TextField
                            label={t('common.billingContactPhone')}
                            {...register('billingContactPhone', {
                                validate: (e) => (validate.phone(e) ? true : t('common.invalidPhone').toString()),
                            })}
                            error={errors.billingContactPhone ? true : false}
                            helperText={errors.billingContactPhone ? errors.billingContactPhone.message : ''}
                        />
                    </>
                )}
                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                <div>
                    <Button type="submit" variant="contained" sx={{ float: 'right' }}>
                        {t('common.next')}
                    </Button>
                </div>
            </Stack>
        </form>
    );
};

export default OrganizationInformationForm;

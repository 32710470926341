import routes from 'consts/routes';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { RootState } from 'store';
import RouteItem from 'types/RouteItem';

type RouteHandlerProps = {
    routeItem: RouteItem;
    key?: string;
    path: string;
};

const RouteHandler: FC<RouteHandlerProps> = ({ routeItem, path, key, ...rest }) => {
    const user = useSelector((state: RootState) => state.user);

    // Public route
    if (!routeItem.private) {
        return <Route key={key} path={path} {...rest} render={() => routeItem.component} />;
    }

    // Not logged inn
    if (!user) {
        return (
            <Route
                key={key}
                path={path}
                {...rest}
                render={() => (
                    <Redirect
                        to={
                            routes.LOGIN.path +
                            '?returnPath=' +
                            encodeURIComponent(window.location.pathname)
                        }
                    />
                )}
            />
        );
    }

    // Invalid role
    if (routeItem.roles && !routeItem.roles.includes(user.role)) {
        return <Route key={key} path={path} {...rest} render={() => <ErrorPage code={'401'} />} />;
    }

    // Valid role
    return <Route key={key} path={path} {...rest} render={() => routeItem.component} />;
};

export default RouteHandler;

import axios from 'axios';
import { useDispatch } from 'react-redux';
import authService from 'services/auth.service';
import { userActions } from 'store';

const useAuth = () => {
    const dispatch = useDispatch();

    const signInUser = async (email: string, password: string) => {
        try {
            const res = await authService.signIn(email, password);
            if (res?.data?.token) {
                const tokenString = JSON.stringify(res.data.token);
                localStorage.setItem('dfrt-token', tokenString);

                axios.defaults.headers.common['Authorization'] = res.data.token;

                const userInfoPromise = authService.getUserInfo();
                const userRolesPromise = authService.getUserRoles();

                const [{ data: user }, { data: role }] = await Promise.all([userInfoPromise, userRolesPromise]);
                user.role = role;
                dispatch(userActions.set(user));
                return true;
            }
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status == 401) {
                return false;
            } else {
                throw err;
            }
        }
    };

    const getUser = async () => {
        try {
            const userInfoPromise = authService.getUserInfo();
            const userRolesPromise = authService.getUserRoles();

            await Promise.all([userInfoPromise, userRolesPromise]).then((values) => {
                const [{ data: user }, { data: role }] = values;
                user.role = role;
                dispatch(userActions.set(user));
            });
        } catch (error) {
            console.error(error);
        }
    };

    const signOut = async () => {
        dispatch(userActions.remove());
        localStorage.removeItem('dfrt-token');
    };

    return { signInUser, getUser, signOut };
};

export default useAuth;

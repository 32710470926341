import './ForgotPassword.scss';

import { Alert, Button, CircularProgress, TextField } from '@mui/material';
import { useAuth } from 'hooks';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import authService from 'services/auth.service';
import { useHistory } from 'react-router';
import routes from 'consts/routes';
import axios, { AxiosError } from 'axios';

type FormValues = {
    email: string;
};

type View = {
    loading?: boolean;
    form?: boolean;
    success?: boolean;
    errorMessage?: string;
    error?: boolean;
};

const ForgotPassword: FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, getValues } = useForm();
    const { signOut } = useAuth();


    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        updateView({
            loading: true,
        });
        try {
            await authService.forgotPassword(data.email);
            updateView({
                success: true,
            });
        } catch (error) {
            let message = 'Client error';

            if (axios.isAxiosError(error)) {
                const axiosError: AxiosError = error;
                if (axiosError.response && axiosError.response.status === 400) {
                    message = t('Email is not correct format');
                } else {
                    message = axiosError.message;
                }
            }

            updateView({
                error: true,
                errorMessage: message,
            });
        }
    };

    const [view, setView] = useState<View>({
        loading: false,
        form: true,
        success: false,
        errorMessage: null,
        error: false,
    });

    const updateView = (updatedView: View) => {
        setView({
            ...{
                loading: false,
                form: false,
                success: false,
                errorMessage: null,
                error: false,
            },
            ...updatedView,
        });
    };

    const signOutAndGoToLogin = async () => {
        await signOut();
        history.push(routes.LOGIN.path);
    };

    return (
        <div className="forgot-password-page">
            <img className="logo" src="/assets/logo-512.png" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="forgot-password-form-container">
                    {view.success && (
                        <Alert severity="success">
                            <p>{t('forgotPassword.success', { email: getValues('email')})}</p>
                            <Button onClick={signOutAndGoToLogin} variant="contained">
                                {t('forgotPassword.goToLogin')}
                            </Button>
                        </Alert>
                    )}
                    {view.loading && (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress sx={{ margin: '10px 0' }} />
                        </div>
                    )}

                    {view.form && (
                        <>
                            <TextField sx={{ flex: 1 }} label={'Email'} {...register('email')} />
                            <Button type="submit" variant="contained" size="large">
                                {t('common.resetPassword')}
                            </Button>
                        </>
                    )}
                    {view.error && <Alert severity="error">{view.errorMessage}</Alert>}
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;

import { FC, useState } from 'react';
import './StartReviewModal.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/lab';
import FidelityReview from 'models/FidelityReview';
import fidelityReviewService from 'services/fidelityReview.service';

type StartReviewModalProps = {
    onCancel: () => void;
    onConfirm: (fidelityOrderId: number) => void;
    fidelityReview: FidelityReview;
};

type FormValues = {
    organizationName: string;
    organizationNo: string;
    reviewDate: Date;
    supervisorName: string;
    supervisorPhone: string;
    supervisorEmail: string;
    billingContactName: string;
    billingContactPhone: string;
    billingContactEmail: string;
    clientTerm: string;
    clientTermPlural: string;
    employmentSpecialistTerm: string;
    employmentSpecialistTermPlural: string;
    supervisorTerm: string;
    supervisorTermPlural: string;
};

const StartReviewModal: FC<StartReviewModalProps> = ({ onCancel, onConfirm, fidelityReview }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            organizationName: fidelityReview.organizationName,
            organizationNo: fidelityReview.organizationNo.toString(),
            reviewDate: fidelityReview.reviewDate,
            supervisorName: fidelityReview.supervisorName,
            supervisorPhone: fidelityReview.supervisorPhone,
            supervisorEmail: fidelityReview.supervisorEmail,
            billingContactName: fidelityReview.billingContactName,
            billingContactPhone: fidelityReview.billingContactPhone,
            billingContactEmail: fidelityReview.billingContactEmail,
            clientTerm: fidelityReview.clientTerm,
            clientTermPlural: fidelityReview.clientTermPlural,
            employmentSpecialistTerm: fidelityReview.employmentSpecialistTerm,
            employmentSpecialistTermPlural: fidelityReview.employmentSpecialistTermPlural,
            supervisorTerm: fidelityReview.supervisorTerm,
            supervisorTermPlural: fidelityReview.supervisorTermPlural,
        },
    });

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        const review: FidelityReview = {
            id: fidelityReview.id,
            organizationName: fields.organizationName,
            organizationNo: +fields.organizationNo,
            reviewDate: fields.reviewDate,

            clientTerm: fields.clientTerm,
            clientTermPlural: fields.clientTermPlural,
            employmentSpecialistTerm: fields.employmentSpecialistTerm,
            employmentSpecialistTermPlural: fields.employmentSpecialistTermPlural,
            supervisorTerm: fields.supervisorTerm,
            supervisorTermPlural: fields.supervisorTermPlural,

            billingContactName: fields.billingContactName,
            billingContactPhone: fields.billingContactPhone,
            billingContactEmail: fields.billingContactEmail,
            supervisorName: fields.supervisorName,
            supervisorPhone: fields.supervisorPhone,
            supervisorEmail: fields.supervisorEmail,
            status: fidelityReview.status,
        };

        await fidelityReviewService.evaluatorStartReview(review);
        onConfirm(fidelityReview.id);
    };
    const [tempDate, setTempDate] = useState<Date>(fidelityReview.reviewDate);

    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('orderFidelityReview.startReview')}</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2}>
                        <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                            <div style={{ height: '30px' }}>
                                <Divider>
                                    <small>{t('orderFidelityReview.organizationInformation')}</small>
                                </Divider>
                            </div>
                            <TextField
                                label={t('common.organizationName')}
                                fullWidth
                                {...register('organizationName', {
                                    required: 'organizationName is required',
                                })}
                                error={errors.organizationName ? true : false}
                                helperText={errors.organizationName ? errors.organizationName.message : ''}
                            />
                            <TextField
                                label={t('common.organizationNo')}
                                fullWidth
                                {...register('organizationNo', {
                                    required: 'organizationNo is required',
                                })}
                                error={errors.organizationNo ? true : false}
                                helperText={errors.organizationNo ? errors.organizationNo.message : ''}
                            />
                            <DatePicker
                                value={tempDate}
                                onChange={(date: Date) => {
                                    setTempDate(date);
                                    setValue('reviewDate', date, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }}
                                label={t('common.reviewDate')}
                                inputFormat="DD.MM.yyyy"
                                renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            />
                            <div style={{ height: '30px' }}>
                                <Divider>
                                    <small>{t('common.terminology')}</small>
                                </Divider>
                            </div>

                            <TextField
                                label={t('orderFidelityReview.clientTerm')}
                                {...register('clientTerm', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.clientTerm ? true : false}
                                helperText={errors.clientTerm ? errors.clientTerm.message : ''}
                            />
                            <TextField
                                label={t('orderFidelityReview.clientTermPlural')}
                                {...register('clientTermPlural', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.clientTermPlural ? true : false}
                                helperText={errors.clientTermPlural ? errors.clientTermPlural.message : ''}
                            />
                            <TextField
                                label={t('orderFidelityReview.employmentSpecialistTerm')}
                                {...register('employmentSpecialistTerm', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.employmentSpecialistTerm ? true : false}
                                helperText={errors.employmentSpecialistTerm ? errors.employmentSpecialistTerm.message : ''}
                            />
                            <TextField
                                label={t('orderFidelityReview.employmentSpecialistTermPlural')}
                                {...register('employmentSpecialistTermPlural', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.employmentSpecialistTermPlural ? true : false}
                                helperText={errors.employmentSpecialistTermPlural ? errors.employmentSpecialistTermPlural.message : ''}
                            />
                            <TextField
                                label={t('orderFidelityReview.supervisorTerm')}
                                {...register('supervisorTerm', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.supervisorTerm ? true : false}
                                helperText={errors.supervisorTerm ? errors.supervisorTerm.message : ''}
                            />
                            <TextField
                                label={t('orderFidelityReview.supervisorTermPlural')}
                                {...register('supervisorTermPlural', {
                                    required: t('common.required').toString(),
                                })}
                                error={errors.supervisorTermPlural ? true : false}
                                helperText={errors.supervisorTermPlural ? errors.supervisorTermPlural.message : ''}
                            />
                        </Stack>
                        <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                            <div style={{ height: '30px' }}>
                                <Divider>
                                    <small>{t('common.supervisor')}</small>
                                </Divider>
                            </div>
                            <TextField
                                label={t('common.supervisorName')}
                                fullWidth
                                {...register('supervisorName', {
                                    required: 'supervisorName is required',
                                })}
                                error={errors.supervisorName ? true : false}
                                helperText={errors.supervisorName ? errors.supervisorName.message : ''}
                            />
                            <TextField
                                label={t('common.supervisorEmail')}
                                fullWidth
                                {...register('supervisorEmail', {
                                    required: 'supervisorEmail is required',
                                })}
                                error={errors.supervisorEmail ? true : false}
                                helperText={
                                    errors.supervisorEmail
                                        ? errors.supervisorEmail.message
                                        : 'User invitation will be sent out to this email.'
                                }
                            />
                            <TextField
                                label={t('common.supervisorPhone')}
                                fullWidth
                                {...register('supervisorPhone', {
                                    required: 'supervisorPhone is required',
                                })}
                                error={errors.supervisorPhone ? true : false}
                                helperText={errors.supervisorPhone ? errors.supervisorPhone.message : ''}
                            />

                            <div style={{ height: '30px' }}>
                                <Divider>
                                    <small>{t('common.billingContact')}</small>
                                </Divider>
                            </div>
                            <TextField
                                label={t('common.billingContactName')}
                                fullWidth
                                {...register('billingContactName', {
                                    required: 'billingContactName is required',
                                })}
                                error={errors.billingContactName ? true : false}
                                helperText={errors.billingContactName ? errors.billingContactName.message : ''}
                            />
                            <TextField
                                label={t('common.billingContactEmail')}
                                fullWidth
                                {...register('billingContactEmail', {
                                    required: 'billingContactEmail is required',
                                })}
                                error={errors.billingContactEmail ? true : false}
                                helperText={errors.billingContactEmail ? errors.billingContactEmail.message : ''}
                            />
                            <TextField
                                label={t('common.billingContactPhone')}
                                fullWidth
                                {...register('billingContactPhone', {
                                    required: 'billingContactPhone is required',
                                })}
                                error={errors.billingContactPhone ? true : false}
                                helperText={errors.billingContactPhone ? errors.billingContactPhone.message : ''}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('orderFidelityReview.startReview')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default StartReviewModal;

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    FormControlLabel,
    TextField,
    RadioGroup,
    Radio,
    Stack,
    FormLabel,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import { validate } from 'utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import userService from 'services/user.service';
import role from 'consts/role';
//import './AddUserModal.scss';

const userRoles = [role.HOST, role.EVALUATOR, role.SUPERVISOR];

type AddUserModalProps = {
    onConfirm: (user: User) => void;
    onCancel: () => void;
};

type FormValues = {
    name: string;
    email: string;
    phone: string;
    role: number;
};

const AddUserModal: FC<AddUserModalProps> = ({ onCancel, onConfirm }) => {
    const { register, formState, handleSubmit } = useForm<FormValues>();
    const { errors } = formState;

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        try {
            const user: User = {
                name: fields.name,
                email: fields.email,
                phone: fields.phone,
                role: fields.role,
            };
            const res = await userService.addUser(user);

            onConfirm(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const { t } = useTranslation();
    return (
        <Dialog open={true}>
            <div className="add-user-modal">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Add new user</DialogTitle>
                    <DialogContent>
                        <FormGroup>
                            <Stack direction="row" columnGap="40px" sx={{ marginTop: '20px' }}>
                                <Stack rowGap="20px">
                                    <TextField
                                        {...register('name', {
                                            required: true,
                                            minLength: 3,
                                            maxLength: 50,
                                        })}
                                        name={'name'}
                                        placeholder={'Name'}
                                        label="Name"
                                        error={errors?.name ? true : false}
                                    />
                                    <TextField
                                        {...register('email', {
                                            required: true,
                                            validate: validate.email,
                                        })}
                                        placeholder={'Email'}
                                        label="Email"
                                        name="email"
                                        error={errors?.email ? true : false}
                                    />
                                    <TextField
                                        {...register('phone', {
                                            required: true,
                                            validate: validate.phone,
                                        })}
                                        placeholder={'Phone'}
                                        label="Phone"
                                        name="phone"
                                        error={errors?.phone ? true : false}
                                    />
                                </Stack>

                                <Stack>
                                    <FormLabel>Role</FormLabel>
                                    <RadioGroup defaultValue={role.EVALUATOR} name="role">
                                        {userRoles.map((userRole, index) => {
                                            return (
                                                <FormControlLabel
                                                    {...register('role', {
                                                        required: false,
                                                    })}
                                                    key={index}
                                                    control={<Radio />}
                                                    label={t('role.' + userRole).toString()}
                                                    value={userRole}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </Stack>
                            </Stack>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => onCancel()}>
                            {t('cancel')}
                        </Button>
                        <Button variant="contained" type={'submit'}>
                            {t('confirmChanges')}
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
};

export default AddUserModal;

import axios from 'axios';
import Suggestion from 'models/Suggestion';

const basePath = 'suggestion';

const suggestionService = {
    getAll: () => axios.get<Suggestion[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Suggestion>(`${basePath}/${id}`),
    add: (model: Suggestion) => axios.post<Suggestion>(`${basePath}/`, model),
    update: (model: Suggestion) => axios.put<Suggestion>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
};

export default suggestionService;

import Import from 'types/Import';
import { validate } from 'utils';
import i18n from 'translation';

export const FamilyModel = [
    {
        key: 'clientName',
        label: `${i18n.t('common.client')}*`,
        required: true,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'name',
        label: `${i18n.t('common.name')}*`,
        required: true,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'phone',
        label: `${i18n.t('common.phone')}*`,
        required: true,
        validate: validate.phone,
    },
    {
        key: 'email',
        label: `${i18n.t('common.email')}*`,
        required: true,
        validate: validate.email,
    },
] as Import[];

export default FamilyModel;

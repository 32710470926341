import { Alert, Button, CircularProgress, Paper, Stack, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import routes from 'consts/routes';
import { useAuth } from 'hooks';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import authService from 'services/auth.service';

type SetNewPasswordParameters = {
    token: string;
};

type FormValues = {
    password: string;
    repeatPassword: string;
};

type View = {
    loading?: boolean;
    form?: boolean;
    success?: boolean;
    errorMessage?: string;
    error?: boolean;
}

const SetNewPassword = () => {
    const { token } = useParams<SetNewPasswordParameters>();
    const { t } = useTranslation();
    const { signOut } = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<FormValues>();
    const history = useHistory();

    const [view, setView] = useState<View>({
        loading: false,
        form: true,
        success: false,
        errorMessage: null,
        error: false,
    });
    const updateView = (updatedView: View) => {
        setView({
            ...{
                loading: false,
                form: false,
                success: false,
                errorMessage: null,
                error: false,
            },
            ...updatedView,
        });
    };

    const onSubmit: SubmitHandler<FormValues> = async (fieldData) => {
        updateView({
            loading: true,
        });
        try {
            await authService.resetPassword(fieldData.password, token);
            updateView({
                success: true,
            });
        } catch (error) {
            let message = 'Client error';

            if (axios.isAxiosError(error)) {
                const axiosError: AxiosError = error;
                if (axiosError.response && axiosError.response.status === 401) {
                    message = t('setNewPassword.invalidToken');
                } else {
                    message = axiosError.message;
                }
            }

            updateView({
                error: true,
                errorMessage: message,
            });
        }
    };
    const validatePassword = (password: string) => {
        // Check empty
        if (password == '') {
            return t('setNewPassword.passwordEmpty');
        }

        // Minimum length
        if (password.length < 8) {
            return t('setNewPassword.passwordMustBeAtLeast8Characters');
        }

        // Characters
        if (!/[A-Za-z]/.test(password)) {
            return t('setNewPassword.passwordMustContainAtLeastOneCharacter');
        }

        // Numbers
        if (!/\d/.test(password)) {
            return t('setNewPassword.passwordMustContainAtLeastOneNumeric');
        }
        return true;
    };

    const passwordsMustMatch = (repeatPassword: string) => {
        const password = getValues('password');
        if (password !== repeatPassword) {
            return t('setNewPassword.passwordsMustMatch');
        }
        return true;
    };

    const signOutAndGoToLogin = async () => {
        await signOut();
        history.push(routes.LOGIN.path);
    };

    return (
        <div className="order-evaluation-page">
            <img className="login-logo" src="/assets/logo-192.png" />
            <h1 className="page-title">{t('setNewPassword.setNewPassword')}</h1>
            <Paper sx={{ maxWidth: '400px;', margin: '0 auto', padding: '20px' }}>
                {view.form && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <TextField
                                label={t('setNewPassword.newPassword')}
                                type={'password'}
                                {...register('password', {
                                    validate: validatePassword,
                                })}
                                error={errors.password ? true : false}
                                helperText={errors.password ? errors.password.message : ''}
                            />
                            <TextField
                                label={t('setNewPassword.repeatPassword')}
                                type={'password'}
                                {...register('repeatPassword', {
                                    validate: passwordsMustMatch,
                                })}
                                error={errors.repeatPassword ? true : false}
                                helperText={errors.repeatPassword ? errors.repeatPassword.message : ''}
                            />
                            <Button type="submit" variant="contained">
                                {t('setNewPassword.setNewPassword')}
                            </Button>
                        </Stack>
                    </form>
                )}
                {view.error && <Alert severity="error">{view.errorMessage}</Alert>}
                {view.success && (
                    <Alert severity="success">
                        <p>{t('setNewPassword.passwordWasSuccessfullySet')}</p>
                        <Button onClick={signOutAndGoToLogin} variant="contained">
                            {t('setNewPassword.goToLogin')}
                        </Button>
                    </Alert>
                )}
                {view.loading && (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress sx={{ margin: '10px 0' }} />
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default SetNewPassword;

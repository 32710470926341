import { createTheme, lighten } from '@mui/material';

const color = {
    primary: '#00814f',
    primaryContrast: '#ffffff',
    secondary: '#336666',
    secondaryContrast: '#ffffff',
};

const theme = createTheme({
    palette: {
        primary: { main: color.primary },
        secondary: { main: color.secondary },
    },
    typography: {},
    components: {
        // Custom styles on table head
        MuiTableHead: {
            styleOverrides: {
                root: {
                    color: color.secondaryContrast,
                    backgroundColor: color.secondary,
                    '& .MuiTableCell-root': {
                        color: color.secondaryContrast,
                        fontWeight: 700,
                    },
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '.MuiTableRow-root:nth-of-type(even)': {
                        backgroundColor: lighten(color.secondary, 0.95),
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper': {
                        maxWidth: 'unset',
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: color.secondaryContrast,
                    backgroundColor: color.secondary,
                },
            },
        },
        /* MuiContainer: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                },
            },
        }, */
        /* MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#00814f',
                    color: '#fff',
                },
            },
        }, */
    },
});

export default theme;

import XlsxTemplate from 'types/XlsxTemplate';
import i18n from 'translation';

const ClientXlsxTemplate = {
    name: `${i18n.t('common.client')}-${i18n.t('common.template')}`,
    sheets: [
        {
            name: '',
            headerRow: [
                { header: `${i18n.t('common.name')}*`, width: 30 },
                { header: `${i18n.t('common.email')}*`, width: 30 },
                { header: `${i18n.t('common.phone')}*`, width: 30 },
                { header: `${i18n.t('common.supervisor')} ${i18n.t('common.name')}`, width: 35 },
                { header: `${i18n.t('common.firstMeetingSupervisorDate')} (DD.MM.YYYY)`, width: 40 },
                { header: `${i18n.t('common.firstMeetingEmployerDate')} (DD.MM.YYYY)`, width: 40 },
            ],
        },
        {
            name: `${i18n.t('common.availible')} ${i18n.t('common.supervisors')}`,
            headerRow: [{ header: `${i18n.t('common.availible')} ${i18n.t('common.supervisors')}`, width: 40 }],
            rows: [],
        },
    ],
} as XlsxTemplate;

export default ClientXlsxTemplate;

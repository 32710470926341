import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Evaluator from 'models/Evaluator';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';

type EvaluatorListProps = {
    evaluators: Evaluator[];
    sort?: boolean;
    displayStatus?: boolean;
    displayOrderButton?: boolean;
};

const EvaluatorList: FC<EvaluatorListProps> = ({ evaluators = [], sort = true, displayOrderButton = false, displayStatus = false }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const sortedEvaluators = sort
        ? evaluators.sort((evaluatorA: Evaluator, evaluatorB: Evaluator) => {
              return evaluatorA.user?.enabled === evaluatorB.user?.enabled ? 0 : evaluatorA.user?.enabled ? -1 : 1;
          })
        : evaluators;

    const handleNewFidelityReview = (evaluator: Evaluator) => {
        history.push({
            pathname: `/fidelity-review/order/${evaluator.uid}`,
        });
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.agencyName')}</TableCell>
                        <TableCell>{t('common.contactName')}</TableCell>
                        <TableCell>{t('common.contactEmail')}</TableCell>
                        <TableCell>{t('common.contactPhone')}</TableCell>
                        {displayStatus && <TableCell>{t('common.status')}</TableCell>}
                        {displayOrderButton && <TableCell>{t('common.order')}</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedEvaluators.map((evaluator) => (
                        <TableRow key={evaluator.id}>
                            <TableCell>{evaluator.agencyName}</TableCell>
                            <TableCell>{evaluator?.user?.name}</TableCell>
                            <TableCell>{evaluator?.user?.email}</TableCell>
                            <TableCell>{evaluator?.user?.phone}</TableCell>
                            {displayStatus && (
                                <TableCell>
                                    {evaluator?.user?.enabled ? <i>{t('common.enabled')}</i> : <i>{t('common.disabled')}</i>}
                                </TableCell>
                            )}
                            {displayOrderButton && evaluator?.user?.enabled && (
                                <TableCell>
                                    <Button color={'secondary'} onClick={() => handleNewFidelityReview(evaluator)} variant="contained">
                                        {t('common.newFidelityReview')}
                                    </Button>
                                </TableCell>
                            )}
                            {displayOrderButton && !evaluator?.user?.enabled && (
                                <TableCell>
                                    <i>{t('common.disabled')}</i>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EvaluatorList;

import axios from 'axios';
import Employee from 'models/Employee';

const basePath = 'employee';

const employeeService = {
    countForFidelityReview: (id: number) => axios.get<{ count: number }>(`${basePath}/count-for-fidelity-review/${id}`),
    getAll: () => axios.get<Employee[]>(`${basePath}/`),
    getSingle: (id: number) => axios.get<Employee>(`${basePath}/${id}`),
    getByFidelityReview: (id: number) => axios.get<Employee[]>(`${basePath}/by-fidelity-review/${id}`),
    add: (model: Employee) => axios.post<Employee>(`${basePath}/`, model),
    update: (model: Employee) => axios.put<Employee>(`${basePath}/${model.id}`, model),
    delete: (id: number) => axios.delete(`${basePath}/${id}`),
    getByUid: (uid: string) => axios.get<Employee>(`${basePath}/get-by-uid/${uid}`),
};

export default employeeService;

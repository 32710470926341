const sourceType = {
    EMPLOYEE: 0,
    CLIENT: 1,
    FAMILY: 2,
    SUPERVISOR: 3,
    EVALUATOR: 4,
    PARTNER_VOCATIONAL_REHABILITATION: 5,
    PARTNER_HEALTH_ORGANIZATION: 6,
    MANAGEMENT: 7
  };
  export default sourceType;
  
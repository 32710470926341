
class FidelityReview {
    id?: number;
    organizationName: string;
    organizationNo: number;
    reviewDate: Date;
    orderDate?: Date;
    clientTerm: string;
    employmentSpecialistTerm?: string;
    supervisorTerm: string;
    clientTermPlural: string;
    employmentSpecialistTermPlural: string;
    supervisorTermPlural: string;
    billingContactName: string;
    billingContactPhone: string;
    billingContactEmail: string;
    supervisorName: string;
    supervisorPhone: string;
    supervisorEmail: string;
    status: number;
    evaluatorUid?: string;

    constructor(evaluatorUid: string = null) {
        this.organizationName = null;
        this.organizationNo = null;
        this.reviewDate = null;
        this.orderDate = null;
        this.clientTerm = null;
        this.clientTermPlural = null;
        this.employmentSpecialistTerm = null;
        this.employmentSpecialistTermPlural = null;
        this.supervisorTerm = null;
        this.supervisorTermPlural = null;
        this.billingContactName = null;
        this.billingContactPhone = null;
        this.billingContactEmail = null;
        this.supervisorName = null;
        this.supervisorPhone = null;
        this.supervisorEmail = null;
        this.status = null;
        this.evaluatorUid = evaluatorUid;
    }
}

export default FidelityReview;

import { FC, ReactNode } from 'react';

import { Control, Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormField = any;

type ReactHookFormSelectProps = {
    name: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    control: Control<FormField, object>;
    children: ReactNode;
    required?: boolean; 
};

const ReactHookFormSelect: FC<ReactHookFormSelectProps> = ({
    required = false,
    name,
    label,
    control,
    children,
    ...props
}) => {
    const labelId = `${name}-label`;
    return (
        <FormControl {...props}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Controller
                rules={{
                    required: required,
                }}
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => {
                    return (
                        <Select
                            labelId={labelId}
                            label={label}
                            onChange={onChange}
                            value={value}
                        >
                            {children}
                        </Select>
                    );
                }}
            />
        </FormControl>
    );
};
export default ReactHookFormSelect;

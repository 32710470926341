import { Delete } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    TextFieldProps,
} from '@mui/material';
import activityTypes from 'consts/activityTypes';
import { useAlert } from 'hooks';
import Activity from 'models/Activity';
import Client from 'models/Client';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import activityService from 'services/activity.service';

type ActivityModalProps = {
    activityType: number;
    activityInEdit?: Activity;
    clients?: Client[];
    onConfirm: (activity: Activity) => void;
    onCancel: () => void;
    onDelete: (activityUid: string) => void;
};

const titles = ['work', 'work trial', 'training'];

const ActivityModal: FC<ActivityModalProps> = ({ activityInEdit, activityType, clients, onConfirm, onCancel, onDelete }) => {
    const { addAlert } = useAlert();
    const { t } = useTranslation();
    const { register, formState, handleSubmit, setValue } = useForm<Activity>({ defaultValues: { ...activityInEdit } });
    const { errors } = formState;

    const onSubmit: SubmitHandler<Activity> = async (fields) => {
        try {
            const activity: Activity = { ...fields, activityType: activityType };
            if (activityInEdit) {
                const res = await activityService.updateByUid(activity);
                addAlert('success', t('activity.updatedActivity'));
                onConfirm(res.data);
            } else {
                const res = await activityService.add(activity);
                addAlert('success', t('activity.addedActivity'));
                onConfirm(res.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // dates
    const [tempMeetingWithJobSpecialist, setTempMeetingWithJobSpecialist] = useState<Date>(
        activityInEdit?.meetingWithJobSpecialist ?? null
    );
    const [tempStart, setTempStart] = useState<Date>(activityInEdit?.start ?? null);
    const [tempEnd, setTempEnd] = useState<Date>(activityInEdit?.end ?? null);

    return (
        <Dialog open={true}>
            <div className="add-activity-modal">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {`${activityInEdit ? 'Edit' : 'Add new'} ${titles[activityType]} activity`}{' '}
                            {activityInEdit && (
                                <IconButton sx={{ color: '#fff' }} onClick={() => onDelete(activityInEdit.uid)}>
                                    <Delete />
                                </IconButton>
                            )}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <FormGroup>
                            <Stack direction="row" columnGap="40px" sx={{ marginTop: '20px' }}>
                                <Stack rowGap="20px" sx={{ minWidth: '375px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('common.client')}</InputLabel>
                                        <Select
                                            disabled={activityInEdit ? true : false}
                                            label={t('common.client')}
                                            defaultValue={activityInEdit?.clientId ?? clients[0].id}
                                            {...register('clientId')}
                                        >
                                            {clients.map((client, index) => (
                                                <MenuItem key={index} value={client.id}>
                                                    {client.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Divider />
                                    {activityType !== activityTypes.TRAINING && (
                                        <TextField
                                            {...register('employer', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            name={'employer'}
                                            placeholder={'Employer'}
                                            label={t('activity.employer')}
                                            error={errors?.employer ? true : false}
                                        />
                                    )}
                                    {activityType == activityTypes.TRAINING && (
                                        <TextField
                                            {...register('placeOfTraining', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            placeholder={'Place of training'}
                                            label={t('activity.placeOfTraining')}
                                            name="placeOfTraining"
                                            error={errors?.placeOfTraining ? true : false}
                                        />
                                    )}
                                    {activityType === activityTypes.TRAINING && (
                                        <TextField
                                            {...register('typeOfTraining', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            placeholder={'TypeOfTraining'}
                                            label={t('activity.typeOfTraining')}
                                            name="typeOfTraining"
                                            error={errors?.typeOfTraining ? true : false}
                                        />
                                    )}
                                    {activityType === activityTypes.WORK_TRIAL && (
                                        <TextField
                                            {...register('incentive', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            placeholder={'incentive'}
                                            label={t('activity.incentive')}
                                            name="incentive"
                                            error={errors?.incentive ? true : false}
                                        />
                                    )}
                                    {activityType !== activityTypes.TRAINING && (
                                        <TextField
                                            {...register('jobDescription', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            placeholder={'Job description'}
                                            label="Job description"
                                            name="jobDescription"
                                            error={errors?.jobDescription ? true : false}
                                        />
                                    )}
                                    {activityType === activityTypes.TRAINING && (
                                        <TextField
                                            {...register('qualification', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 50,
                                            })}
                                            placeholder={'Qualification'}
                                            label={t('activity.qualification')}
                                            name="qualification"
                                            error={errors?.qualification ? true : false}
                                        />
                                    )}
                                    {activityType === activityTypes.WORK && (
                                        <TextField
                                            {...register('hourlyWage', {
                                                required: true,
                                                min: 0,
                                                max: 100000,
                                            })}
                                            type={'number'}
                                            placeholder={'Hourly wage'}
                                            label={t('activity.hourlyWage')}
                                            name="hourlyWage"
                                            error={errors?.hourlyWage ? true : false}
                                        />
                                    )}
                                    <TextField
                                        {...register('hoursPerWeek', {
                                            required: true,
                                            min: 0,
                                            max: 100,
                                        })}
                                        type={'number'}
                                        placeholder={'Hours per week'}
                                        label={t('activity.hoursPerWeek')}
                                        name="hoursPerWeek"
                                        error={errors?.hoursPerWeek ? true : false}
                                    />
                                    <DatePicker
                                        value={tempMeetingWithJobSpecialist}
                                        {...register('meetingWithJobSpecialist', {
                                            required: {
                                                value: true,
                                                message: t('common.required'),
                                            },
                                        })}
                                        onChange={(date: Date) => {
                                            setTempMeetingWithJobSpecialist(date);
                                            setValue('meetingWithJobSpecialist', date, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        label={t('activity.meetingWithJobSpecialist')}
                                        inputFormat="DD.MM.yyyy"
                                        renderInput={(params: TextFieldProps) => <TextField {...params} />}
                                    />
                                    <DatePicker
                                        value={tempStart}
                                        {...register('start', {
                                            required: {
                                                value: true,
                                                message: t('common.required'),
                                            },
                                        })}
                                        onChange={(date: Date) => {
                                            setTempStart(date);
                                            setValue('start', date, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        label={t('activity.start')}
                                        inputFormat="DD.MM.yyyy"
                                        renderInput={(params: TextFieldProps) => <TextField {...params} />}
                                    />
                                    <DatePicker
                                        value={tempEnd}
                                        {...register('end', {
                                            required: {
                                                value: true,
                                                message: t('common.required'),
                                            },
                                        })}
                                        onChange={(date: Date) => {
                                            setTempEnd(date);
                                            setValue('end', date, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        label={t('activity.end')}
                                        inputFormat="DD.MM.yyyy"
                                        renderInput={(params: TextFieldProps) => <TextField {...params} />}
                                    />
                                </Stack>
                            </Stack>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => onCancel()}>
                            {t('cancel')}
                        </Button>
                        <Button variant="contained" type={'submit'}>
                            {t('confirmChanges')}
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
};

export default ActivityModal;

import { FC } from 'react';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Employee from 'models/Employee';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

type EmployeeListProps = {
    employees: Employee[];
    editEmployee: (employee: Employee) => void;
    deleteEmployee: (id: number) => void;
};

const EmployeeList: FC<EmployeeListProps> = ({ employees, editEmployee, deleteEmployee }) => {
    const { t } = useTranslation();

    return (
        <div>
            {employees === null ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>{t('common.name')}</TableCell>
                                <TableCell>{t('common.phone')}</TableCell>
                                <TableCell>{t('common.email')}</TableCell>
                                <TableCell>{t('common.vacancyRate')}</TableCell>
                                <TableCell>{t('common.startDate')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {employees.map((employee) => (
                                <TableRow key={employee.id}>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => editEmployee(employee)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{employee.name}</TableCell>
                                    <TableCell>{employee.phone}</TableCell>
                                    <TableCell>{employee.email}</TableCell>
                                    <TableCell>{employee.vacancyRate}</TableCell>
                                    <TableCell>{moment(employee.startDate).format('MM.DD.YYYY')}</TableCell>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => deleteEmployee(employee.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {employees.length === 0 && (
                        <p style={{ textAlign: 'center' }}>
                            <i>{t('common.theListIsEmpty')}</i>
                        </p>
                    )}
                </TableContainer>
            )}
        </div>
    );
};

export default EmployeeList;

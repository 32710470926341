import role from 'consts/role';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import MyEvaluatorReviews from './MyEvaluatorReviews/MyEvaluatorReviews';
import MySupervisorReviews from './MySupervisorReviews/MySupervisorReviews';

const StartPage: FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const { t } = useTranslation();
    return (
        <div>
            {user.role === role.SUPERVISOR ? <MySupervisorReviews /> : null}
            {user.role === role.EVALUATOR ? <MyEvaluatorReviews /> : null}
            {user.role === role.HOST ? (
                <div>
                    <h2>{t('startPage.welcomeHostUser')}</h2>
                    <p>{t('startPage.useTheMenu')}</p>
                </div>
            ) : null}
        </div>
    );
};

export default StartPage;

import { FC, ReactElement, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ProfileMenu from 'components/ProfileMenu/ProfileMenu';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

type HeaderProps = {
    title?: string;
    menuOpen?: boolean;
    actions?: ReactElement;
    className?: string;
    openMenu?: () => void;
    closeMenu?: () => void;
};

const Header: FC<HeaderProps> = ({
    title,
    actions = undefined,
    className = '',
    menuOpen = false,
    openMenu,
    closeMenu,
}) => {
    const [menuIconOpen, setMenuIconOpen] = useState(menuOpen);
    const { t } = useTranslation();

    return (
        <Box
            component='header'
            className={className + 'main-header'}
            sx={{
                flexGrow: 1,
                marginLeft: menuIconOpen ? '250px' : '0',
                transition: 'margin 0.3s',
            }}
        >
            <AppBar className='top-header' position='static'>
                <Toolbar>
                    {menuIconOpen ? (
                        <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            onClick={() => {
                                setMenuIconOpen(false);
                                closeMenu();
                            }}
                            sx={{ mr: 2 }}
                        >
                            <MenuOpenIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            onClick={() => {
                                setMenuIconOpen(true);
                                openMenu();
                            }}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography
                        variant='h6'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    >
                        {t(title)}
                    </Typography>
                    {actions}
                    <LanguageSwitcher />
                    <ProfileMenu></ProfileMenu>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;

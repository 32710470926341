import Import from 'types/Import';
import { validate } from 'utils';
import i18n from 'translation';

export const ClientModel = [
    {
        key: 'name',
        label: `${i18n.t('common.name')}*`,
        required: true,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'email',
        label: `${i18n.t('common.email')}*`,
        required: true,
        validate: validate.email,
    },
    {
        key: 'phone',
        label: `${i18n.t('common.phone')}*`,
        required: true,
        validate: validate.phone,
    },
    {
        key: 'employeeName',
        label: `${i18n.t('common.supervisor')} ${i18n.t('common.name')}`,
        required: false,
        validate: (val: string) => validate.length(val, 2, 50),
    },
    {
        key: 'firstMeetingEmployeeDate',
        label: `${i18n.t('common.firstMeetingSupervisorDate')}`,
        required: false,
        validate: validate.dateString,
    },
    {
        key: 'firstMeetingEmployerDate',
        label: `${i18n.t('common.firstMeetingEmployerDate')}`,
        required: false,
        validate: validate.dateString,
    },
] as Import[];

export default ClientModel;

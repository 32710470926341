import React, { FC } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import fidelityReviewStatus from 'consts/fidelityReviewStatus';

type ReviewTimelineProps = {
    status: number;
    reviewDate: Date;
    orderDate: Date;
};

const ReviewTimeline: FC<ReviewTimelineProps> = ({ status, reviewDate, orderDate }) => {
    const { t } = useTranslation();

    const statuses = Object.values(fidelityReviewStatus);

    return (
        <Timeline position="left">
            {statuses.map((s) => (
                <TimelineItem key={s}>
                    <TimelineSeparator>
                        <TimelineDot variant={status === s ? 'filled' : 'outlined'} color={status === s ? 'primary' : 'grey'} />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color={status === s ? 'primary' : 'grey'} sx={{ fontWeight: status === s ? 'bold' : 'inherit' }}>
                        {t('fidelityReviewStatus.' + s)}
                        {s === fidelityReviewStatus.ORDER_RECIEVED && (
                            <div>
                                <small>{moment(orderDate).format('DD.MM.YYYY')}</small>
                            </div>
                        )}
                        {s === fidelityReviewStatus.REPORT_GENERATED && (
                            <div>
                                <small>{moment(reviewDate).format('DD.MM.YYYY')}</small>
                            </div>
                        )}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default ReviewTimeline;

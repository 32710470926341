import userSlice, { userActions } from './slices/userSlice';
import { configureStore } from '@reduxjs/toolkit';
import alertSlice, { alertActions } from './slices/alertsSlice';

const store = configureStore({
    reducer: {
        user: userSlice,
        alerts: alertSlice,
    },
});

export default store;

export { userActions, alertActions };

export type RootState = ReturnType<typeof store.getState>;

import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import './PrimaryListItem.scss';

type PrimaryListItemProps = {
    actions?: ReactNode;
    label?: string;
    value?: string;
};

const PrimaryListItem: FC<PrimaryListItemProps> = ({
    label,
    value,
    actions,
}) => {
    return (
        <div className={'primary-list-item'}>
            <div className='label'>
                <Typography>{label}</Typography>
            </div>
            <div className='value'>
                <Typography>{value}</Typography>
                <div className='actions'>{actions}</div>
            </div>
        </div>
    );
};

export default PrimaryListItem;

import { useDispatch, useSelector } from 'react-redux';
import authService from 'services/auth.service';
import { RootState, userActions } from 'store';
import User from 'models/User';

const useEditUser = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const updateUser = async (updatedUser: User) => {
        try {
            await authService.updateUser(updatedUser);
            dispatch(userActions.set(updatedUser));
        } catch (error) {
            console.error(error);
        }
    };

    const changeName = async (newName: string) => {
        const userTemp = { ...user };
        userTemp.name = newName;
        await updateUser(userTemp);
    };

    const changeEmailRequest = async () => {
        try {
            await authService.changeEmailRequest();
        } catch (error) {
            console.error(error);
        }
    };

    const changePassword = async (newPassword: string, oldPassword: string) => {
        try {
            await authService.changePassword(newPassword, oldPassword);
        } catch (error) {
            console.error('error');
        }
    };

    const changeEmail = async (newEmail: string, changeEmailToken: string) => {
        try {
            await authService.changeEmail(newEmail, changeEmailToken);
            const userTemp = { ...user };
            userTemp.email = newEmail;
            dispatch(userActions.set(userTemp));
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return {
        changeName,
        changeEmailRequest,
        changeEmail,
        changePassword,
    };
};

export default useEditUser;

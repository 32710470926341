import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Paper, Tab } from '@mui/material';
import { Box } from '@mui/system';
import axios, { AxiosError } from 'axios';
import { ActivityList } from 'components';
import activityTypes from 'consts/activityTypes';
import routes from 'consts/routes';
import { useAlert } from 'hooks';
import Activity from 'models/Activity';
import Client from 'models/Client';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import activityService from 'services/activity.service';
import clientService from 'services/client.service';
import IdParams from 'types/IdParams';
import ActivityModal from './ActivityModal/ActivityModal';
import filterActivitiesByType from './utils/filterActivitiesByType';
import { ConsentModal } from 'components';

const Activities: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { addAlert } = useAlert();
    const { id: fidelityReviewId } = useParams<IdParams>();

    // States
    const [clients, setClients] = useState<Client[]>();
    const [activityInEdit, setActivityInEdit] = useState<Activity>();
    const [activeActivityType, setActiveActivityType] = useState(activityTypes.WORK);
    const [tabValue, setTabValue] = useState('work');
    const [workActivities, setWorkActivities] = useState<Activity[]>([]);
    const [workTrialActivities, setWorkTrialActivities] = useState<Activity[]>([]);
    const [trainingActivities, setTrainingActivities] = useState<Activity[]>([]);

    // modals
    const [modal, setModal] = useState(0);

    useEffect(() => {
        getClients();
        getActivities();
    }, []);

    const getClients = async () => {
        if (fidelityReviewId) {
            try {
                const { data: clientsRes = [] } = await clientService.getByFidelityReview(parseInt(fidelityReviewId));
                setClients(clientsRes);
            } catch (error) {
                let message = 'Client error';
                if (axios.isAxiosError(error)) {
                    const axiosError: AxiosError = error;
                    if (axiosError.response && axiosError.response.status === 404) {
                        history.push(routes.ERROR_PAGE.path.replace(':code', '701'));
                        return;
                    } else {
                        message = axiosError.message;
                    }
                }
                addAlert('error', message);
            }
        }
    };

    const getActivities = async () => {
        try {
            const { data: activitiesRes } = await activityService.getAll();

            const filteredActivitiesByType = filterActivitiesByType(activitiesRes);
            setWorkActivities(filteredActivitiesByType.workActivities);
            setWorkTrialActivities(filteredActivitiesByType.workTrialActivities);
            setTrainingActivities(filteredActivitiesByType.trainingActivities);
        } catch (error) {
            let message = 'Client error';
            if (axios.isAxiosError(error)) {
                const axiosError: AxiosError = error;
                if (axiosError.response && axiosError.response.status === 404) {
                    history.push(routes.ERROR_PAGE.path.replace(':code', '701'));
                    return;
                } else {
                    message = axiosError.message;
                }
            }
            addAlert('error', message);
        }
    };

    const deleteActivity = async () => {
        if (activityInEdit) {
            try {
                await activityService.deleteByUid(activityInEdit.uid);
                await getActivities();
                addAlert('success', t('activity.deletedActivity'));
            } catch (error) {
                let message = 'Client error';
                if (axios.isAxiosError(error)) {
                    const axiosError: AxiosError = error;
                    if (axiosError.response && axiosError.response.status === 404) {
                        history.push(routes.ERROR_PAGE.path.replace(':code', '701'));
                        return;
                    } else {
                        message = axiosError.message;
                    }
                }
                addAlert('error', message);
            } finally {
                setActivityInEdit(null);
                setModal(0);
            }
            activityService;
        } else {
            addAlert('error', 'Could not find the activity you wanted to delete');
        }
    };

    const handleTabChanged = (e: React.SyntheticEvent, val: string) => {
        setTabValue(val);
        switch (val) {
            case 'work':
                setActiveActivityType(activityTypes.WORK);
                break;
            case 'workTrial':
                setActiveActivityType(activityTypes.WORK_TRIAL);
                break;
            case 'training':
                setActiveActivityType(activityTypes.TRAINING);
                break;
            default:
                setActiveActivityType(activityTypes.WORK);
                break;
        }
    };

    const handleActivityEdit = (activity: Activity) => {
        setActivityInEdit(activity);
        setModal(1);
    };

    return (
        <>
            <div className="acitivity-page">
                <h1>{t('common.activities')}</h1>
                <div className="activities-container">
                    <Paper>
                        <Box sx={{ width: '100%' }}>
                            <TabContext value={tabValue}>
                                <Paper>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleTabChanged}>
                                            <Tab label={'work'} value="work" />
                                            <Tab label={'work trial'} value="workTrial" />
                                            <Tab label={'training'} value="training" />
                                        </TabList>
                                    </Box>
                                </Paper>
                                <TabPanel value="work" sx={{ padding: 0 }}>
                                    <ActivityList
                                        onEdit={handleActivityEdit}
                                        activityType={activityTypes.WORK}
                                        activities={workActivities}
                                    />
                                </TabPanel>
                                <TabPanel value="workTrial" sx={{ padding: 0 }}>
                                    <ActivityList
                                        onEdit={handleActivityEdit}
                                        activityType={activityTypes.WORK_TRIAL}
                                        activities={workTrialActivities}
                                    />
                                </TabPanel>
                                <TabPanel value="training" sx={{ padding: 0 }}>
                                    <ActivityList
                                        onEdit={handleActivityEdit}
                                        activityType={activityTypes.TRAINING}
                                        activities={trainingActivities}
                                    />
                                </TabPanel>
                            </TabContext>
                            <div style={{ padding: 15 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={clients && clients.length > 0 ? false : true}
                                    onClick={() => setModal(1)}
                                >
                                    Add activity
                                </Button>
                            </div>
                        </Box>
                    </Paper>
                </div>
            </div>
            {modal === 1 && (
                <ActivityModal
                    activityInEdit={activityInEdit}
                    activityType={activeActivityType}
                    clients={clients}
                    onConfirm={() => {
                        getActivities();
                        setModal(0);
                        setActivityInEdit(null);
                    }}
                    onCancel={() => {
                        setModal(0);
                        setActivityInEdit(null);
                    }}
                    onDelete={() => {
                        setModal(2);
                    }}
                />
            )}
            {modal === 2 && (
                <ConsentModal
                    title={t('activity.deleteActivity')}
                    content={t('activity.confirmDeleteActivity')}
                    onConfirm={deleteActivity}
                    onCancel={() => {
                        setActivityInEdit(null);
                        setModal(null);
                    }}
                />
            )}
        </>
    );
};

export default Activities;

import moment from 'moment';

/* eslint-disable no-useless-escape */
const validate = {
    email: (email: string) => {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return emailRegex.test(email);
    },
    phone: (phone: string) => {
        //Validates the phone number
        const phoneRegex = /^(\+91-|\+91|0)?\d{8}$/; // Change this regex based on requirement
        return phoneRegex.test(phone);
    },
    length: (string: string, min: number, max: number) => {
        return string.length >= min && string.length <= max;
    },
    expired: (expire: number) => {
        const timeNow = new Date().getTime();
        return timeNow > expire;
    },
    dateString: (dateString: string) => {
        const d = moment(dateString, 'DD.MM.YYYY', true);
        return d.isValid();
    },
    date: (date: Date) => {
        return date instanceof Date && !isNaN(date.getTime());
    },
    number: (val: number, min = 0, max = 99999999) => {
        if (typeof val === 'number') {
            return val >= min && val <= max;
        } else {
            return false;
        }
    },
};

export default validate;

import { FC } from 'react';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Family from 'models/Family';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

type FamilyListProps = {
    family: Family[];
    clientTerm: string;
    editFamily: (family: Family) => void;
    deleteFamily: (id: number) => void;
};

const FamilyList: FC<FamilyListProps> = ({ family, editFamily, deleteFamily, clientTerm }) => {
    const { t } = useTranslation();

    return (
        <div>
            {family === null ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>{t('common.name')}</TableCell>
                                <TableCell>{t('common.phone')}</TableCell>
                                <TableCell>{t('common.email')}</TableCell>
                                <TableCell>{clientTerm}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {family.map((family) => (
                                <TableRow key={family.id}>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => editFamily(family)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{family.name}</TableCell>
                                    <TableCell>{family.phone}</TableCell>
                                    <TableCell>{family.email}</TableCell>
                                    <TableCell>{family.client ? family.client.name : ''} </TableCell>
                                    <TableCell>
                                        <IconButton color="secondary" edge="end" onClick={() => deleteFamily(family.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {family.length === 0 && (
                        <p style={{ textAlign: 'center' }}>
                            <i>{t('common.theListIsEmpty')}</i>
                        </p>
                    )}
                </TableContainer>
            )}
        </div>
    );
};

export default FamilyList;

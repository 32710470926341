import { FC } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Partner from 'models/Partner';
import partnerType from 'consts/partnerType';

type EditPartnerModalProps = {
    partner: Partner;
    onCancel: () => void;
    onConfirm: (partner: Partner) => void;
};

type FormValues = {
    type: number;
    name: string;
    email: string;
    phone: string;
    title: string;
};

const EditPartnerModal: FC<EditPartnerModalProps> = ({ onCancel, onConfirm, partner }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<FormValues>({
        defaultValues: {
            type: partner?.type || partnerType.HEALTH_ORGANIZATION,
            name: partner?.name,
            email: partner?.email,
            phone: partner?.phone,
            title: partner?.title,
        },
    });

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        //TODO CreateEvaluator
        const returnPartner: Partner = {
            id: partner?.id,
            type: fields.type,
            name: fields.name,
            email: fields.email,
            phone: fields.phone,
            title: fields.title,
        };
        onConfirm(returnPartner);
    };
    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {partner ? t('common.edit') : t('common.add')} {t('common.partner')}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                        <FormControl fullWidth>
                            <InputLabel>{t('common.type')}</InputLabel>
                            <Select label={t('common.type')} defaultValue={getValues('type')} {...register('type')}>
                                <MenuItem value={partnerType.VOCATIONAL_REHABILITATION}>
                                    {t('partnerType.' + partnerType.VOCATIONAL_REHABILITATION)}
                                </MenuItem>
                                <MenuItem value={partnerType.HEALTH_ORGANIZATION}>
                                    {t('partnerType.' + partnerType.HEALTH_ORGANIZATION)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label={t('common.name')}
                            fullWidth
                            {...register('name', {
                                required: 'name is required',
                            })}
                            error={errors.name ? true : false}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                        <TextField
                            label={t('common.title')}
                            fullWidth
                            {...register('title', {
                                required: 'title is required',
                            })}
                            error={errors.title ? true : false}
                            helperText={errors.title ? errors.title.message : ''}
                        />
                        <TextField
                            label={t('common.phone')}
                            fullWidth
                            {...register('phone', {
                                required: 'phone is required',
                            })}
                            error={errors.phone ? true : false}
                            helperText={errors.phone ? errors.phone.message : ''}
                        />
                        <TextField
                            label={t('common.email')}
                            fullWidth
                            {...register('email', {
                                required: 'email is required',
                            })}
                            error={errors.email ? true : false}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditPartnerModal;

import { FC } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Family from 'models/Family';
import Client from 'models/Client';

type EditFamilyModalProps = {
    family: Family;
    clients: Client[];
    onCancel: () => void;
    onConfirm: (family: Family) => void;
    clientTerm: string;
};

type FormValues = {
    name: string;
    email: string;
    phone: string;
    clientId: number;
};

const EditFamilyModal: FC<EditFamilyModalProps> = ({ onCancel, onConfirm, family, clients, clientTerm }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            name: family?.name,
            email: family?.email,
            phone: family?.phone,
            clientId: family?.clientId,
        },
    });

    const onSubmit: SubmitHandler<FormValues> = async (fields) => {
        //TODO CreateEvaluator
        const returnFamily: Family = {
            id: family?.id,
            name: fields.name,
            email: fields.email,
            phone: fields.phone,
            clientId: fields.clientId,
        };
        onConfirm(returnFamily);
    };

    return (
        <Dialog className="invite-evaluator-modal" open={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {family ? t('common.edit') : t('common.add')} {t('common.familyMember')}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '20px', minWidth: '375px' }}>
                        <FormControl fullWidth>
                            <InputLabel>{clientTerm}</InputLabel>
                            <Select label={clientTerm} {...register('clientId')}>
                                <MenuItem value={undefined}>{t('common.none')}</MenuItem>
                                {clients.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                        {e.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label={t('common.name')}
                            fullWidth
                            {...register('name', {
                                required: 'name is required',
                            })}
                            error={errors.name ? true : false}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                        <TextField
                            label={t('common.phone')}
                            fullWidth
                            {...register('phone', {
                                required: 'phone is required',
                            })}
                            error={errors.phone ? true : false}
                            helperText={errors.phone ? errors.phone.message : ''}
                        />
                        <TextField
                            label={t('common.email')}
                            fullWidth
                            {...register('email', {
                                required: 'email is required',
                            })}
                            error={errors.email ? true : false}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditFamilyModal;

import { Breakpoint, Container } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { Header, NavDrawer } from 'components';
import './Dashboard.scss';

type DashboardProps = {
    children: ReactNode;
    title?: string;
    action?: ReactNode;
    pageWidth?: Breakpoint;
};

const Dashboard: FC<DashboardProps> = ({ title = '', children, pageWidth = 'lg' }) => {
    const [displayDrawer, setDisplayDrawer] = useState(true);

    return (
        <>
            <div className={'dashboard'}>
                <div className={'app-bar-container'}>
                    <div className={'toolbar-placeholder'}></div>
                    <Header
                        menuOpen={displayDrawer}
                        title={title}
                        openMenu={() => {
                            setDisplayDrawer(true);
                        }}
                        closeMenu={() => {
                            setDisplayDrawer(false);
                        }}
                    />
                </div>
                <div className="dashboard-content">
                    <NavDrawer
                        displayDrawer={displayDrawer}
                        onDrawerClose={() => {
                            setDisplayDrawer(false);
                        }}
                        onDrawerOpen={() => {
                            setDisplayDrawer(true);
                        }}
                    />
                    <Container maxWidth={pageWidth} className={'page-container'}>
                        {children}
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
